import {
    GET_NOTES_SUCCESS,
    SAVE_NOTE,
    DELETE_NOTE,
} from "../constant";

const initial = {
    userNotes: [],
};

export const UserNoteReducer = (state = initial, action) => {
    switch (action.type) {
        case GET_NOTES_SUCCESS:
            return {
                ...state,
                userNotes: [...action.payload.notes]
            }

        case SAVE_NOTE:
            return {
                ...state,
                userNotes: [...action.payload.notes]
            }
        case DELETE_NOTE:
            return {
                ...state,
                userNotes: [...action.payload.notes]
            }
        default:
            return state;
    }
};