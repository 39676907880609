import React from "react";
import { Box, Button, Avatar, Stack, Chip, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function RedesignUserProfileComponent({ user, page, total }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 786px)" });

  return (
    <Stack
      sx={{
        width: isMobile ? "90vw" : "79vw",
        background: "white",
        margin: "0 auto",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}>
        <Button onClick={() => navigate("/users")} variant="contained" sx={{ margin: "0 10px" }}>
          <ArrowBackIcon sx={{ marginRight: "8px" }} />
          {t("components.redesignUserProfileComponent.users")}
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          margin: "32px 8px 32px",
        }}>
        <Avatar
          sx={{
            height: "64px",
            width: "64px",
            borderRadius: "50%",
          }}>
          <img
            src="https://cdn.pixabay.com/photo/2022/09/08/15/16/cute-7441224_640.jpg"
            style={{
              height: "100%",
              width: "100%",
              borderRadius: "50%",
            }}
          />
        </Avatar>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}>
          <Stack
            sx={{
              margin: "0 16px",
            }}>
            <Typography variant="h4">{user[0].fullname}</Typography>
            <Stack alignItems="center" direction="row" spacing={1}>
              <Stack alignItems="center" direction="row" spacing={1}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    marginRight: "8px",
                  }}>
                  {t("components.redesignUserProfileComponent.role")}:
                </Typography>
                <Chip label={user[0].role}></Chip>
              </Stack>
              <Stack alignItems="center" direction="row" spacing={1}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    marginRight: "8px",
                  }}>
                  {t("components.redesignUserProfileComponent.total")} {page}:
                </Typography>
                <Chip label={total} />
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
}

export default RedesignUserProfileComponent;
