import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CardScrollingStrip from "./CardScrollingStrip";
import SpeedText from "./SpeedText";
import { useState } from "react";
import TvIcon from "@mui/icons-material/Tv";
import { grey } from "@mui/material/colors";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { MenuItem } from "@mui/material";
import { FormControl } from "@mui/material";
import { InputLabel } from "@mui/material";
import { Select } from "@mui/material";
import ColorPickerApp from "./ColorPickerApp";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { addWidget } from "../../../redux/actions/Content";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

export default function DialogBoxScrollingStrip({ isOpen, onClose, onTakeData, isIcon, onAddWidget }) {
  const { t } = useTranslation();
  // const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { currentFolder } = useSelector((state) => state.FileFolder);
  const { user } = useSelector((state) => state.User);
  const [items, setItems] = useState([]);
  const [name, setName] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [color, setColor] = useState({ bgcolor: "", textcolor: "" });
  const [bg, setBg] = useState("");
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [numberValue, setNumberValue] = useState("");
  const [spacing, setSpacing] = useState("");
  const [customColor, setCustomColor] = useState("");
  const [textCustomColor, setTextCustomColor] = useState("");
  const [speedValue, setSpeedValue] = useState(10);
  const [textPosition, setTextPosition] = useState("");

  React.useEffect(() => {
    handleClickOpen();
  }, [isOpen, onClose]);

  const handleClickOpen = () => {
    if (isOpen) {
      setName("");
      setInputValue("");
      setBg("");
      setNumberValue("");
      setSpacing("");
      setShowAdvanced(false);
      setCustomColor("");
      setTextCustomColor("");
      setTextPosition("horizontal");
      setSpeedValue(10);
    }
    if (onClose) {
      setName("");
      setInputValue("");
      setBg("");
      setNumberValue("");
      setSpacing("");
      setShowAdvanced(false);
      setCustomColor("");
      setTextCustomColor("");
      setTextPosition("horizontal");
      setSpeedValue(10);
    }
  };

  const handleClose = () => {
    // setOpen(false);
    setShowAdvanced(false);
  };

  const handleChange = (event) => {
    setBg(event.target.value);
    const clr = event.target.value;
    if (clr === "light") {
      setColor({ bgcolor: "white", textcolor: "black" });
    }
    if (clr === "dark") {
      setColor({ bgcolor: "black", textcolor: "white" });
    }
  };

  const handleChangeSpacing = (event) => {
    setSpacing(event.target.value);
    const space = event.target.value;
  };

  const handleToggleAdvanced = () => {
    setShowAdvanced(!showAdvanced);
  };

  const handlePreviewOpen = () => {
    setPreviewOpen(true);
    // setOpen(false);
    if (customColor !== "" || textCustomColor !== "") {
      setColor({ bgcolor: customColor, textcolor: textCustomColor });
    }
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
    // setOpen("true");
    setCustomColor("");
    setTextCustomColor("");
  };

  const handleAdd = () => {
    let text = inputValue;
    if (name == "" || text == "") {
      toast.error(t("views.dialogBoxScrollingStrip.fieldsRequired"));
      return;
    }
    setItems((previous) => [...previous, { title: name, inputValue }]);
    // setOpen(false);
    onTakeData({ title: name, inputValue, isIcon });
    const htmlCode = `
      <html>
      <head>
      <style>
      .textPreview {
        position: absolute;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        animation: linear infinite;
      }
      
      @keyframes horizontal {
        0% {
          transform: translateX(100%);
        }
        100% {
          transform: translateX(-100%);
        }
      }
      
      @keyframes horizontalreverse {
        0% {
          transform: translateX(-100%);
        }
        100% {
          transform: translateX(100%);
        }
      }
      </style>
      </head>
      <body>
      <div
      style="
        background-color: {{color.bgcolor}};
        color: {{color.textcolor}};
        word-spacing: {{spacing}};
        font-size: {{numberValue}}px;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        padding: 0;
        min-width: 600px;
        height: 100%;
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
      "
    >
      <div
         class="textPreview"
        style="
          animation-duration: {{speedValue}}s;
          animation-name: {{textPosition}};
        "
      >
        {{inputValue}}
      </div>
    </div>
      </body>
      </html>
      `;
    const _HtmlCode = htmlCode.replace(/"/g, '\\"').replace(/\n/g, "\\n").toString();
    const minifyHtmlCode = _HtmlCode
      .replace(/\\"/g, '"')
      .replace(/\\n/g, "\n")
      .replace("{{color.bgcolor}}", color.bgcolor)
      .replace("{{color.textcolor}}", color.textcolor)
      .replace("{{spacing}}", spacing)
      .replace("{{numberValue}}", numberValue)
      .replace("{{speedValue}}", speedValue)
      .replace("{{textPosition}}", textPosition)
      .replace("{{inputValue}}", inputValue);

    const htmlTextStrip = `<div
      style="
        background-color: {{color.bgcolor}};
        color: {{color.textcolor}};
        word-spacing: {{spacing}};
        font-size: {{numberValue}}px;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        padding: 0;
        min-width: 600px;
        height: 100%;
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
      "
    >
      <div
         class="textPreview"
        style="
          animation-duration: {{speedValue}}s;
          animation-name: {{textPosition}};
        "
      >
        {{inputValue}}
      </div>
    </div>`;
    const htmltostr = htmlTextStrip.replace(/"/g, '\\"').replace(/\n/g, "\\n").toString();
    const minifyHtml = htmltostr
      .replace(/\\"/g, '"')
      .replace(/\\n/g, "\n")
      .replace("{{color.bgcolor}}", color.bgcolor)
      .replace("{{color.textcolor}}", color.textcolor)
      .replace("{{spacing}}", spacing)
      .replace("{{numberValue}}", numberValue)
      .replace("{{speedValue}}", speedValue)
      .replace("{{textPosition}}", textPosition)
      .replace("{{inputValue}}", inputValue);
    if (user.isMember) {
      dispatch(
        addWidget({
          name,
          type: "widget",
          widgetCat: "scrollingstrip",
          widgetIcon: isIcon,
          url: minifyHtml,
          html: minifyHtmlCode,
          folderId: currentFolder,
          role: user.isMember && user.user.role,
          subuser: user.user._id,
        })
      );
    } else {
      onAddWidget({
        name,
        type: "widget",
        widgetCat: "scrollingstrip",
        widgetIcon: isIcon,
        url: minifyHtml,
        html: minifyHtmlCode,
        folderId: currentFolder,
        role: user.role,
        user: user._id,
        subuser: user._id,
      })
    }

    setName("");
    setInputValue("");
    setPreviewOpen(false);
    //toast.success(t("views.dialogBoxScrollingStrip.successfullyLoaded"));
    onClose();
    setShowAdvanced(false);
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    const onlyNumbers = value.replace(/[^0-9]/g, "");
    setNumberValue(onlyNumbers);
  };

  const handleSpeedValue = (value) => {
    if (value >= 1 && value <= 30) {
      setSpeedValue(31 - value);
    }
  };

  const handleChangePosition = (event) => {
    setTextPosition(event.target.value);
  };

  const isMobile = useMediaQuery({ query: "(max-width: 786px)" });

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
      <Dialog fullWidth open={isOpen} onClose={() => onClose(false)}>
        <TvIcon
          sx={{
            color: grey[400],
            fontSize: 100,
            textAlign: "center",
            marginX: "auto",
            paddingTop: 3,
          }}
        />

        <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>{t("views.dialogBoxScrollingStrip.title")}</DialogTitle>
        <DialogContent sx={{ minHeight: "30vh" }}>
          <div
            style={{
              display: "flex",
              marginTop: 20,
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <DialogContentText sx={{ fontWeight: "bold", marginRight: 6 }}>{t("views.dialogBoxScrollingStrip.name")}</DialogContentText>
            <TextField
              id="outlined-basic"
              label={t("views.dialogBoxScrollingStrip.giveYourTextName")}
              variant="outlined"
              sx={{ width: "465px" }}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              marginTop: 20,
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <DialogContentText sx={{ fontWeight: "bold" }}>{t("views.dialogBoxScrollingStrip.text")}</DialogContentText>
            <TextField
              id="standard-multiline-static"
              variant="outlined"
              label={t("views.dialogBoxScrollingStrip.enterYourText")}
              multiline
              value={inputValue}
              rows={5}
              sx={{ marginLeft: 7, width: "465px" }}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              cursor: "pointer",
            }}
            onClick={handleToggleAdvanced}>
            <p style={{ fontWeight: "bold" }}>{t("views.dialogBoxScrollingStrip.advanced")}</p>
            <ArrowRightIcon color="primary" className={showAdvanced && "arrowRotate"} />
          </div>

          {showAdvanced && ( // Render advanced settings if showAdvanced is true
            <div>
              <div
                style={{
                  display: "flex",
                  marginTop: 20,
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>
                <DialogContentText sx={{ fontWeight: "bold" }}>{t("views.dialogBoxScrollingStrip.theme")}</DialogContentText>{" "}
                <FormControl sx={{ marginLeft: 6 }}>
                  <InputLabel id="demo-simple-select-helper-label">{t("views.dialogBoxScrollingStrip.themeColor")}</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={bg}
                    label="Text Spacing"
                    sx={{ width: isMobile ? "210px" : "435px" }}
                    onChange={handleChange}>
                    <MenuItem value={"light"}>{t("views.dialogBoxScrollingStrip.light")}</MenuItem>
                    <MenuItem value={"dark"}>{t("views.dialogBoxScrollingStrip.dark")}</MenuItem>
                    <MenuItem value={"custom"}>{t("views.dialogBoxScrollingStrip.custom")}</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {bg === "custom" && (
                <div style={{ marginLeft: 90 }}>
                  <div>
                    <p style={{ fontWeight: "bold", marginTop: 20 }}>{t("views.dialogBoxScrollingStrip.textBackgroundColor")}</p>
                    <ColorPickerApp onColorChange={(color) => setCustomColor(color)} />
                  </div>
                  <div>
                    <p style={{ fontWeight: "bold", marginTop: 10 }}>{t("views.dialogBoxScrollingStrip.textColor")}</p>
                    <ColorPickerApp onColorChange={(color) => setTextCustomColor(color)} />
                  </div>
                </div>
              )}
              <div>
                <div
                  style={{
                    display: "flex",
                    marginTop: 20,
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  <DialogContentText sx={{ fontWeight: "bold" }}>{t("views.dialogBoxScrollingStrip.spacing")}</DialogContentText>
                  <FormControl sx={{ marginLeft: 3 }}>
                    <InputLabel id="demo-simple-select-helper-label">{t("views.dialogBoxScrollingStrip.wordSpacing")}</InputLabel>{" "}
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={spacing}
                      label={t("views.dialogBoxScrollingStrip.wordSpacing")}
                      sx={{ width: isMobile ? "210px" : "435px" }}
                      onChange={handleChangeSpacing}>
                      <MenuItem value={"5px"}>{t("views.dialogBoxScrollingStrip.short")}</MenuItem>
                      <MenuItem value={"30px"}>{t("views.dialogBoxScrollingStrip.medium")}</MenuItem>
                      <MenuItem value={"40px"}>{t("views.dialogBoxScrollingStrip.long")}</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginTop: 20,
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  <DialogContentText sx={{ fontWeight: "bold" }}>{t("views.dialogBoxScrollingStrip.position")}</DialogContentText>
                  <FormControl sx={{ marginLeft: 3 }}>
                    <InputLabel id="demo-simple-select-helper-label">{t("views.dialogBoxScrollingStrip.textPosition")}</InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={textPosition}
                      label={t("views.dialogBoxScrollingStrip.textPosition")}
                      sx={{ width: isMobile ? "210px" : "435px" }}
                      onChange={handleChangePosition}>
                      <MenuItem value={"horizontal"}>{t("views.dialogBoxScrollingStrip.rightToLeft")}</MenuItem>
                      <MenuItem value={"horizontalreverse"}>{t("views.dialogBoxScrollingStrip.leftToRight")}</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginTop: 20,
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  <DialogContentText sx={{ fontWeight: "bold" }}>{t("views.dialogBoxScrollingStrip.speed")}</DialogContentText>
                  <div>
                    <SpeedText onGetValue={handleSpeedValue} />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginTop: 20,
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  <DialogContentText sx={{ fontWeight: "bold" }}>{t("views.dialogBoxScrollingStrip.fontSize")}</DialogContentText>
                  <FormControl sx={{ marginLeft: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label={t("views.dialogBoxScrollingStrip.fontSize")}
                      variant="outlined"
                      sx={{ width: isMobile ? "210px" : "435px" }}
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                      type="text"
                      value={numberValue}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </div>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 2,
            marginTop: 1,
          }}>
          <div>
            <Button variant="contained" color="primary" sx={{ marginRight: 1 }}>
              {t("views.dialogBoxScrollingStrip.help")}
            </Button>
            <Button onClick={handlePreviewOpen} color="primary" variant="contained">
              {t("views.dialogBoxScrollingStrip.preview")}
            </Button>
          </div>
          <div>
            <Button onClick={() => onClose(false)}>{t("views.dialogBoxScrollingStrip.cancel")}</Button>
            <Button onClick={handleAdd} variant="contained" color="primary">
              {t("views.dialogBoxScrollingStrip.save")}{" "}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <div
        style={{
          display: "flex",
          gap: "10px",
          marginTop: "10px",
          flexWrap: "wrap",
          justifyContent: "center",
        }}>
        {items.map((item, index) => (
          <CardScrollingStrip key={index} item={item} />
        ))}
      </div>

      <Dialog fullWidth open={previewOpen} onClose={handlePreviewClose}>
        <TvIcon
          sx={{
            color: grey[400],
            fontSize: 100,
            textAlign: "center",
            marginX: "auto",
            paddingTop: 3,
          }}
        />
        <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>{t("views.dialogBoxScrollingStrip.previewTitle")}</DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: `${color.bgcolor}`,
            color: `${color.textcolor}`,
            wordSpacing: `${spacing}`,
            fontSize: `${numberValue}px`,
            borderTop: "1px solid black",
            borderBottom: "1px solid black",
            padding: "0",
            minWidth: "600px",
            height: "300px",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
          }}>
          <div
            // className="textPreview"
            style={{
              position: "absolute",
              minWidth: "600px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              animation: "linear infinite",
              animationDuration: `${speedValue}s`,
              animationName: `${textPosition}`,
            }}>
            {inputValue}
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 2,
            marginTop: 1,
          }}>
          <div>
            <Button variant="contained" color="primary" onClick={handleAdd}>
              {t("views.dialogBoxScrollingStrip.confirm")}
            </Button>
          </div>
          <div>
            <Button onClick={handlePreviewClose}>{t("views.dialogBoxScrollingStrip.cancel")}</Button>{" "}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
