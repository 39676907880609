import React, { useMemo, useCallback, createElement } from "react";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Card, Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import WidgetActions from "./WidgetActions";
import WidgetDefaultHeader from "./WidgetDefaultHeader";
import WidgetContentWrapper from "./WidgetContentWrapper";

import * as Widgets from "../widgets";

const WidgetGridItem = ({ widgetData, editMode, onDelete, onEdit, onChangeVisibility }) => {
  const isVisible = useMemo(() => widgetData.style.isVisible, [widgetData]);
  const sortable = useSortable({ id: widgetData._id });
  const { attributes, listeners: moveListeners, setNodeRef, transform, transition } = sortable;

  const styles = {
    transform: CSS.Transform.toString(transform && { ...transform, scaleX: 1, scaleY: 1 }),
    transition,
    ...(!isVisible ? { opacity: "0.5", userSelect: "none" } : {}),
  };

  //Action hooks
  const handleDelete = useCallback(() => {
    onDelete?.(widgetData);
  }, [widgetData, onDelete]);

  const handleEdit = useCallback(() => {
    onEdit?.(widgetData);
  }, [widgetData, onEdit]);

  const handleChangeVisiblility = useCallback(() => {
    onChangeVisibility?.(widgetData);
  }, [widgetData, onChangeVisibility]);

  //UI elements
  const WidgetContent = useMemo(() => {
    const ContentComponent = Object.values(Widgets).find((w) => w.type == widgetData.type)?.WidgetContent;
    if (!ContentComponent) return null;

    return createElement(ContentComponent, { widgetData, editMode });
  }, [widgetData]);

  const WidgetHeader = useMemo(() => {
    let HeaderComponent = Object.values(Widgets).find((w) => w.type == widgetData.type)?.WidgetHeader || WidgetDefaultHeader;

    const ActionsComponent = (
      <WidgetActions
        onDelete={handleDelete}
        onEdit={handleEdit}
        onChangeVisibility={handleChangeVisiblility}
        moveListeners={moveListeners}
        isVisible={isVisible}
        editMode={editMode}
      />
    );

    return createElement(HeaderComponent, {
      widgetData,
      actions: ActionsComponent,
      editMode,
    });
  }, [widgetData, editMode, moveListeners, isVisible]);

  const CardStyle = useMemo(() => {
    let style = {};

    if (widgetData.style.border !== "none") {
      style["border"] = "1px solid";
      style["borderColor"] = `${widgetData.style.border}.main`;
    }

    if (widgetData.type === "HTML") {
      style["background"] = "transparent";
      style["boxShadow"] = "none !important";
    }

    return style;
  }, [widgetData]);

  if (!editMode && !isVisible) return null;

  return (
    <Grid item xs={12} lg={widgetData.style.size} ref={setNodeRef} sx={styles} {...attributes}>
      <Card sx={CardStyle}>
        {WidgetHeader}
        <WidgetContentWrapper widgetData={widgetData}>{WidgetContent}</WidgetContentWrapper>
      </Card>
    </Grid>
  );
};

export default WidgetGridItem;
