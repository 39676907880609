import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "./theme";
import { Helmet } from "react-helmet-async";

import "./App.css";
import Login from "./views/authenticate/Login";
import Logout from "./views/authenticate/Logout";
import { BrowserRouter, Navigate, Outlet, Route, Routes } from "react-router-dom";
import "src/locales/i18n";

// new imports
import RedesignFile from "./views/files/RedesignFile";
import RedesignScreens from "./views/screen/RedesignScreens";
import RedesignDashboard from "./views/newdashboar/RedesignDashboard";
import RedesignPlaylist from "./views/playlist/RedesignPlaylist";
import RedesignUserDetails from "./views/userdetails/RedesignUserDetails";
import ForgetPassword from "./views/authenticate/ForgetPassword";
import RedesignRestoreFile from "./views/files/RedesignRestoreFile";
import RedesignEmail from "./views/email/RedesignEmail";
import FeatureManager from "./views/featuremanager/FeatureManager";
import UserGroup from "./views/user/UserGroups";
// import RedesignApkManage from "./views/apkmanage/RedesignApkManage";
import RedesignApkManageNew from "./views/apkmanage/RedesignApkManageNew";
// import Dashboard from "./views/dashboard/Dashboard";
// import Report from "./views/report/Report";
import Report from "./views/report/ReportMui";
import { useDispatch, useSelector } from "react-redux";
import Signup from "./views/authenticate/Signup";
import VerifyAccount from "./views/authenticate/VerifyAccount";
import { useEffect, useRef } from "react";
import { getPlaylistsById } from "./redux/actions/Playlist";
import { getContentById } from "./redux/actions/Content";
import { Toaster } from "react-hot-toast";
import { socketInit } from "./socket";
import { useRefreshLogin } from "./hooks/useRefreshLogin";
import { getAllScreenById, screenStatusActive, screenStatusDeActive } from "./redux/actions/Screen";
import { getGroups } from "src/redux/actions/Group";
import { logout, socialMediaLogin } from "./redux/actions/User";
import Premiume from "./views/premiume/Premiume";
import PrivacyPolicy from "./views/privacy/PrivacyPolicy";
import { Box } from "@mui/material";
import { baseApi } from "./api/endpoints";
import { getAllUsers } from "./redux/actions/User";
import RedesignUser from "./views/user/RedesignUserNew";
import UserPlaylist from "./views/playlist/ByUserId/UserPlaylist";
import Folder from "./views/folder/Folder";
import { getFolders } from "./redux/actions/FileFolder";
import { getPlans } from "src/redux/actions/Plan";
import FolderComponent from "./views/folder/component/FolderComponent";
import ScreenDetail from "./views/screendetails/ScreenDetail";
import { fetchApk } from "./redux/actions/Apk";
import Circularbar from "./components/circularbar/Circularbar";
import ChangePassword from "./views/authenticate/ChangePassword";
import FullScreens from "./views/fullscreen/FullScreen";
import UserFile from "./views/files/ByUserId/UserFile";
import UserScreens from "./views/screen/ByUserId/UserScreens";

import { RTL } from "src/components/rtl";
import { SettingsConsumer, SettingsProvider } from "src/contexts/settings";

import AuthRouter from "./utils/auth-router";

import { ErrorBoundary } from "react-error-boundary";

const LOGIN_ALIVE_TIME = 6 * 60 * 60 * 1000; //6h
const events = ["load", "mousemove", "mousedown", "click", "scroll", "keypress"];
function App() {
  const { loading } = useRefreshLogin();
  const dispatch = useDispatch();
  const { user, auth, loggedin, users } = useSelector((state) => state.User);
  const socketRef = useRef();
  let timer;
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      resetTimer();
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      logoutAction();
    }, LOGIN_ALIVE_TIME);
  };
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };
  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
  }, []);
  const logoutAction = () => {
    // resetTimer();
    dispatch(logout());
    // window.location.pathname = "/login";
  };

  useEffect(() => {
    const connection = () => {
      socketRef.current = socketInit();
      socketRef.current.emit("join", {
        detail: {
          mac: "3eec16aaae46d1a9",
          ram: { total: "1514 MB", free: "867 MB" },
          storage: { total: "5951 MB", free: "5615 MB" },
          device_details: { ip: "192.168.232.8", height: 3120, width: 1440, software: "1.0.5", os_version: 25, manufacture: "Google", root_level: false },
        },
      });
      socketRef.current.emit("ActivateScreen", {
        codes: "7686",
        mac: "2b:3c:9d:12:yu",
      });
      socketRef.current.on("onescreen", (data) => {
        console.log("onescreen");
        console.log(data);
      });
      socketRef.current.on("admin_screen", ({ detail, left }) => {
        console.log("admin_screen");
        console.log(detail.mac);
        // dispatch(screenStatusActive(detail.mac, true));
      });
      socketRef.current.on("leave", (obj) => {
        console.log("from app-deactive", obj.mac);
        // dispatch(screenStatusDeActive(obj.mac, false));
      });
      socketRef.current.on("screenjoined", ({ mac }) => {
        console.log("joined: ", mac);
        dispatch(screenStatusActive(mac, true));
      });
      socketRef.current.on("leavescreen", ({ mac }) => {
        console.log("leavescreen");
        dispatch(screenStatusDeActive(mac, false));
      });
    };
    connection();
    return () => {
      socketRef.current.off("admin_screen");
      socketRef.current.off("leave");
      socketRef.current.off("screen");
      socketRef.current.off("screenjoined");
      socketRef.current.off("leavescreen");
    };
  }, []);

  useEffect(() => {
    console.log("app user=>", user);
    if (auth) {
      if (user.role !== "admin") {
        dispatch(getPlaylistsById(user?._id));
        dispatch(getContentById(user?._id));
        dispatch(getAllScreenById(user?._id));
        dispatch(getFolders(user?._id));
        dispatch(getGroups());
      } else {
        dispatch(fetchApk());
        dispatch(getPlans());
        dispatch(getAllUsers());
        dispatch(getPlaylistsById(user?._id));
        dispatch(getContentById(user?._id));
        dispatch(getAllScreenById(user?._id));
        dispatch(getFolders(user?._id));
        dispatch(getGroups());
      }
    }
  }, [auth, user]);
  useEffect(() => {
    const getUser = () => {
      fetch(`${baseApi}/login/success`, {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          throw new Error("authentication has been failed!");
        })
        .then((resObject) => {
          console.log("social media login=>", resObject.user);
          dispatch(socialMediaLogin(resObject.user));
        })
        .catch((err) => {
          console.log(err);
        });
    };
    //getUser();
  }, []);

  return loading ? (
    <>
      <Box
        sx={{
          minHeight: "100vh",
          maxWidth: "100vw",
          backgroundImage: `url("/images/loginside.png")`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          position: "relative",
          margin: 0,
          padding: 0,
          boxSizing: "border-box",
        }}>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Circularbar />
          {/* <img src='/images/spiner.gif' /> */}
        </Box>
      </Box>
    </>
  ) : (
    <>
      <SettingsProvider>
        <SettingsConsumer>
          {(settings) => {
            // console.log('app_settings', settings);

            const theme = createTheme({
              colorPreset: settings.colorPreset,
              contrast: settings.contrast,
              direction: settings.direction,
              paletteMode: settings.paletteMode,
              responsiveFontSizes: settings.responsiveFontSizes,
              typography: {
                fontFamily: "Open Sans",
              },
            });

            return (
              <div className="App">
                {/* <DndProvider backend={HTML5Backend}> */}
                <ThemeProvider theme={theme}>
                  <Helmet>
                    <meta name="color-scheme" content={settings.paletteMode} />
                    <meta name="theme-color" content={theme.palette.neutral[900]} />
                  </Helmet>
                  <RTL direction={settings.direction}>
                    <CssBaseline />
                    <Toaster
                      position={"top-center"}
                      toastOptions={{
                        duration: 5000,
                      }}
                    />
                    <ErrorBoundary fallback={null}>
                      <BrowserRouter>
                        <Routes>
                          <Route exact path={"/login"} element={<Login />} />
                          <Route exact path={"/auth/logout"} element={<Logout />} />
                          <Route exact path={"/forgetpassword"} element={<ForgetPassword />} />
                          <Route exact path={"/signup"} element={<Signup />} />
                          <Route exact path={"/privacypolicy"} element={<PrivacyPolicy />} />
                          <Route exact path={"/verifyaccount/:token"} element={<VerifyAccount />} />
                          <Route exact path={"/folder"} element={<Folder />} />
                          <Route exact path={"/foldercomponent/:folderId"} element={<FolderComponent />} />
                          <Route exact path={"/fullscreen"} element={<FullScreens />} />
                          <Route exact path={"/changepassword/:token"} element={<ChangePassword user={user} />} />
                          {/* <Route exact path={'/screen'} element={<Screens user={user} socketRef={socketRef} />} /> */}
                          {/* <Route exact path={'/screendetail'} element={<ScreenDetail user={user} />} /> */}
                          {/* <Route exact path={'/profile'} element={<Profile user={user} socketRef={socketRef} />} /> */}
                          <Route element={<PrivateRouter />}>
                            <Route exact path={"/"} element={<RedesignDashboard user={user} socketRef={socketRef} />} />
                            {/* <Route exact path={'/'} element={<Dashboard user={user} socketRef={socketRef} />} /> */}
                            <Route exact path={"/screen"} element={<RedesignScreens user={user} socketRef={socketRef} />} />
                            <Route exact path={"/user-screens/:id"} element={<UserScreens user={user} socketRef={socketRef} />} />
                            <Route element={<AuthRouter auth={(u, params) => u.role === "admin" || params.id == u._id} />}>
                              <Route exact path={"/user-details/:id"} element={<RedesignUserDetails />} />
                            </Route>
                            <Route exact path={"/user-details/edit/:id"} element={<RedesignUserDetails isEditActive={true} />} />
                            <Route exact path={"/screendetail/:id"} element={<ScreenDetail user={user} socketRef={socketRef} />} />
                            <Route exact path={"/screenfolders/:folderId?"} element={<RedesignScreens user={user} socketRef={socketRef} />} />
                            <Route exact path={"/report"} element={<Report user={user} />} />
                            <Route exact path={"/files/:folderId?"} element={<RedesignFile user={user} socketRef={socketRef} />} />
                            <Route exact path={"/user-content/:id"} element={<UserFile user={user} socketRef={socketRef} />} />
                            {/* <Route exact path={'/restorefile'} element={<RestoreFile user={user} />} /> */}
                            <Route element={<AuthRouter auth={(u) => u.plan.planFeatures.find((f) => f.slug == "restore_files" && f.value == 1)} />}>
                              <Route exact path={"/restorefile"} element={<RedesignRestoreFile user={user} />} />
                            </Route>
                            <Route exact path={"/playlist"} element={<RedesignPlaylist user={user} socketRef={socketRef} />} />
                            <Route exact path={"/user-playlist/:id"} element={<UserPlaylist user={user} socketRef={socketRef} />} />
                            {/* <Route exact path={'/apk'} element={<RedesignApkManage user={user} />} /> */}
                            <Route exact path={"/apk"} element={<RedesignApkManageNew user={user} />} />
                            <Route exact path={"/users/:currentGroupId?"} element={<RedesignUser user={user} />} />
                            <Route exact path={"/users/group"} element={<UserGroup user={user} socketRef={socketRef} />} />
                            {/* <Route exact path={'/email-templete'} element={<Email user={user} />} /> */}
                            <Route exact path={"/email-templete"} element={<RedesignEmail user={user} />} />
                            <Route element={<AuthRouter auth={(u, params) => u.role === "admin"} />}>
                              <Route exact path={"/feature-manager"} element={<FeatureManager socketRef={socketRef} />} />
                            </Route>
                            {/* <Route exact path={'/privacypolicy'} element={<PrivacyPolicy/>} /> */}
                          </Route>
                          <Route element={<PremiumePrivateRouter />}>
                            <Route exact path={"/premiume"} element={<Premiume />} />
                          </Route>
                        </Routes>
                      </BrowserRouter>
                    </ErrorBoundary>
                  </RTL>
                </ThemeProvider>
                {/* </DndProvider> */}
              </div>
            );
          }}
        </SettingsConsumer>
      </SettingsProvider>
    </>
  );
}

const PrivateRouter = () => {
  const { auth, premiume, isVerified } = useSelector((state) => state.User);
  console.log("its from private router=", isVerified);
  return (
    <>
      {/* {auth ?  isVerified ?
       (
          <Outlet />
          
          )
        :
       ( 
       <Navigate to={'/login'}/>
       )
       :
       ( <Navigate to={"/login"} />)
      } */}

      {auth ? (
        !isVerified ? (
          <Navigate to="/login" />
        ) : (
          <ErrorBoundary fallback={null}>
            <Outlet />
          </ErrorBoundary>
        )
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
};
const PremiumePrivateRouter = () => {
  const { auth, premiume } = useSelector((state) => state.User);
  return <>{!auth && premiume ? <Navigate to={"/premiume"} /> : <Navigate to="/login" />}</>;
};
export default App;
