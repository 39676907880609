import { Button, TextField, Box, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, Checkbox, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

function AddPlaylistModal({ openCreatePlaylist, value, onClose, textValue, savePlaylist, isAdmin, defaultPlaylist, setDefaultPlaylist }) {
  const { t } = useTranslation();

  return (
    <>
      <Dialog open={openCreatePlaylist} onClose={() => onClose(false)} maxWidth={"lg"} sx={{ zIndex: "1000000" }}>
        <FormControl component="form" onSubmit={(e) => e.preventDefault()}>
          <DialogTitle sx={{ textAlign: "center" }}>{t("views.addPlaylistModal.title")}</DialogTitle>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}>
              <Box sx={{ margin: "20px 0" }}>
                <TextField
                  variant={"standard"}
                  fullWidth
                  required
                  type={"text"}
                  label={t("views.addPlaylistModal.playlistNameLabel")}
                  value={value}
                  onChange={(e) => textValue(e.target.value)}
                />
              </Box>
              {isAdmin && (
                <Box
                  className="checkbox-wrap"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    "& .checkbox-label": { fontWeight: "400", fontSize: "15px", lineHeight: "27px", color: "#92959E" },
                    "& .checkbox-wrap": { display: "flex", justifyContent: "flex-start", alignItems: "center" },
                  }}>
                  <Checkbox checked={defaultPlaylist} size={"small"} onChange={(e) => setDefaultPlaylist(e.target.checked)} />
                  <Typography variant={"caption"} className="checkbox-label">
                    {t("views.addPlaylistModal.adminPlaylist")}
                  </Typography>
                </Box>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant={"outlined"}
              sx={{
                height: "38px",
                width: "193px",
                textTransform: "capitalize",
                borderRadius: "5px",
              }}
              onClick={() => onClose(false)}>
              {t("views.addPlaylistModal.closeButton")}
            </Button>
            <Button
              variant={"contained"}
              onClick={() => savePlaylist()}
              sx={{
                height: "38px",
                width: "193px",
                textTransform: "capitalize",
                borderRadius: "5px",
              }}
              type="submit">
              {t("views.addPlaylistModal.saveButton")}
            </Button>
          </DialogActions>
        </FormControl>
      </Dialog>
    </>
  );
}

export default AddPlaylistModal;
