import React from "react";
import ContentList from "./ContentList";
import { Box, Typography, Divider, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    contentList: {
      "&:hover::-webkit-scrollbar": {
        display: "block",
        transition: "display .5s",
      },
      "&::-webkit-scrollbar": {
        width: "5px",
        borderRadius: "5px",
        display: "none",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#fffff",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "blue",
      },
      "&::-wekit-scrollbar-corner": {
        borderRadius: "5px",
      },
    },
    headIconStyle: {
      color: "#92959E",
      fontFamily: "open sans",
      fontStyle: "normal",
      fontWeight: "600",
      cursor: "pointer",
    },
    topHeading: {
      display: "flex",
      gap: "10px",
      margin: "0 7px",
    },
    headingIcon: {
      width: "56px",
      height: "58px",
      background: "rgba(1, 106, 254, 0.1)",
      borderRadius: "10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    btnGroups: {
      display: "flex",
      justifyContent: "center",
      margin: "10px 0",
    },
  };
});

function PlaylistData({
  playlist,
  onShowData,
  onDeleteContent,
  onUpdateContentDuration,
  onEditPlaylistName,
  onUnchecked,
  isOver,
  time,
  onSetTime,
  keyChange,
  onFocus,
  onContentStartDrag,
  onContentOverDrag,
  onContentDropped,
  lastContentId,
  isMainContent,
  user,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Box
        sx={{
          height: "60px",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "10px",
        }}>
        <Typography variant="body1"> {playlist?.playlistName?.substring(0, 20)}</Typography>
        {user.isMember ? (
          user.previlliages.playlist.canEdit ? (
            <IconButton aria-label="delete" onClick={() => onEditPlaylistName(playlist._id)}>
              <EditIcon color="primary" />
            </IconButton>
          ) : (
            <></>
          )
        ) : (
          <IconButton aria-label="delete" onClick={() => onEditPlaylistName(playlist._id)}>
            <EditIcon />
          </IconButton>
        )}
      </Box>

      <Divider />

      {!isOver && <Box className="drag-drop">{t("views.playlistData.dragAndDrop")}</Box>}
      {isOver ? (
        <Box className="drag-drop">{t("views.playlistData.dragAndDrop")}</Box>
      ) : (
        <Box
          sx={{
            maxHeight: "60vh",
            width: "97%",
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            background: "white",
          }}
          className={classes.contentList}>
          {playlist?.content?.map((c) => {
            return (
              <ContentList
                key={c._id}
                content={c}
                onShowData={onShowData}
                onDeleteContent={onDeleteContent}
                onUpdateContentDuration={onUpdateContentDuration}
                onUnchecked={onUnchecked}
                time={time}
                onSetTime={onSetTime}
                keyChange={keyChange}
                onFocus={onFocus}
                onContentStartDrag={onContentStartDrag}
                onContentOverDrag={onContentOverDrag}
                onContentDropped={onContentDropped}
                lastContentId={lastContentId}
                isMainContent={isMainContent}
                user={user}
              />
            );
          })}
        </Box>
      )}
    </>
  );
}

export default PlaylistData;
