import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import { Scrollbar } from "src/components/scrollbar";
import { useState, useEffect, useCallback } from "react";
import { getUserNotes, addUserNote, deleteUserNote } from "../../redux/actions/UserNote";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import SvgIcon from "@mui/material/SvgIcon";
import IconButton from "@mui/material/IconButton";
import ConfirmDialog from "src/components/dialog/ConfirmDialog";
import TablePagination from "@mui/material/TablePagination";

import formatDate from "src/utils/format-date";

function RedesignUserNotes({ user }) {
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const [note, setNote] = useState("");
  const [noteToDelete, setNoteToDelete] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { userNotes } = useSelector((state) => state.UserNote);

  useEffect(() => {
    dispatch(getUserNotes(user._id));
  }, []);

  const handleAddNote = useCallback(() => {
    if (note !== "") {
      dispatch(
        addUserNote(user._id, {
          content: note,
        })
      );
      setNote("");
      document.getElementById("note").value = "";
    } else {
      toast.error(t("views.userNotes.emptyNoteError"));
    }
  }, [dispatch, user._id, note, t]);

  const handleDeleteNote = useCallback(() => {
    if (noteToDelete !== "") {
      dispatch(deleteUserNote(user._id, noteToDelete));
      setConfirmOpen(false);
    }
  }, [dispatch, user._id, noteToDelete]);

  const handleClickDelete = useCallback(
    (id) => {
      setNoteToDelete(id);
      setConfirmOpen(true);
    },
    [dispatch]
  );

  const handleAbortDelete = useCallback(() => {
    setNoteToDelete("");
    setConfirmOpen(false);
  }, [dispatch, noteToDelete]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Card>
        <CardHeader title={t("views.userNotes.addNotes")} />
        <CardContent sx={{ pt: 0 }}>
          <FormControl sx={{ width: "100%" }} variant={"standard"}>
            <TextField id="note" placeholder={t("views.userNotes.notePlaceholder")} type="text" variant="outlined" onChange={(e) => setNote(e.target.value)} />
          </FormControl>
          <Button sx={{ marginTop: "20px" }} variant="outlined" onClick={() => handleAddNote()}>
            {t("views.userNotes.addNote")}
          </Button>
        </CardContent>
      </Card>

      <Card>
        <CardHeader title={t("views.userNotes.notes")} />
        <Scrollbar>
          <Table sx={{ minWidth: 700 }}>
            <TableHead>
              <TableRow>
                <TableCell>{t("views.userNotes.userNotes")}</TableCell>
                <TableCell>{t("views.userNotes.date")}</TableCell>
                <TableCell>{t("views.userNotes.email")}</TableCell>
                <TableCell align="right">{t("views.userNotes.actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userNotes.length > 0 &&
                userNotes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((note) => {
                  return (
                    <TableRow key={note._id}>
                      <TableCell>{note.content}</TableCell>
                      <TableCell>{formatDate(new Date(note.createdAt))}</TableCell>
                      <TableCell>{note.author?.email}</TableCell>
                      <TableCell align="right">
                        <IconButton onClick={() => handleClickDelete(note._id)}>
                          <SvgIcon>
                            <DeleteIcon />
                          </SvgIcon>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Scrollbar>
        <TablePagination
          component="div"
          count={userNotes.length}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage={t("views.userNotes.rowsQty")}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
      <ConfirmDialog open={isConfirmOpen} title={t("views.userNotes.deleteConfirmation")} onClose={() => handleAbortDelete()} onAccept={() => handleDeleteNote()} />
    </>
  );
}

export default RedesignUserNotes;
