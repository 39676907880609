import * as DOMPurify from 'dompurify';

DOMPurify.addHook('uponSanitizeElement', (node, data) => {
    if (data.tagName === 'iframe') {
        const src = node.getAttribute('src') || ''
        if (!(
            src.startsWith('https://www.canva.com/design/') ||
            /cloudfront.net\/index\.php\/posterbuilder\/view\/(.*)$/.test(src)
        )) {
            return node.parentNode?.removeChild(node)
        }
    }
})


export default DOMPurify;