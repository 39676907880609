import { api } from "../../api/endpoints";
import {
  ADD_APK,
  ADD_APK_SUCCESS,
  APK_ALREADY_SAVED,
  APK_DELETE,
  APK_FLAG_OFF,
  APK_INDENTITY,
  FETCH_APK_REQUEST,
  FETCH_APK_SUCCESS,
} from "../constant";
export const apkFlagOff = () => {
  return async (dispatch) => {
    dispatch({
      type: APK_FLAG_OFF,
      payload: "",
    });
  };
};
export const apkUpload = (obj) => {
  return async (dispatch) => {
    dispatch({
      type: ADD_APK,
      payload: ''
    })
    const apk = await api.post(`/apk/apkfile`, obj);
    console.log(apk.data);
    if (apk.status === 201) {
      if (apk.data.required) {
        if (apk.data.isAlreadyAvailable) {
          dispatch({
            type: APK_ALREADY_SAVED,
            payload: "",
          });
        } else {
          dispatch({
            type: ADD_APK_SUCCESS,
            payload: apk.data.apk,
          });
        }
      } else {
        dispatch({
          type: APK_INDENTITY,
          payload: ''
        })
      }

    }
  };
};
export const fetchApk = () => {
  return async (dispatch) => {
    dispatch({
      type: FETCH_APK_REQUEST,
      payload: "",
    });
    const { status, data } = await api.get(`/apk/getapk`);
    if (status === 200) {
      dispatch({
        type: FETCH_APK_SUCCESS,
        payload: data.apk,
      });
    }
  };
};
export const apkDelete = (id) => {
  return async (dispatch) => {
    const apk = await api.get(`/apk/deleteapk/${id}`);
    if (apk.status === 201) {
      dispatch({
        type: APK_DELETE,
        payload: id,
      });
    }
  };
};
