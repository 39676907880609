import React, {useState, useEffect, useRef, useMemo} from 'react';
import { Dialog, DialogContent, DialogTitle, Box, Grid, IconButton, Divider, DialogActions,Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ZoneCanvasControl from './ZoneCanvasControl';
import ZoneDetailsControl from './ZoneDetailsControl';
import { FitScreen, Orientation, ContentType } from './types';
import { resolutionCounters,  UnitTypes, ResolutionTypes, BackgroundMusicTypes, AudioZoneTypes, PrimaryZoneTypes } from './types';

import { useDispatch, useSelector } from "react-redux";
import {
  getPlaylistsById,
  editPlaylistDemoAttribute,
} from "../../../../redux/actions/Playlist";

const DialogBoxEditScreen = ({ isOpen, onClose, orientation, rectanglesUnfolding }) => {
  const dispatch = useDispatch();
    const { user} = useSelector((state) => state.User);
    const { playlists, playlist, isOnePlaylist, isPlaylistLoaded } = useSelector((state) => state.Playlist);

    // ---- useStates ----
    const [settingsInfo, setSettingsInfo] = React.useState({
      "Unit Type": UnitTypes.PERCENTAGE,
      "Resolution": ResolutionTypes.HD,
      "Background Music": BackgroundMusicTypes.NONE,
      "Audio Zone": AudioZoneTypes.ALL,
      "Primary Zone": PrimaryZoneTypes.NONE,
  })
    const [screenInfo, setScreenInfo] = useState({name:"", orientation: Orientation.LANDSCAPE, customWidth: 0, customHeight: 0});
    const [zonesDetails, setZonesDetails] = useState([{name:"",id:"",x:0,y:0,width:0,height:0,type:ContentType.ASSET,content_name:"",content_path:"",fit_screen:FitScreen.FIT}]); // Zones details
    const [zonesHistory, setZonesHistory] = useState([]); // Zones history
    const [historyPointer, setHistoryPointer] = useState(0);
    const [stageSize, setStageSize] = useState({
      width: window.innerWidth * 0.4 ,
      height: window.innerWidth * 0.4 / resolutionCounters.HD,
    });
    const [selectedId, setSelectedId] = useState(null);
    const debounceTimeout = useRef(null);

    // ---- Functions ----
    const changeScreenInfo = (key, value) => {
      setScreenInfo(prevScreenInfo => {
        return {...prevScreenInfo, [key]: value}
      })

      if (key === "orientation"){
        if (value === Orientation.LANDSCAPE){
          setStageSize(prevStageSize => {
            return {width: window.innerWidth * 0.4, height: window.innerWidth * 0.4 / resolutionCounters.HD}
          })
        }
        else if (value === Orientation.PORTRAIT){
          setStageSize(prevStageSize => {
            return {width: window.innerHeight * 0.5 / resolutionCounters.HD, height: window.innerHeight * 0.5}
          })
        }
      }
    }

    const changeZoneDetails = (index, key, value) => {
      console.log("ChangeZoneDetails ", key,  value)
      var newZoneDetails = [];
      if (Array.isArray(value) && Array.isArray(key)){
        newZoneDetails = zonesDetails.map((zone, i) => {
          if (i === index) {
            // Tworzymy nowy obiekt z podmienionymi kluczami i wartościami
            let updatedZone = { ...zone };
            key.forEach((k, idx) => {
              updatedZone[k] = value[idx];
            });
            return updatedZone;
          }
          return zone;
        });
      
        setZonesDetails(newZoneDetails)
      }

      else{
        newZoneDetails = zonesDetails.map((zone, i) => {
          if (i === index){
            return {...zone, [key]: value}
          }
          return zone;
        })
        setZonesDetails(newZoneDetails)
      }

      // Change History
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      debounceTimeout.current = setTimeout(() => {
        if (historyPointer == 0){
          setZonesHistory(prev => [...prev, newZoneDetails]);
        }
        else{
          setZonesHistory(prev => [...prev.slice(0,zonesHistory.length + historyPointer-1), newZoneDetails]);
          setHistoryPointer(0);
        }
      }, 1500);
    }

    // ---- useEffects ----
    useEffect(() => {
      if (user?.isMember) {
        dispatch(getPlaylistsById(user?.user?._id));
      } else {
        dispatch(getPlaylistsById(user?._id));
      }
    }, [user]);


    useEffect(() => {
      const newZonesSet = rectanglesUnfolding.map((item, index) => {
        return {
          name: `Zone ${index+1}`,
          x: item.startX * stageSize.width,
          y: item.startY * stageSize.height,
          width: item.w_factor * stageSize.width,
          height: item.h_factor * stageSize.height,
          fill: item.fill,
          fit_screen: FitScreen.FIT,
          type:ContentType.NONE,
          opacity: 0.7,
          id: `rect${index}`,
          scaleX: 1,
          scaleY: 1,
        }
      })
      setZonesDetails(newZonesSet)
      setZonesHistory([newZonesSet]);
      
    },[rectanglesUnfolding, stageSize]);

    useEffect(() => {
      if (zonesHistory.length > 0){
        setZonesDetails(zonesHistory[zonesHistory.length + historyPointer-1]);
      }
    }, [historyPointer])

    useEffect(() => {
      const handleResize = () => {
        if (orientation === Orientation.LANDSCAPE){
          setScreenInfo(prevScreenInfo => {
            return {...prevScreenInfo, orientation: Orientation.LANDSCAPE}
          })
          setStageSize(prevStageSize => {
            return {width: window.innerWidth * 0.5, height: window.innerHeight * 0.5}
          })
        }
        else if (orientation === Orientation.PORTRAIT){
          setScreenInfo(prevScreenInfo => {
            return {...prevScreenInfo, orientation: Orientation.PORTRAIT}
          })
          setStageSize(prevStageSize => {
            return {width: window.innerHeight * 0.6, height: window.innerWidth * 0.2}
          })
        }
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, [orientation]);

    // Change screen size on orientation change - orientation chosen during screen creation
    useEffect(() => {
      if (orientation === Orientation.LANDSCAPE){
        setScreenInfo(prevScreenInfo => {
          return {...prevScreenInfo, orientation: Orientation.LANDSCAPE}
        })
        setStageSize(prevStageSize => {
          return {width: window.innerWidth * 0.4, height: window.innerWidth * 0.4 / resolutionCounters.HD}
        })
      }
      else if (orientation === Orientation.PORTRAIT){
        console.log("CHANGEEE PORTRAIT")
        setScreenInfo(prevScreenInfo => {
          return {...prevScreenInfo, orientation: Orientation.PORTRAIT}
        })
        setStageSize(prevStageSize => {
          return {width: window.innerHeight * 0.5 / resolutionCounters.HD, height: window.innerHeight * 0.5}
        })
      }
    },[orientation]);


    // ---- useMemo ----
    const memoizedZoneDetailsControl = useMemo(() => (
      <ZoneDetailsControl
        playlists={playlists}
        settingsInfo={settingsInfo}
        selectedId={selectedId}
        screenInfo={screenInfo}
        changeScreenInfo={changeScreenInfo}
        zones={zonesDetails}
        changeZoneDetails={changeZoneDetails}
        stageSize={stageSize}
      />
    ), [
      playlists,
      settingsInfo,
      selectedId,
      screenInfo,
      changeScreenInfo,
      zonesDetails,
      changeZoneDetails,
      stageSize,
    ]);

  return (
    <Dialog
      fullScreen 
      open={isOpen}
      onClose={() => onClose(false)}
      sx={{
        overflow:"none",
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{ backgroundColor: "#f0f0f0", padding: "12px", position: 'relative' }} // 2*8px = 16px (top+ bottom = 32px)
      >
        <IconButton
          onClick={() => onClose(false)}
          sx={{
            position: 'absolute',
            top: 16,
            right: 16,
            color: 'black',
            zIndex: 1300, 
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle sx={{ textAlign: "center", fontWeight: "bold", color:"black"}} style={{fontSize:"32px" }}> 
          Edit Zones
        </DialogTitle>
      </Box>

      <DialogContent sx={{ padding: 2, height: 'calc(100vh - 128px)' }}>  {/* Dodanie paddingu i wysokości */}
      <Grid container spacing={2}  sx={{ height: '100%', maxHeight:  'calc(100vh - 128px)', padding:"20px" }}>
        <Grid item xs={12} md={4} >
            {memoizedZoneDetailsControl}
        </Grid>
        <Grid item xs={12} md={8}>
            <ZoneCanvasControl screenInfo={screenInfo} setScreenInfo={setScreenInfo} settingsInfo={settingsInfo} setSettingsInfo = {setSettingsInfo} selectedId={selectedId} setSelectedId={setSelectedId} zoneDetails={zonesDetails} setZonesDetails={setZonesDetails} zonesHistory={zonesHistory} setZonesHistory={setZonesHistory} historyPointer={historyPointer} setHistoryPointer={setHistoryPointer} stageSize={stageSize}/>
        </Grid>
      </Grid>
      </DialogContent>
      <Divider sx={{ width: '100%' }} />
        <DialogActions sx={{ backgroundColor: "#1A2432", width: "100%", padding:"8px",paddingTop:"7px", paddingBottom:"7px" }}>
            <Button color="primary" variant="contained">
                Save
            </Button>
        </DialogActions>
    </Dialog>
  );
};

export default DialogBoxEditScreen;
