import React, {useEffect, useRef, useMemo, useCallback} from 'react'
import {Select, MenuItem, FormControl, InputLabel,TextField} from '@mui/material';
import { FitScreen, Orientation, ContentType } from './types';
import SingleZone from './SingleZone';
 
const ZoneDetailsControl = ({ playlists, settingsInfo, selectedId, screenInfo, changeScreenInfo, zones, changeZoneDetails, stageSize}) => {
    var selected_idx = -100;
    const zoneRefs = useRef([]);

    // Używamy useEffect, aby przewijać do wybranego zone po zmianie selectedId
    useEffect(() => {
        setTimeout(() => {
            const selectedZoneIndex = zones.findIndex(zone => zone.id === selectedId);
            if (selectedZoneIndex !== -1 && zoneRefs.current[selectedZoneIndex]) {
                zoneRefs.current[selectedZoneIndex].scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        },100)
    }, [selectedId]);
    
  return (
    <div style={{
        display:"flex",
        flexDirection:"column",
        width: "100%",
        overflowY:"auto",
        maxHeight:"calc(100vh - 193px)", // Ogólnie wychodzi mi 64px + 40px + 32px+5px = 141px  + footer
        paddingRight:"10px",
        paddingBottom:"30px"
    }}>
        <div style={{
                paddingTop:"5px",
                display:"flex",
                flexDirection:"column",
                gap:"24px",
                width:"100%",
            }}>
            <TextField
            label="Name"
            variant="outlined"
            value={screenInfo.name}
            onChange={(e)=>changeScreenInfo("name",e.target.value)}
            fullWidth
        />
            <FormControl fullWidth variant="outlined">
                <InputLabel id="select-label">Orientation</InputLabel>
                <Select
                labelId="select-label"
                value={screenInfo.orientation}
                onChange={(e)=>changeScreenInfo("orientation",e.target.value)}
                label="Orientation"
                >
                {Object.entries(Orientation).map(([key, value]) => (
                    <MenuItem key={key} value={value}>
                        {value}
                    </MenuItem>
                ))}
                </Select>
            </FormControl>
        </div>

        <div>
            {zones.map((zone, index) => {
                if (selectedId == zone.id){
                    selected_idx = index;
                }
                console.log(",mapping to zone details")
                return (
                    <div 
                    ref={el => zoneRefs.current[index] = el}
                    key={zone.id}
                    style={{
                        backgroundColor: selectedId == zone.id ? "#1A2432" : "#111927",
                        paddingBottom: selectedId == zone.id ? "30px" : "0px"
                    }}>
                        <div style={{height: "1px", backgroundColor: selectedId == zone.id ? zone.fill : "white", width:"100%", margin:"auto", marginTop:index == selected_idx+1 ? "0px": "30px"}}></div>
                        <div style={{height: "9px", backgroundColor: selectedId == zone.id ? zone.fill : "#111927", width:"100%", margin:"auto", marginBottom:"30px"}}></div>

                        <SingleZone
                            playlists={playlists}
                            settingsInfo={settingsInfo}
                            zone={zone}
                            changeZoneDetails={changeZoneDetails}
                            idx={index}
                            stageSize={stageSize}
                        />
                    </div>
                )
            }
        )}
        </div>       
    </div>
  )
}

export default ZoneDetailsControl