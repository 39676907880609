import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Checkbox } from "@mui/material";
import { useState } from "react";
import CardYt from "./CardYt";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { addWidget } from "../../../redux/actions/Content";
import { useTranslation } from "react-i18next"; // Import the hook

export default function DialogBoxYt({ isOpen, onClose, onTakeData, isIcon, onAddWidget }) {
  const { t } = useTranslation(); // Initialize the translation hook
  const [items, setItems] = useState([]);
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [item, setItem] = useState({});
  const [mute, setMute] = useState(false);
  const dispatch = useDispatch();
  const { currentFolder } = useSelector((state) => state.FileFolder);
  const { user } = useSelector((state) => state.User);

  React.useEffect(() => {
    handleClickOpen();
  }, [isOpen, onClose]);

  const handleClickOpen = () => {
    if (isOpen) {
      setName("");
      setUrl("");
    }
    if (onClose) {
      setName("");
      setUrl("");
    }
  };

  const handleMute = (e) => {
    const { checked } = e.target;
    checked ? setMute(true) : setMute(false);
  };

  const handleAdd = () => {
    const ytId = (() => {
      var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
      var match = url.match(regExp);
      return match && match[2].length == 11 ? match[2] : false;
    })();

    if (name === "") {
      toast.error(t("views.dialogBoxYt.fillRequiredFields"));
      return;
    }

    if (ytId) {
      setItems((previous) => [...previous, { title: name, url, id: ytId }]);
      onTakeData({ title: name, url, id: ytId, isIcon });
      const muteVal = mute ? 1 : 0;
      const h = `<iframe title="Youtube Video" width="329px" height="288px" style={{ border: "1px solid black", borderRadius: "10px" }} src={"https://www.youtube.com/embed/{{id}}?autoplay=1&mute={{muteVal}}"} ></iframe>`;
      const emUrl = `https://www.youtube.com/embed/{{id}}?autoplay=1&mute={{muteVal}}`;
      const htmltostr = h.replace(/"/g, '\\"').replace(/\n/g, "\\n").toString();
      onAddWidget({
        name,
        type: "widget",
        widgetCat: "youtube",
        widgetIcon: isIcon,
        url: emUrl.replace("{{id}}", ytId).replace("{{muteVal}}", muteVal),
        html: htmltostr.replace(/\\"/g, '"').replace(/\\n/g, "\n").replace("{{id}}", ytId).replace("{{muteVal}}", muteVal),
        folderId: currentFolder,
        user: user._id,
      });
      setName("");
      setUrl("");
      setItem({});
      setPreviewOpen(false);
      //toast.success(t("views.dialogBoxYt.success"));
      onClose();
    } else {
      setName("");
      setUrl("");
      toast.error(t("views.dialogBoxYt.invalidUrl"));
    }
  };

  const handlePreviewOpen = () => {
    const array = url.split(".");
    const ytId = url.split("=");
    const muteVal = mute ? 1 : 0;
    const emUrl = `https://www.youtube.com/embed/${ytId[1]}?rel=0&amp;autoplay=1&muted=1`;
    if (array[1] === "youtube") {
      setItem({ title: name, url: emUrl, id: ytId[1] });
      setPreviewOpen(true);
    } else {
      toast.error(t("views.dialogBoxYt.invalidUrl"));
    }
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
    setItem({});
  };

  return (
    <>
      <Dialog fullWidth open={isOpen} onClose={() => onClose(false)}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <img src="/images/youtubeIcon.png" alt="" width={"15%"} style={{ alignSelf: "center", marginTop: "20px" }} />
        </div>
        <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>{t("views.dialogBoxYt.youtube")}</DialogTitle>
        <DialogContent sx={{ minHeight: "30vh" }}>
          <div
            style={{
              display: "flex",
              marginTop: 20,
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <DialogContentText sx={{ fontWeight: "bold" }}>{t("views.dialogBoxYt.name")}</DialogContentText>
            <TextField
              id="outlined-basic"
              label={t("views.dialogBoxYt.yourName")}
              variant="outlined"
              fullWidth
              sx={{ marginLeft: 3, width: "465px" }}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              marginTop: 20,
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <DialogContentText sx={{ fontWeight: "bold" }}>{t("views.dialogBoxYt.url")}</DialogContentText>
            <TextField
              id="outlined-basic"
              label="URL"
              variant="outlined"
              fullWidth
              sx={{ marginLeft: 3, width: "465px" }}
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              marginTop: 10,
              alignItems: "center",
            }}>
            <DialogContentText sx={{ fontWeight: "bold" }}>{t("views.dialogBoxYt.mute")}</DialogContentText>
            <Checkbox sx={{ marginLeft: 4 }} checked={mute ? true : false} onChange={handleMute} />
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 2,
            marginTop: 1,
          }}>
          <div>
            <Button variant="contained" color="primary" onClick={handlePreviewOpen}>
              {t("views.dialogBoxYt.preview")}
            </Button>
          </div>
          <div>
            <Button onClick={() => onClose(false)}>{t("views.dialogBoxYt.cancel")}</Button>
            <Button variant="contained" color="primary" onClick={handleAdd}>
              {t("views.dialogBoxYt.save")}
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth open={previewOpen} onClose={handlePreviewClose}>
        <img src="/images/youtubeIcon.png" alt="" width={"15%"} style={{ alignSelf: "center", marginTop: "20px" }} />
        <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>{t("views.dialogBoxYt.youtube")}</DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: "#e5e3e3",
            minHeight: "30vh",
            display: "flex",
            justifyContent: "center",
          }}>
          <div>
            <CardYt item={item} />
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 2,
            marginTop: 1,
          }}>
          <div>
            <Button variant="contained" color="primary" onClick={handleAdd}>
              {t("views.dialogBoxYt.confirm")}
            </Button>
          </div>
          <div>
            <Button onClick={handlePreviewClose}>{t("views.dialogBoxYt.cancel")}</Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
