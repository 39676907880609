import React, { useState, useRef, useEffect } from 'react';

const PlayerScaler = ({ children }) => {

    const containerRef = useRef(null);
    const contentRef = useRef(null);

    const [scale, setScale] = useState(1);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            if (containerRef.current && contentRef.current) {
                const containerWidth = containerRef.current.offsetWidth;
                const containerHeight = containerRef.current.offsetHeight;
                const contentWidth = contentRef.current.offsetWidth;
                const contentHeight = contentRef.current.offsetHeight;

                const scaleWidth = containerWidth / contentWidth;
                const scaleHeight = containerHeight / contentHeight;

                const scaleFactor = Math.min(scaleWidth, scaleHeight);

                setScale(scaleFactor);
            }
        });

        if (containerRef.current)
            resizeObserver.observe(containerRef.current);

        return () => {
            if (containerRef.current)
                resizeObserver.unobserve(containerRef.current)
        }
    }, [children]);

    return (
        <div
            ref={containerRef}
            style={{
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                display: 'flex',
                position: 'realtive',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <div
                ref={contentRef}
                style={{
                    minWidth: '100%',
                    minHeight: '100%',
                    transform: `scale(${scale}, ${scale})`,
                    transformOrigin: 'top center',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                }}
            >
                {children}
            </div>
        </div>
    )

};

export default PlayerScaler;