import {
    GET_LOGS_SUCCESS,
} from "../constant";

const initial = {
    logs: [],
    page: 1,
    limit: 10,
    allLogs: 0,
    pageCount:0,
    searchResults: [],
};

export const LogReducer = (state = initial, action) => {
    switch (action.type) {
        case GET_LOGS_SUCCESS:
            return {
                ...state,
                logs: action.payload.logs,
                page: action.payload.page,
                limit: action.payload.limit,
                allLogs: action.payload.allLogs,
                pageCount: action.payload.pageCount,
            }
        default:
            return state
    }
};