import { makeStyles, Typography } from "@material-ui/core";
import { Box } from "@mui/material";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { resolveSrc } from "../../../api/endpoints";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import ImageIcon from "@mui/icons-material/Image";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => {
  return {
    contentListContainer: {},
    focusInput: {
      "&.focus": { border: "none" },
    },
  };
});

function ContentList({
  content,
  onShowData,
  onDeleteContent,
  onUpdateContentDuration,
  onUnchecked,
  time,
  onSetTime,
  keyChange,
  onContentStartDrag,
  onContentOverDrag,
  onContentDropped,
  lastContentId,
  onFocus,
  user,
}) {
  const { t } = useTranslation();
  const { content: contentList } = content;
  const classes = useStyles();
  const isMobile = useMediaQuery({ query: "(max-width: 786px)" });

  const onContentDragStart = (e, id) => {
    onContentStartDrag(e, id);
  };
  const onContentDragOver = (e, id) => {
    onContentOverDrag(e, id);
  };
  const onContentDroped = (e) => {
    onContentDropped(e);
  };

  return (
    contentList !== null && (
      <>
        <Box
          draggable
          onDragStart={(e) => onContentDragStart(e, content._id)}
          onDragOver={(e) => onContentDragOver(e, content._id)}
          onDrop={(e) => onContentDroped(e)}
          sx={{
            minHeight: "10vh",
            height: "100%",
            width: "100%",
            display: "flex",
            transform: "translateX(-22px)",
            cursor: "pointer",
            background: content.timeChecked && "rgba(85,31,255,0.2)",
          }}>
          <Box
            sx={{
              height: "100%",
              width: "50%",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}>
            <DragHandleIcon
              sx={{
                color: "#551ffa",
              }}
            />
          </Box>

          <Box
            sx={{
              height: "100%",
              width: "50%",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}>
            <Box
              sx={{
                height: "50px",
                width: "50px",
                borderRadius: "10px",
              }}>
              {content?.content?.type === "image" && (
                <img
                  src={resolveSrc(content?.content?.url)}
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "10px",
                    objectFit: "cover",
                  }}
                />
              )}
              {content?.content?.type === "widget" && (
                <img
                  src={`${content?.content?.widget?.widgetIcon}`}
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "10px",
                    objectFit: "cover",
                  }}
                />
              )}
              {content?.content?.type === "video" && (
                <video
                  src={resolveSrc(content?.content?.url)}
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "10px",
                    objectFit: "cover",
                  }}
                />
              )}
              {content?.content?.embed === "embed" && (
                <embed
                  src={`${content?.content?.url}`}
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "10px",
                    objectFit: "cover",
                  }}
                />
              )}
            </Box>
            <Box sx={{ width: "100px", height: "50px", fontSize: "12px", fontWeight: "600", display: "flex", alignItems: "center", margin: "0 5px" }}>
              {content?.content?.name?.substring(0, 20)}
            </Box>
            <Box
              sx={{
                height: "14px",
                width: "14px",
              }}>
              {content?.content?.type === "image" && <ImageIcon sx={{ color: "#551ffa" }} />}
              {content?.content?.type === "widget" && <img src={"/images/appColorIcon.png"} />}
              {content?.content?.type === "video" && <SlideshowIcon sx={{ color: "#551ffa" }} />}
            </Box>
          </Box>

          <Box
            sx={{
              height: "100%",
              width: "25%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            {user.isMember ? (
              user.previlliages.playlist.canEdit ? (
                <Box
                  sx={{
                    height: "4vh",
                    width: isMobile ? "22vw" : "8vw",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "5px",
                    cursor: "pointer",
                  }}
                  onClick={() => onUnchecked(content._id, content?.duration)}
                  onKeyPress={(e) => keyChange(e)}
                  onMouseDown={(e) => keyChange(e)}
                  onFocus={(e) => onFocus(e)}>
                  <img src={"/images/clockColorIcon.png"} />
                  {content?.timeChecked ? (
                    <input
                      type={"text"}
                      autoFocus={true}
                      className={"input-focus"}
                      onChange={(e) => onSetTime(e)}
                      value={time}
                      style={{ outline: "none", width: "30px", background: "transparent", border: "none" }}
                    />
                  ) : (
                    <Typography
                      style={{
                        fontWeight: "400",
                        fontSize: "small",
                        color: "#15192c",
                        fontFamily: "open sans",
                        fontStyle: "normal",
                      }}>
                      {content?.content?.type === "video" ? `${0}` : `${content?.duration / 1000}`}
                    </Typography>
                  )}
                </Box>
              ) : (
                <Box
                  sx={{
                    height: "4vh",
                    width: isMobile ? "22vw" : "8vw",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "5px",
                    cursor: "pointer",
                  }}
                  onClick={() => onUnchecked(content._id, content?.duration)}>
                  <Typography
                    style={{
                      fontWeight: "400",
                      fontSize: "small",
                      color: "#15192c",
                      fontFamily: "open sans",
                      fontStyle: "normal",
                    }}>
                    {content?.content?.type === "video" ? `${0}` : `${content?.duration / 1000}`}
                  </Typography>
                </Box>
              )
            ) : (
              <Box
                sx={{
                  height: "4vh",
                  width: isMobile ? "22vw" : "8vw",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px",
                  cursor: "pointer",
                }}
                onClick={() => onUnchecked(content._id, content?.duration)}
                onKeyPress={(e) => keyChange(e)}
                onMouseDown={(e) => keyChange(e)}
                onFocus={(e) => onFocus(e)}>
                <img src={"/images/clockColorIcon.png"} />
                {content?.timeChecked ? (
                  <input
                    type={"text"}
                    autoFocus={true}
                    className={"input-focus"}
                    onChange={(e) => onSetTime(e)}
                    value={time}
                    style={{ outline: "none", width: "30px", background: "transparent", border: "none" }}
                  />
                ) : (
                  <Typography
                    style={{
                      fontWeight: "400",
                      fontSize: "small",
                      color: "#15192c",
                      fontFamily: "open sans",
                      fontStyle: "normal",
                    }}>
                    {content?.content?.type === "video" ? `${0}` : `${content?.duration / 1000}`}
                  </Typography>
                )}
              </Box>
            )}
            {t("views.contentList.sec")}
          </Box>

          <Box
            sx={{
              height: "100%",
              width: "25%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}>
            {user.isMember ? (
              user.previlliages.playlist.canDelete ? (
                <img src="/images/trash.png" style={{ cursor: "pointer" }} onClick={() => onDeleteContent(content._id)} />
              ) : (
                <></>
              )
            ) : (
              <img src="/images/trash.png" style={{ cursor: "pointer" }} onClick={() => onDeleteContent(content._id)} />
            )}
          </Box>
        </Box>
      </>
    )
  );
}

export default ContentList;
