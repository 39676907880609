import React, { useEffect, useState } from "react";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import AppsIcon from "@mui/icons-material/Apps";
import { FaSortAmountDownAlt, FaAngleDown, FaSortNumericUp, FaSortNumericDownAlt, FaSortAlphaDownAlt, FaSortAlphaUp, FaTrash, FaAngleUp } from "react-icons/fa";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import { Checkbox, Button, Box, Dialog, DialogTitle, DialogContent, TextField, Menu, MenuItem } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { changeFolder, createFolder } from "../../redux/actions/FileFolder";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { contentAscByCreatedAt, contentAscByName, contentDescByCreatedAt, contentDescByName } from "../../redux/actions/Content";
import { Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";

function BodyHeader({ user, view, onViewChange, isChecked, onCheckedContent, onUncheckedContent, onGetCheckedContent }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentFolder, currentFolderData, userFolders, content } = useSelector(
    (state) => ({
      currentFolder: state.FileFolder.currentFolder,
      currentFolderData: state.FileFolder.userFolders.find((u) => u._id === state.FileFolder.currentFolder),
      userFolders: state.FileFolder.userFolders,
      content: state.Content.content,
    }),
    shallowEqual
  );
  const [folderName, setFolderName] = useState("");
  const [openCreateFolder, setOpenCreateFolder] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  useEffect(() => {
    const openBtn = document.querySelector("#openBtn");
    const closeBtn = document.querySelector("#closeBtn");
    const pagg = document.querySelector("#pagination-box");
    const btnGrp = document.querySelector("#btn-group");
    openBtn?.addEventListener("click", function (e) {
      btnGrp.classList.remove("hidden-rm");
      pagg.classList.remove("show-rm");
      pagg?.classList.add("hidden-rm");
      btnGrp.classList.add("show-rm");
    });
    closeBtn?.addEventListener("click", function (e) {
      pagg.classList.remove("hidden-rm");
      btnGrp.classList.remove("show-rm");
      pagg?.classList.add("show-rm");
      btnGrp.classList.add("hidden-rm");
    });
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (type) => {
    if (type === "desc") {
      dispatch(contentDescByCreatedAt());
    }
    if (type === "asc") {
      dispatch(contentAscByCreatedAt());
    }
    if (type === "name_desc") {
      dispatch(contentDescByName());
    }
    if (type === "name_asc") {
      dispatch(contentAscByName());
    }
    setAnchorEl(null);
  };
  const makeFolder = async () => {
    setOpenCreateFolder(false);
    setFolderName("");
    if (folderName === "") {
      toast.error(t("components.tableBodies.fillFolderNameField"));
    } else {
      const folder = await dispatch(
        createFolder({
          name: folderName,
          parent: currentFolder,
          folderFor: "content",
          role: user?.role,
          user: user?._id,
          subuser: user?._id,
          path: currentFolder === "root" ? [] : [...currentFolderData.path, { parent: currentFolder, name: currentFolderData.name }],
        })
      );

      if (!folder)
        toast.error(t("components.bodyHeader.folderAlreadyAvailable"));
    }
  };
  const handleNavigate = (link, id) => {
    navigate(link);
    dispatch(changeFolder(id));
  };
  const onChangeScreenBox = (e) => {
    const { checked } = e.target;
    if (checked) {
      onCheckedContent();
    } else {
      onUncheckedContent();
    }
  };
  const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
  const isCellPhone = useMediaQuery({ query: "(max-width:540px" });

  return (
    <>
      {isCellPhone ? (
        <Box
          sx={{
            height: "5vh",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}>
          <Box id={"pagination-box"} className={"file-paggination-box"}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                height: "100%",
                width: "90%",
              }}>
              <div role="presentation" style={{ display: "flex", width: "100%", margin: "0 5px", fontSize: "10px" }}>
                {currentFolder !== "root" ? (
                  <>
                    {" "}
                    <Button onClick={() => handleNavigate("/files", "root")}>{t("components.bodyHeader.home")}</Button>
                    {currentFolderData.path.map((c, index) => (
                      <>
                        {" "}
                        <Button
                          onClick={() =>
                            handleNavigate(
                              `/filefoldercomponent/${userFolders.find((u) => u._id === c.parent)?._id}`,
                              `${userFolders.find((u) => u._id === c.parent)?._id}`
                            )
                          }>
                          {userFolders.find((u) => u._id === c.parent)?.name}
                        </Button>
                      </>
                    ))}
                    <li
                      style={{
                        listStyle: "none",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      {currentFolderData?.name}
                    </li>
                  </>
                ) : (
                  <>
                    <li
                      style={{
                        listStyle: "none",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      {t("components.bodyHeader.home")}
                    </li>
                  </>
                )}
              </div>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "10%",
              }}>
              <FaAngleUp id={"openBtn"} />
            </Box>
          </Box>

          <Box id={"btn-group"} className={"file-btn-group-box"}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                height: "100%",
                width: "90%",
                gap: "5px",
              }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "10%",
                }}>
                <Checkbox onChange={onChangeScreenBox} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  height: "100%",
                  width: "90%",
                  gap: "5px",
                }}>
                {isChecked ? (
                  <>
                    <Button
                      sx={{
                        height: { xl: "35px", lg: "35px", md: "35px", xs: "30px" },
                        minWidth: { xl: "120px", lg: "120px", xs: "15vw" },
                        color: "#313031",
                        fontFamily: "open sans",
                        fontStyle: "normal",
                        fontSize: isMobile ? "8px" : "10px",
                        fontWeight: 600,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        border: "1px solid rgba(0, 0, 0, 0.2)",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                      variant={"outlined"}
                      onClick={() => setOpenCreateFolder(true)}
                      disabled={user.checkFeatureAcesss("create_folders")}>
                      <CreateNewFolderOutlinedIcon
                        style={{
                          fontSize: isMobile ? "10px" : "20px",
                          marginLeft: "-12px",
                        }}
                      />{" "}
                      <span
                        style={{
                          margin: "5px 0",
                        }}>
                        {t("components.bodyHeader.createFolder")}
                      </span>
                    </Button>

                    <Button
                      sx={{
                        height: { xl: "35px", lg: "35px", md: "35px", xs: "30px" },
                        minWidth: { xl: "120px", lg: "120px", xs: "10vw" },
                        color: "#313031",
                        fontFamily: "open sans",
                        fontStyle: "normal",
                        fontSize: "20px",
                        fontWeight: 600,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        border: "1px solid rgba(0, 0, 0, 0.2)",
                        borderRadius: "4px",
                      }}
                      variant={"outlined"}
                      onClick={() => onViewChange()}>
                      {view ? <AppsIcon style={{ fontSize: isMobile ? "16px" : "25px" }} /> : <ListOutlinedIcon style={{ fontSize: isMobile ? "16px" : "25px" }} />}
                    </Button>

                    <Button
                      sx={{
                        height: { xl: "35px", lg: "35px", md: "35px", xs: "30px" },
                        minWidth: { xl: "120px", lg: "120px", xs: "10vw" },
                        color: "#313031",
                        fontFamily: "open sans",
                        fontStyle: "normal",
                        fontSize: "20px",
                        fontWeight: 600,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        border: "1px solid rgba(0, 0, 0, 0.2)",
                        borderRadius: "4px",
                      }}
                      variant={"outlined"}
                      onClick={handleClick}
                      aria-controls={open ? "content-menu" : undefined}
                      aria-haspopup={"true"}
                      aria-expanded={open ? "true" : undefined}
                      id="content-menu-btn">
                      <FaSortAmountDownAlt style={{ fontSize: isMobile ? "16px" : "20px" }} />
                    </Button>
                    <Menu
                      id={"content-menu"}
                      anchorEl={anchorEl}
                      open={open}
                      MenuListProps={{
                        "aria-labelledby": "content-menu-btn",
                      }}
                      onClose={handleClose}
                      keepMounted
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}>
                      <MenuItem onClick={() => handleClose("desc")}>
                        <FaSortNumericDownAlt style={{ fontSize: "20px" }} />
                        &nbsp;{t("components.bodyHeader.descByCreatedAt")}
                      </MenuItem>
                      <MenuItem onClick={() => handleClose("asc")}>
                        <FaSortNumericUp style={{ fontSize: "20px" }} />
                        &nbsp;{t("components.bodyHeader.ascByCreatedAt")}
                      </MenuItem>
                      <MenuItem onClick={() => handleClose("name_desc")}>
                        <FaSortAlphaDownAlt style={{ fontSize: "20px" }} />
                        &nbsp;{t("components.bodyHeader.descByName")}
                      </MenuItem>
                      <MenuItem onClick={() => handleClose("name_asc")}>
                        <FaSortAlphaUp style={{ fontSize: "20px" }} />
                        &nbsp;{t("components.bodyHeader.ascByName")}
                      </MenuItem>
                    </Menu>
                  </>
                ) : (
                  <>
                    {user.isMember ? (
                      user.previlliages.content.canDelete ? (
                        <Tooltip title={t("components.bodyHeader.delete")}>
                          <FaTrash onClick={() => onGetCheckedContent()} />
                        </Tooltip>
                      ) : (
                        <></>
                      )
                    ) : (
                      <Tooltip title={t("components.bodyHeader.delete")}>
                        <FaTrash onClick={() => onGetCheckedContent()} />
                      </Tooltip>
                    )}
                    <Tooltip title={t("components.bodyHeader.addToPlaylist")}>
                      <PlaylistAddIcon />
                    </Tooltip>
                    <Tooltip title={t("components.bodyHeader.moveToFolder")}>
                      <MoveUpIcon />
                    </Tooltip>
                  </>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "10%",
              }}>
              <FaAngleDown id={"closeBtn"} />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            height: "80%",
            width: "95%",
            display: "flex",
          }}>
          {/* left side */}
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "5px",
            }}>
            <Box
              sx={{
                height: "50px",
                width: isMobile ? "60px" : "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Box
                sx={{
                  height: "100%",
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <Checkbox onChange={onChangeScreenBox} style={isMobile ? { transform: "translateX(12px)" } : {}} />
                <FaAngleDown style={isMobile ? { marginRight: "10px" } : {}} />
              </Box>
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  position: "relative",
                }}>
                <Box
                  sx={{
                    position: "absolute",
                    margin: "0 -5px",
                    height: "13px",
                    width: "2px",
                    background: "#92959E",
                    marginLeft: "1px",
                  }}></Box>
                <div role="presentation" style={{ display: "flex", width: "100%", margin: "0 5px" }}>
                  {currentFolder !== "root" ? (
                    <>
                      {" "}
                      <Button onClick={() => handleNavigate("/files", "root")}>{t("components.bodyHeader.home")}</Button>
                      {currentFolderData.path.map((c, index) => (
                        <>
                          {" "}
                          <Button
                            onClick={() =>
                              handleNavigate(
                                `/filefoldercomponent/${userFolders.find((u) => u._id === c.parent)?._id}`,
                                `${userFolders.find((u) => u._id === c.parent)?._id}`
                              )
                            }>
                            {userFolders.find((u) => u._id === c.parent)?.name}
                          </Button>
                        </>
                      ))}
                      <li
                        style={{
                          listStyle: "none",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}>
                        {currentFolderData?.name}
                      </li>
                    </>
                  ) : (
                    <>
                      <li
                        style={{
                          listStyle: "none",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}>
                        {t("components.bodyHeader.home")}
                      </li>
                    </>
                  )}
                </div>
              </Box>
            </Box>
          </Box>
          {/* right side */}
          {isChecked ? (
            <Box
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "5px",
              }}>
              <Button
                sx={{
                  height: { xl: "35px", lg: "35px", md: "35px" },
                  width: { xl: "120px", lg: "120px" },
                  color: "#313031",
                  fontFamily: "open sans",
                  fontStyle: "normal",
                  fontSize: isMobile ? "8px" : "10px",
                  fontWeight: 600,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
                variant={"outlined"}
                onClick={() => setOpenCreateFolder(true)}
                disabled={user.isMember ? (user.previlliages.folder.canCreate ? false : true) : false}>
                <CreateNewFolderOutlinedIcon
                  style={{
                    fontSize: isMobile ? "12px" : "20px",
                    marginLeft: "-12px",
                  }}
                />{" "}
                <span
                  style={{
                    margin: "5px 0",
                  }}>
                  {t("components.bodyHeader.createFolder")}
                </span>
              </Button>

              <Button
                sx={{
                  height: { xl: "35px", lg: "35px", md: "35px" },
                  width: "40px",
                  color: "#313031",
                  fontFamily: "open sans",
                  fontStyle: "normal",
                  fontSize: "20px",
                  fontWeight: 600,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                  borderRadius: "4px",
                }}
                variant={"outlined"}
                onClick={() => onViewChange()}>
                {view ? <AppsIcon style={{ fontSize: isMobile ? "16px" : "25px" }} /> : <ListOutlinedIcon style={{ fontSize: isMobile ? "16px" : "25px" }} />}
              </Button>

              <Button
                sx={{
                  height: { xl: "35px", lg: "35px", md: "35px" },
                  width: "40px",
                  color: "#313031",
                  fontFamily: "open sans",
                  fontStyle: "normal",
                  fontSize: "20px",
                  fontWeight: 600,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                  borderRadius: "4px",
                }}
                variant={"outlined"}
                onClick={handleClick}
                aria-controls={open ? "content-menu" : undefined}
                aria-haspopup={"true"}
                aria-expanded={open ? "true" : undefined}
                id="content-menu-btn">
                <FaSortAmountDownAlt style={{ fontSize: isMobile ? "16px" : "20px" }} />
              </Button>
              <Menu
                id={"content-menu"}
                anchorEl={anchorEl}
                open={open}
                MenuListProps={{
                  "aria-labelledby": "content-menu-btn",
                }}
                onClose={handleClose}
                keepMounted
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}>
                <MenuItem onClick={() => handleClose("desc")}>
                  <FaSortNumericDownAlt style={{ fontSize: "20px" }} />
                  &nbsp;{t("components.bodyHeader.descByCreatedAt")}
                </MenuItem>
                <MenuItem onClick={() => handleClose("asc")}>
                  <FaSortNumericUp style={{ fontSize: "20px" }} />
                  &nbsp;{t("components.bodyHeader.ascByCreatedAt")}
                </MenuItem>
                <MenuItem onClick={() => handleClose("name_desc")}>
                  <FaSortAlphaDownAlt style={{ fontSize: "20px" }} />
                  &nbsp;{t("components.bodyHeader.descByName")}
                </MenuItem>
                <MenuItem onClick={() => handleClose("name_asc")}>
                  <FaSortAlphaUp style={{ fontSize: "20px" }} />
                  &nbsp;{t("components.bodyHeader.ascByName")}
                </MenuItem>
              </Menu>
            </Box>
          ) : (
            <Box
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "5px",
              }}>
              {user.isMember ? (
                user.previlliages.content.canDelete ? (
                  <Tooltip title={t("components.bodyHeader.delete")}>
                    <FaTrash onClick={() => onGetCheckedContent()} />
                  </Tooltip>
                ) : (
                  <></>
                )
              ) : (
                <Tooltip title={t("components.bodyHeader.delete")}>
                  <FaTrash onClick={() => onGetCheckedContent()} />
                </Tooltip>
              )}
              <Tooltip title={t("components.bodyHeader.addToPlaylist")}>
                <PlaylistAddIcon />
              </Tooltip>
              <Tooltip title={t("components.bodyHeader.moveToFolder")}>
                <MoveUpIcon />
              </Tooltip>
            </Box>
          )}
        </Box>
      )}
      <Dialog open={openCreateFolder} onClose={() => setOpenCreateFolder(false)} maxWidth={"lg"}>
        <DialogTitle sx={{ textAlign: "center" }}>{t("components.bodyHeader.createFolder")}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: isMobile ? "70vw" : "50vw",
              height: "50vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}>
            <Box style={{ width: "183px", height: "154.41px", margin: "0 auto" }}>
              <img src="/images/addFolderIcon.png" />
            </Box>
            <Box sx={{ margin: "20px 0" }}>
              <TextField
                variant={"standard"}
                fullWidth
                type={"text"}
                label={t("components.bodyHeader.typeFolderName")}
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "40px 0",
                gap: "10px 10px",
              }}>
              <Button
                variant={"outlined"}
                sx={{
                  height: "38px",
                  width: "193px",
                  textTransform: "capitalize",
                  borderRadius: "10px",
                }}
                onClick={() => setOpenCreateFolder(false)}>
                {t("components.bodyHeader.close")}
              </Button>
              <Button
                variant={"contained"}
                sx={{
                  height: "38px",
                  width: "193px",
                  textTransform: "capitalize",
                  borderRadius: "10px",
                }}
                onClick={makeFolder}>
                {t("components.bodyHeader.save")}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default BodyHeader;
