import React, { useState, useEffect } from "react";
import { Typography, Box, Button, Switch, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useTranslation } from "react-i18next";

import { baseImg } from "../../../../api/endpoints";
import { FitScreen } from "../EditScreen/types";
import { FaBars } from "react-icons/fa6";
import { TbRotate360 } from "react-icons/tb";
import { AiFillSound } from "react-icons/ai";
import { RiVolumeMuteFill } from "react-icons/ri";

import OrientationDialog from "./OrientationDialog";
import { RotationTypes } from "../EditScreen/types";

const items = [
    // { text: 'Orientation', icon: <TbRotate360/> },
    { text: 'Mute', icon: <AiFillSound /> },
]

function PreviewPlayer({ mainWidth, mainHeight, open, onClose, player }) {
    console.log(">>> Player inside preview: ", player);
    const { t } = useTranslation();
    const [isHovered, setIsHovered] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openOrientationDialog, setOpenOrientationDialog] = useState(false);
    const [androidSettings, setAndroidSettings] = useState({ orientation: RotationTypes.LANDSCAPE, audio: true });

    const handleContentFit = (content) => {
        if (player && player.length > 0) {
            switch (content.fit_screen) {
                case FitScreen.FIT:
                    return "contain";
                case FitScreen.NONE:
                    return "initial";
                case FitScreen.ZOOM:
                    return "cover";
                case FitScreen.STRETCH:
                    return "fill";
                default:
                    return "contain";
            }
        }
        else {
            return "contain"
        }
    }
    const handleContentZIndex = (content) => {
        if (player && player.length > 0) {
            return content.queue * 10;
        }
        else {
            return 0
        }
    }

    const getContentStyle = (content) => ({
        width: "100%",
        height: "100%",
        objectFit: handleContentFit(content),
        border: "none",
        margin: 0,
        padding: 0,
        boxShadow: "none",
        outline: "none",
        backgroundColor: "#0e1320",
        zIndex: handleContentZIndex(content),
    });

    const rotationStyle = (orientation) => {
        let rotationDegree;
        switch (orientation) {
            case RotationTypes.LANDSCAPE:
                rotationDegree = 0;
                break;
            case RotationTypes.ROTATE_90:
                rotationDegree = 90;
                break;
            case RotationTypes.ROTATE_180:
                rotationDegree = 180;
                break;
            case RotationTypes.ROTATE_270:
                rotationDegree = 270;
                break;
            default:
                rotationDegree = 0;
        }
        return {
            transform: `rotate(${rotationDegree}deg)`,
            transformOrigin: 'center',
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        };
    };

    return (
        <Box
            sx={{
                height: `${mainHeight}px`,
                width: `${mainWidth}px`,
                maxHeight: `${mainHeight}px`,
                maxWidth: `${mainWidth}px`,
                background: "#0e1320",
                zIndex: "1000000000000",
                position: "relative",
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setTimeout(() => { setIsHovered(false) }, 2000)}
        >
            {isHovered && (
                <FaBars
                    size={24}
                    onClick={() => setOpenDrawer(prev => !prev)}
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        zIndex: 1000000000001,
                        cursor: 'pointer',
                        pointerEvents: 'auto'
                    }}
                />
            )}

            {/* Dialog Component */}
            <OrientationDialog androidSettings={androidSettings} setAndroidSettings={setAndroidSettings} open={openOrientationDialog} onClose={setOpenOrientationDialog} title={"Rotation"} />


            {/* Slide-in Panel */}
            <Box
                sx={{
                    height: '100%',
                    width: openDrawer ? '40%' : '0', // Slide in to 30% width
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    backgroundColor: '#1c1f2b', // Panel color
                    transition: 'width 0.5s ease', // Smooth transition
                    zIndex: 1000,
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto', // Handle overflow of content
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '10px',
                        backgroundColor: "#006aff",
                        borderBottom: '1px solid rgba(255, 255, 255, 0.1)', // Optional divider between items
                    }}
                >
                    <Typography sx={{ color: '#white', fontWeight: "bold", fontSize: "20px", margin: "auto" }}>VC Player</Typography>
                </Box>
                {/* Map through items and render rows #006aff*/}
                {items.map((item, index) => (
                    <Box
                        key={index}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '10px',
                            paddingTop: '15px',
                            paddingBottom: '15px',
                            borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                            cursor: 'pointer',
                            transition: 'background-color 0.3s ease', // Dodaj płynne przejście
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.2)', // Kolor tła na hover
                            },
                        }}
                        onClick={() => {
                            if (item.text == "Orientation") {
                                setOpenDrawer(false);
                                setOpenOrientationDialog(true);
                            }
                            if (item.text == "Mute") {
                                setAndroidSettings(prev => ({ ...prev, audio: !prev.audio }))
                            }
                        }}
                    >

                        {/* Left Column: Icon */}
                        {item.text == "Mute" ? androidSettings.audio ? item.icon : <RiVolumeMuteFill /> : item.icon}
                        {/* Right Column: Text */}
                        <Typography sx={{ color: '#fff', marginLeft: "15px" }}>{item.text}</Typography>
                        {item.text == "Mute" && <Switch sx={{ marginLeft: "auto" }} checked={!androidSettings.audio} onChange={() => setAndroidSettings(prev => ({ ...prev, audio: !prev.audio }))} />}
                    </Box>
                ))}
            </Box>



            {/* Dimmed Overlay */}
            {(openDrawer || openOrientationDialog) && (
                <Box
                    sx={{
                        height: '100%',
                        width: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dimmed effect
                        transition: 'opacity 0.5s ease', // Smooth transition
                        zIndex: 999, // Below the slide-in panel
                    }}
                    onClick={() => {
                        setOpenDrawer(false)
                        setOpenOrientationDialog(false)
                    }} // Close the panel when clicked
                />
            )}

            {/* Render Player Elements */}
            {player && player.length > 0 && (
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        ...rotationStyle // Apply rotation to container of content
                    }}
                >
                    {player && player.length > 0 && player.map((x, index) => {
                        const featureType = x.type == "Playlist" ? x.playlistContent.type : x.type;
                        const featureUrl = x.type == "Playlist" ? x.playlistContent.type == "widget" ? x.playlistContent.widget.url : x.playlistContent.url : x.type == "widget" ? x.widget.url : x.url;
                        const featureHtml = x.type == "Playlist" ? x.playlistContent.widget?.html : x.widget?.html
                        const widgetCat = x.type == "Playlist" ? x.playlistContent.widget?.widgetCat : x.widget?.widgetCat
                        console.log(">>> Feature Type: ", featureType);
                        console.log(">>> Feature Url: ", featureUrl);
                        console.log(">>> Widget Cat: ", widgetCat);

                        return (
                            <Box key={index}
                                sx={{
                                    height: `${x.height}px`,
                                    width: `${x.width}px`,
                                    position: "absolute",
                                    left: `${x.x}px`,
                                    top: `${x.y}px`,
                                    backgroundColor: "#0e1320",
                                    zIndex: x.queue * 10,
                                }}
                            >
                                {featureType === "image" && <img src={`${baseImg}/${featureUrl}`} style={getContentStyle(x)} />}
                                {featureType === "video" && <video muted={androidSettings.audio} autoPlay src={`${baseImg}/${featureUrl}`} style={getContentStyle(x)}></video>}
                                {player.embed === "embed" && <embed src={`${featureUrl}`} style={getContentStyle(x)} controls />}
                                {featureType === "widget" && (
                                    <>
                                        {widgetCat === "youtube" && <iframe muted={androidSettings.audio} src={featureUrl} style={getContentStyle(x)}></iframe>}
                                        {widgetCat === "vimeo" && <iframe muted={androidSettings.audio} src={featureUrl} style={getContentStyle(x)}></iframe>}
                                        {widgetCat === "website" && <iframe muted={androidSettings.audio} src={featureUrl} style={getContentStyle(true)}></iframe>}
                                        {widgetCat === "scrollingstrip" && (
                                            <div style={getContentStyle(x)} dangerouslySetInnerHTML={{ __html: featureHtml }}></div>
                                        )}
                                        {widgetCat === "embed" && <div style={getContentStyle(x)} dangerouslySetInnerHTML={{ __html: featureHtml }}></div>}
                                        {widgetCat === "weather" && <div style={getContentStyle(x)} dangerouslySetInnerHTML={{ __html: featureHtml }}></div>}
                                    </>
                                )}
                            </Box>
                        );
                    })}
                </Box>
            )}
        </Box>

    );
}

export default PreviewPlayer;
