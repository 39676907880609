import { api } from "../../api/endpoints";
import {
    CHANGE_PLAN_FEATURE,
    GET_PLANS_SUCCESS,
    GET_PLAN_SUCCESS,
    CHANGE_PLAN_FEATURE_DRAFT,
    SAVE_PLANS_FEATURES,
    SEARCH_PLANS,
    CLEAN_DRAFT
} from "../constant";


export const getPlans = () => {
    return async (dispatch) => {
        const response = await api.get(`/plan`);
        if (response.status === 200) {
            dispatch({
                type: GET_PLANS_SUCCESS,
                payload: response.data,
            });
        }
    };
};
export const getPlanById = (id) => {
    return async (dispatch) => {
        const response = await api.get(`/plan/${id}`);
        if (response.status === 200) {
            dispatch({
                type: GET_PLAN_SUCCESS,
                payload: response.data,
            });
        }
    };
};


export const changePlanFeature = (obj) => {
    return async (dispatch) => {
        dispatch({
            type: CHANGE_PLAN_FEATURE,
            payload: obj,
        });
        await api.patch(`/plan/${obj.planId}/feature/${obj.featureId}`, {
            enabled: obj.enabled,
        });
    };
};

export const changePlanFeatureDraft = (obj) => {
    return async (dispatch) => {
        dispatch({
            type: CHANGE_PLAN_FEATURE_DRAFT,
            payload: obj,
        });
    };
};

export const searchPlanFeatures = (query) => {
    return async (dispatch) => {
        dispatch({
            type: SEARCH_PLANS,
            payload: query,
        });
    };
};



export const savePlans = (obj) => {
    return async (dispatch) => {
        dispatch({
            type: SAVE_PLANS_FEATURES,
            payload: obj,
        });
        await api.patch(`/plan`, { plans: obj });
    };
};