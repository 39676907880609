import { Typography, Card } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const WidgetsGridAddButton = ({ onClick }) => {

    return (
        <Card variant="outlined" bg="transparent" sx={{
            cursor: "pointer",
            background: "transparent"
        }} onClick={onClick}>
            <Typography align="center" py={4}>
                <AddIcon fontSize="large" />
            </Typography>
        </Card>
    );

}

export default WidgetsGridAddButton;