import { useCallback } from "react";
import { useTranslation } from "react-i18next";

const useForm18nValidation = () => {
    const { t } = useTranslation();

    const parseError18n = useCallback((error) => {
        return error?.type ? t(`validation.error.${error.type}`) : error.message;
    }, [t]);

    const parseErrors18n = useCallback((data) => Object.entries(data).reduce((errors, [fieldName, fieldError]) => ({
        ...errors,
        [fieldName]: {
            message: parseError18n(fieldError)
        }
    }), {}), [parseError18n]);


    return { parseError18n, parseErrors18n };
};

export default useForm18nValidation;