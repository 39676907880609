import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardHeader from "@mui/material/CardHeader";
import { useTranslation } from "react-i18next";

import { PropertyList } from "src/components/property-list";
import { PropertyListItem } from "src/components/property-list-item";

export const CustomerBasicDetails = (props) => {
  const { formattedAddress, address1, address2, country, email, isVerified, phone, state, ...other } = props;
  const { t } = useTranslation();

  return (
    <Card {...other}>
      <CardHeader title={t("views.customerBasicDetails.personalProfile")} />
      <PropertyList>
        <PropertyListItem divider label={t("views.customerBasicDetails.email")} value={email} />
        <PropertyListItem divider label={t("views.customerBasicDetails.phone")} value={phone} />
        <PropertyListItem divider label={t("views.customerBasicDetails.country")} value={country} />
        <PropertyListItem divider label={t("views.customerBasicDetails.address")} value={formattedAddress} />
      </PropertyList>
    </Card>
  );
};

CustomerBasicDetails.propTypes = {
  formattedAddress: PropTypes.string,
  address2: PropTypes.string,
  address1: PropTypes.string,
  country: PropTypes.string,
  email: PropTypes.string.isRequired,
  isVerified: PropTypes.bool.isRequired,
  phone: PropTypes.string,
  state: PropTypes.string,
};
