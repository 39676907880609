import { api } from "../../api/endpoints";
import {
  DELETE_SCREEN,
  DELETE_SCREEN_REQUEST,
  GET_ALL_SCREEN_SUCCESS,
  GET_ONE_SCREEN,
  SCREEN_REGISTER,
  SCREEN_REGISTER_FLAG_OFF,
  SCREEN_STATUS_ACTIVE,
  SCREEN_STATUS_DEACTIVE,
  EDIT_SCREEN,
  SCREEN_MOVE_TO_FOLDER,
  SCREEN_PAIRED,
  SCREEN_UNPAIRED,
} from "../constant";

export const registerScreen = (code, obj) => {
  return async (dispatch) => {
    try {
      const updatedScreen = await api.patch(`/screen/screenregister/${code}`, obj);
      if (updatedScreen.status === 201) {
        dispatch({
          type: SCREEN_REGISTER,
          payload: updatedScreen.data.updatedScreen,
        });
        return updatedScreen.data.updatedScreen; // Return the updated screen data
      }
    } catch (error) {
      console.error("Error registering screen:", error);
      throw error; // Rethrow the error so we can catch it in the component
    }
  };
};
export const updateScreen = (id, obj) => {
  return async (dispatch) => {
    const updatedScreen = await api.patch(`/screen/screenupdate/${id}`, {
      preventLastActive: true,
      ...obj
    });
    if (updatedScreen.status === 201) {
      dispatch({
        type: EDIT_SCREEN,
        payload: updatedScreen.data.updatedScreen,
      });
    }
  };
};
export const registerScreenFlagOff = () => {
  return async (dispatch) => {
    dispatch({
      type: SCREEN_REGISTER_FLAG_OFF,
      payload: "",
    });
  };
};
export const getAllScreen = () => {
  return async (dispatch) => {
    const { data, status } = await api.get(`/screen/getscreens`);
    if (status === 200) {
      dispatch({
        type: GET_ALL_SCREEN_SUCCESS,
        payload: data.screens,
      });
    }
  };
};
export const getAllScreenById = (id) => {
  return async (dispatch) => {
    const { data, status } = await api.get(`/screen/getscreens/${id}`);
    if (status === 200) {
      dispatch({
        type: GET_ALL_SCREEN_SUCCESS,
        payload: data.screens,
      });
    }
  };
};
export const getOneScreen = (id) => {
  return async (dispatch) => {
    dispatch({
      type: GET_ONE_SCREEN,
      payload: id,
    });
  };
};
export const screenStatusActive = (mac, status) => {
  return async (dispatch) => {
    dispatch({
      type: SCREEN_STATUS_ACTIVE,
      payload: mac,
    });
    // const updated = await api.patch(
    //   `/screen/screenstatusupdate/${encodeURIComponent(mac)}`,
    //   { status }
    // );
    // if (updated.status === 201) {

    // }
  };
};
export const screenStatusDeActive = (mac, status) => {
  return async (dispatch) => {
    dispatch({
      type: SCREEN_STATUS_DEACTIVE,
      payload: mac,
    });
    // const updated = await api.patch(
    //   `/screen/screenstatusupdate/${encodeURIComponent(mac)}`,
    //   { status }
    // );
    // if (updated.status === 201) {

    // }
  };
};
export const unPairedScreen = (id) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_SCREEN_REQUEST,
      payload: "",
    });
    const { data, status } = await api.patch(`/screen/unpairscreen/${id}`);
    if (status === 201) {
      dispatch({
        type: SCREEN_UNPAIRED,
        payload: data.updated,
      });
    }
  };
};
export const pairedScreen = (id) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_SCREEN_REQUEST,
      payload: "",
    });
    const { data, status } = await api.patch(`/screen/pairscreen/${id}`);
    if (status === 201) {
      dispatch({
        type: SCREEN_PAIRED,
        payload: data.updated,
      });
    }
  };
};
export const screenMoveToFolder = (obj) => {
  return async (dispatch) => {
    const screen = await api.patch(`/screen/screenmovetofolder/${obj.id}`, obj);
    if (screen.status === 201) {
      dispatch({
        type: SCREEN_MOVE_TO_FOLDER,
        payload: screen.data.screen,
      });
    }
  };
};
