import { useEffect } from "react";
import { useFormContext } from 'react-hook-form-mui';
import { ColorPicker, useColor } from "react-color-palette";

const ColorInputElement = ({ name }) => {

    const { getValues, setValue } = useFormContext();

    const [color, setColor] = useColor(getValues(name) ?? "red");

    useEffect(() => {
        if (color)
            setValue(name, color.hex);
    }, [name, color]);

    return <ColorPicker hideInput={["rgb", "hsv"]} color={color} onChange={setColor} />;

};

export default ColorInputElement;