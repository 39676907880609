import React, { useEffect } from "react";

import { Checkbox, Box, IconButton, FormControlLabel, FormGroup } from "@mui/material";

import { Tooltip } from "@material-ui/core";

// icons
import MoveUp from "@mui/icons-material/MoveUp";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";

function RedesignBodyHeaderBottom({ user, isChecked, onCheckedContent, onUncheckedContent, onGetCheckedContent, isCheckedAll, onMoveCheckedContent }) {
  const { t } = useTranslation();

  useEffect(() => {
    const openBtn = document.querySelector("#openBtn");
    const closeBtn = document.querySelector("#closeBtn");
    const pagg = document.querySelector("#pagination-box");
    const btnGrp = document.querySelector("#btn-group");
    openBtn?.addEventListener("click", function (e) {
      btnGrp.classList.remove("hidden-rm");
      pagg.classList.remove("show-rm");
      pagg?.classList.add("hidden-rm");
      btnGrp.classList.add("show-rm");
    });
    closeBtn?.addEventListener("click", function (e) {
      pagg.classList.remove("hidden-rm");
      btnGrp.classList.remove("show-rm");
      pagg?.classList.add("show-rm");
      btnGrp.classList.add("hidden-rm");
    });
  }, []);

  const onChangeScreenBox = (e) => {
    if (!isCheckedAll) {
      onCheckedContent();
    } else {
      onUncheckedContent();
    }
  };

  return (
    <>
      <Box
        sx={{
          p: 2,
          height: "80%",
          width: "100%",
          display: "flex",
        }}>
        {/* left side */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "5px",
            height: "50px",
          }}>
          <FormGroup>
            <FormControlLabel control={<Checkbox onChange={onChangeScreenBox} checked={isCheckedAll} />} label={t("components.redesignBodyHeaderBottom.selectAll")} />
          </FormGroup>
        </Box>
        {/* right side */}
        {isChecked && (
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "5px",
            }}>
            {user.isMember ? (
              user.previlliages.content.canDelete ? (
                <Tooltip title={t("components.redesignBodyHeaderBottom.delete")}>
                  <IconButton aria-label="delete" onClick={() => onGetCheckedContent()}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <></>
              )
            ) : (
              <Tooltip title={t("components.redesignBodyHeaderBottom.delete")}>
                <IconButton aria-label="delete" onClick={() => onGetCheckedContent()}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
            {/* TODO: Uncomment after adding function's
              <Tooltip title={t('components.redesignBodyHeaderBottom.addToPlaylist')}>
                <IconButton aria-label="add-to-playlist">
                  <PlaylistAddIcon />
                </IconButton>
              </Tooltip> */}
            <Tooltip title={t("components.redesignBodyHeaderBottom.moveToFolder")}>
              <IconButton aria-label="move-to-folder" onClick={() => onMoveCheckedContent()}>
                <MoveUp />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
    </>
  );
}

export default RedesignBodyHeaderBottom;
