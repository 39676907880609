import { useState, useCallback, useEffect, useRef } from 'react';
import useDrivePicker from 'react-google-drive-picker';

const DEFAULT_SCOPES = ['https://www.googleapis.com/auth/drive.file'];

/*
    Overriding default 'react-google-drive-picker', because the current version (v1.2.2) does not support default scope overwriting.
    According to Google's recommendation scope 'drive.readonly' should be replaced with 'drive.files'
    The following code allows us to replace scope with Google's recommendation.
    The solution is based on custom token config options and provides back-forward compatibility with the plugin.
*/
const useGoogleDrivePicker = () => {

    const [auth, setAuth] = useState(null);
    const openPickerConfigRef = useRef(null);

    const [openPicker] = useDrivePicker();

    //Custom default scope auth request
    const requestAuthToken = useCallback(async (config) => new Promise((resolve) => {
        const { google } = window;

        const client = google.accounts.oauth2.initTokenClient({
            client_id: config.clientId,
            scope: [...DEFAULT_SCOPES, ...(config?.customScopes ?? [])].join(' '),
            callback: (tokenResponse) => {
                resolve(tokenResponse);
            },
        })

        client.requestAccessToken();
    }), []);

    //Open picker with custom authToken
    const openPickerWithInjectedScope = useCallback((config) => {
        openPickerConfigRef.current = config;

        if (!auth && !config.token) {
            requestAuthToken(config).then(authRes => {
                setAuth(authRes);
            });
            return;
        }

        openPicker({
            token: auth?.access_token,
            ...config,
        });
    }, [auth, setAuth, requestAuthToken, openPicker]);

    //Auto open picker on auth pass
    useEffect(() => {
        if (!auth || !openPickerConfigRef.current)
            return;

        openPickerWithInjectedScope(openPickerConfigRef.current);
    }, [auth]);

    return [
        openPickerWithInjectedScope,
        auth
    ];

};

export default useGoogleDrivePicker;