import { io } from 'socket.io-client';

let baseURL = process.env.REACT_APP_BACKEND_URL;

export const socketInit = () => {
    const options = {
        'force new connection': true,
        reconnectionAttempt: 'Infinity',
        timeout: 10000,
        transport: ['websocket']
    }

    return io(baseURL, options);
}
