import { combineReducers } from "redux";

import { LOGOUT } from './redux/constant';

import { ApkReducer } from "./redux/reducers/ApkReducer";
import { ContentReducer } from "./redux/reducers/ContentReducer";
import { EmailTempReducer } from "./redux/reducers/EmailTempReducer";
import { FileFolderReducer } from "./redux/reducers/FileFolderReducer";
import { GroupReducer } from "./redux/reducers/GroupReducer";
import { PlaylistReducer } from "./redux/reducers/PlaylistReducer";
import { ScreenReducer } from "./redux/reducers/ScreenReducer";
import { UserReducer } from "./redux/reducers/UserReducer";
import { PlanReducer } from "./redux/reducers/PlanReducer";
import { WidgetsGridsReducer } from "./redux/reducers/WidgetsGridsReducer";
import { LogReducer } from "./redux/reducers/LogReducer";
import { UserNoteReducer } from "./redux/reducers/UserNoteReducer";

const appReducer = combineReducers({
    User: UserReducer,
    Playlist: PlaylistReducer,
    Content: ContentReducer,
    Log: LogReducer,
    Screen: ScreenReducer,
    Apk: ApkReducer,
    FileFolder: FileFolderReducer,
    Group: GroupReducer,
    EmailTemp: EmailTempReducer,
    Plan: PlanReducer,
    WidgetsGrids: WidgetsGridsReducer,
    UserNote: UserNoteReducer,
});

export const rootReducer = (state, action) => {
    if (action.type == 'LOGOUT') {
        window.localStorage.removeItem('isLogin');
        return appReducer(undefined, action);
    }

    return appReducer(state, action);
}