import React from "react";
import { useTranslation } from "react-i18next";
import { Card, Typography, Stack, Button, IconButton } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { Tooltip } from "@material-ui/core";

const RedesignCard = ({ widget, onClickWidget, disabled = false }) => {
  const { t } = useTranslation();

  const capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const isMobile = useMediaQuery({ query: "(max-width: 786px)" });

  if (disabled) {
    if (isMobile) {
      return (
        <Tooltip title={t("views.redesignCard.applicationNotAvailable")}>
          <Button color="primary" disabled={disabled} variant="contained">
            {capitalizeFirstLetter(widget?.name)}
          </Button>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={t("views.redesignCard.applicationNotAvailable")}>
          <Card>
            <Stack spacing={1} sx={{ p: 3, width: "150px", cursor: "pointer" }}>
              <Stack alignItems="center" direction="row">
                <img
                  src={widget?.img}
                  alt="Icon"
                  style={{
                    width: "100%",
                    height: "70px",
                    objectFit: "contain",
                    filter: disabled ? "grayscale(1)" : "grayscale(0)",
                  }}
                />
              </Stack>
              <Typography color="text.secondary" variant="body2" sx={{ textAlign: "center" }}>
                {capitalizeFirstLetter(widget?.name)}
              </Typography>
            </Stack>
          </Card>
        </Tooltip>
      );
    }
  } else {
    if (isMobile) {
      return (
        <Button
          color="primary"
          disabled={disabled}
          onClick={() => onClickWidget({ slug: widget?.slug, icon: widget?.img })}
          variant="contained"
          startIcon={
            <img
              src={widget?.img}
              alt="Icon"
              style={{
                height: "20px",
                objectFit: "contain",
                filter: disabled ? "grayscale(1)" : "grayscale(0)",
              }}
            />
          }>
          {capitalizeFirstLetter(widget?.name)}
        </Button>
      );
    } else {
      return (
        <Card onClick={() => onClickWidget({ slug: widget?.slug, icon: widget?.img })}>
          <Stack spacing={1} sx={{ p: 3, width: "150px", cursor: "pointer" }}>
            <Stack alignItems="center" direction="row">
              <img
                src={widget?.img}
                alt="Icon"
                style={{
                  width: "100%",
                  height: "70px",
                  objectFit: "contain",
                  filter: disabled ? "grayscale(1)" : "grayscale(0)",
                }}
              />
            </Stack>
            <Typography color="text.secondary" variant="body2" sx={{ textAlign: "center" }}>
              {capitalizeFirstLetter(widget?.name)}
            </Typography>
          </Stack>
        </Card>
      );
    }
  }
};

export default RedesignCard;
