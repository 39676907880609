import React from "react";
import { Box, Dialog, DialogTitle, DialogContent, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

function ConfirmationModel({ openCreateModel, title, onClose, onConfirm, Icon }) {
  const { t } = useTranslation();

  return (
    <>
      <Dialog
        open={openCreateModel}
        onClose={() => onClose(false)}
        maxWidth={"lg"}
        sx={{
          zIndex: "100000000000",
        }}>
        <DialogTitle sx={{ textAlign: "center" }}>{title}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: "30vw",
              height: "30vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}>
            <Box style={{ width: "100px", height: "100px", margin: "0 auto" }}>
              <Icon
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "40px 0 0 0",
                gap: "10px 10px",
              }}>
              <Button
                variant={"outlined"}
                sx={{
                  height: "38px",
                  width: "193px",
                  textTransform: "capitalize",
                  borderRadius: "5px",
                }}
                onClick={() => onClose(false)}>
                {t("components.confirmationModel.no")}
              </Button>
              <Button
                variant={"contained"}
                onClick={() => onConfirm()}
                sx={{
                  height: "38px",
                  width: "193px",
                  textTransform: "capitalize",
                  borderRadius: "5px",
                }}>
                {t("components.confirmationModel.yes")}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ConfirmationModel;
