import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import DialogBoxSettings from './DialogBoxSettings';
import PreviewPlayer from '../PreviewScreen/PreviewPlayer';

import { Stage, Layer, Rect, Transformer } from 'react-konva';
import {getRandomColor} from './utlis';
import { ContentType,FitScreen } from './types';
import { MdFlipToFront } from "react-icons/md";
import { MdFlipToBack } from "react-icons/md";
import { IoAddOutline } from "react-icons/io5";
import { FaRegTrashAlt } from "react-icons/fa";
import { IoSettingsOutline } from "react-icons/io5";
import { CiMonitor } from "react-icons/ci";
import { LuRefreshCw } from "react-icons/lu";
import { LuRefreshCcw } from "react-icons/lu";

import Tooltip from "@mui/material/Tooltip";
import { Button } from '@mui/material';

const ZoneCanvasControl = ({screenInfo, setScreenInfo, settingsInfo, setSettingsInfo, selectedId, setSelectedId, zoneDetails, setZonesDetails, zonesHistory, setZonesHistory, historyPointer, setHistoryPointer, stageSize}) => {
  const { t } = useTranslation();

  // ------------- USE STATES -------------
  const [openSettingsDialog, setOpenSettingsDialog] = useState(false);
  //---
  const [openPreview, setOpenPreview] = useState(false);
  const [player, setPlayer] = useState([]);

  // ------------- USE REFS -------------
  const shapeRefs = useRef({});
  const trRefs = useRef({});
  const intervalIDs = useRef([]);
  // ------------- FUNCTIONS -------------
  const addRectangle = () => {
    if (zoneDetails.length < 4) {
      const newRectangles = [...zoneDetails];
      newRectangles.push({
        x: 0,
        y: 0,
        width: 100,
        height: 100,
        fill: getRandomColor(),
        fit_screen: FitScreen.FIT,
        opacity: 0.7,
        id: `rect${generateUUID()}`,
        type:ContentType.FIT,
        scaleX: 1,
        scaleY: 1,
      });
      setZonesDetails(newRectangles);
    }

  };

  const handleDragEnd = (e) => {
    const id = e.target.id();
    const updatedRects = zoneDetails.map(rect => {
      if (rect.id === id) {
        return { ...rect, x: e.target.x(), y: e.target.y() };
      }
      return rect;
    });
    setZonesDetails(updatedRects);
    if (historyPointer == 0){
      setZonesHistory(prev => [...prev, updatedRects]);
    }
    else{
      setZonesHistory(prev => [...prev.slice(0,zonesHistory.length + historyPointer-1), updatedRects]);
      setHistoryPointer(0);
    }
  };

  const onChange = (newAttrs, id) => {
    const updatedRects = zoneDetails.map(rect => {
      if (rect.id === id) {
        return newAttrs;
      }
      return rect;
    });
    setZonesDetails(updatedRects);
    if (historyPointer == 0){
      setZonesHistory(prev => [...prev, updatedRects]);
    }
    else{
      setZonesHistory(prev => [...prev.slice(0,zonesHistory.length + historyPointer-1), updatedRects]);
      setHistoryPointer(0);
    }
  };

  const bringToFront = () => {
    if (selectedId) {
      const index = zoneDetails.findIndex(rect => rect.id === selectedId);
      if (index > -1) {
        const updatedRects = [...zoneDetails];
        const [rect] = updatedRects.splice(index, 1);
        updatedRects.push(rect);
        setZonesDetails(updatedRects);
      }
    }
  };

  const sendToBack = () => {
    if (selectedId) {
      const index = zoneDetails.findIndex(rect => rect.id === selectedId);
      if (index > -1) {
        const updatedRects = [...zoneDetails];
        const [rect] = updatedRects.splice(index, 1);
        updatedRects.unshift(rect);
        setZonesDetails(updatedRects);
      }
    }
  };

  const deleteFocusedZone = () => {
    if (selectedId) {
      setZonesDetails(prevZones => {
        return prevZones.filter(rect => rect.id !== selectedId);
      });
      setSelectedId(null);
    }
  };

  const handleSelect = (e) => {
    const id = e.target.id();
    setSelectedId(id);
  };


  const moveHistoryPointer = (direction) => {
    // console.log("moveHistoryPointer ", direction, zonesHistory.length, historyPointer);
    if (zonesHistory.length > 1  ) {
      if (direction === "undo" && historyPointer > zonesHistory.length * -1 + 1) {
        setHistoryPointer(prev => prev - 1);
      } 
      else if (direction === "redo" && historyPointer < 0) {
        setHistoryPointer(prev => prev + 1);
      }
    }
}


  function generateUUID() {
    // Generate an array of 16 random bytes
    const bytes = new Uint8Array(16);
    window.crypto.getRandomValues(bytes);

    // Set the version to 4 (UUID version 4)
    bytes[6] = (bytes[6] & 0x0f) | 0x40;
    // Set the variant to 1 (RFC 4122 variant)
    bytes[8] = (bytes[8] & 0x3f) | 0x80;

    // Convert bytes to a UUID string
    const hexBytes = Array.from(bytes).map(b => b.toString(16).padStart(2, '0'));
    return [
        hexBytes.slice(0, 4).join(''),
        hexBytes.slice(4, 6).join(''),
        hexBytes.slice(6, 8).join(''),
        hexBytes.slice(8, 10).join(''),
        hexBytes.slice(10).join('')
    ].join('-');
}


  const isAnyChosenZoneMedia = ()=>{
    let isAnyChosen = false;
    zoneDetails?.forEach((player,cnt) => {
      if (player.type && (player.content_name || player.object)){
        isAnyChosen = true;
        return;
      }
    });
    return isAnyChosen
  }
  // ---- PREVIEW  FUNCTIONS ----
  const handlePlaylistPreview = (stop) => {
    if (stop) {
      return closePlayer();
    }

    let playerContentList = [];
    if (zoneDetails === undefined || zoneDetails.length === 0) {
      playerContentList = [];
    } else {
      setOpenPreview(true);
      if (zoneDetails !== undefined || zoneDetails.length > 0) {
        let i = 0;
        zoneDetails?.forEach((player,cnt) => {
          if (player.type && (player.content_name || player.object)) {

            if (player.type === "Playlist"){
              //Has to fo through playlist
              var playlistContent = [];
              player.object.content.forEach((content) => {
                playlistContent.push({
                  name: content.content.name,
                  url: content.content.url,
                  duration: content.duration,
                  type: content.content.type,
                  widget: content.content.widget
                });
              })

              playerContentList.push({
                queue:cnt,
                idx:i,
                type:"Playlist",
                x: player.x,
                y: player.y,
                width: player.width,
                height: player.height,
                playlistContent: playlistContent,
              });
              i++;
            }
            else{
              playerContentList.push({
                queue:cnt,
                name: player.object.name,
                url: player.object.url,
                type: player.object.type,
                widget: player.object.widget,
                x: player.x,
                y: player.y,
                width: player.width,
                height: player.height,
                fit_screen: player.fit_screen,
              });
            }
          
          }
        });
      }

      const startingPlayer = playerContentList.map((content) => {
        if (content.type !== "Playlist"){
          return content;
        }
        return {...content,"playlistContent":content.playlistContent[0]};
      })

      const playlists = playerContentList.filter((content) => content.type === "Playlist");

      console.log("ZONEEEEE", zoneDetails);
      //Check if there is no playlist to play, if there is no playlist, then it does not have to be maintained, so set the player to the starting player
      if (playlists.length === 0) {
        setPlayer(startingPlayer);
        return;
      }

      let counters_ = new Array(playlists.length).fill(0);
      let newPlaylist = [];

      const nextPlay = (playlists) => {
        console.log("playlists: ", playlists);

        for (const playlist of playlists) {
          const playlistContent = playlist.playlistContent;
          if (counters_[playlist.idx] < playlistContent.length && playlistContent.length > 0) {
            const contentPlay = playlistContent[counters_[playlist.idx]];
            const temp_counters = [...counters_];
            // console.log("temp_counters: ", temp_counters);

            setPlayer((prev) => {
              if(prev.length === 0){
                console.log("initialize player: ", startingPlayer);
                newPlaylist = startingPlayer;
                return startingPlayer
              }

              // console.log("NEW PLAYLIST: \n", newPlaylist);// "\n\nPlayerContentList: \n", playerContentList, "\n\n", playlist.idx

              newPlaylist = newPlaylist.map((content) => {
                if (content.idx == playlist.idx) {
                  const newContent = playerContentList.find((new_content) => new_content.idx == playlist.idx);

                  return {...content,"playlistContent":newContent.playlistContent[temp_counters[newContent.idx]]};
                }
                return content;
              });

              return newPlaylist
            });

            counters_[playlist.idx]++;
            // setIntervalID(setTimeout(() => nextPlay([playlist]), contentPlay.duration));
            console.log("WHEN PLAYLIST IS ",playlist.idx, "DURATION: ", contentPlay.duration);
            intervalIDs.current[playlist.idx] = setTimeout(() => nextPlay([playlist]), contentPlay.duration? contentPlay.duration : 10000);
          } 
          else {
            if (playlistContent.length > 0) {
              counters_[playlist.idx] = 0;
              setPlayer((prev) => {
                return prev.map((content) => {
                  if (content.type !== "Playlist") {
                    return content;
                  }
                  const newContent = playerContentList.find((new_content) => new_content.idx == playlist.idx);
                  if (newContent) {
                    return newContent.playlistContent[counters_[newContent.idx]];
                  }
                  return content;
                });
              });
              nextPlay([playlist]);
            }
          }
        }
      };
      nextPlay(playlists);
    }
  };

  const closePlayer = () => {
    setOpenPreview(false);
    Object.keys(intervalIDs.current).forEach(key => {
      clearTimeout(intervalIDs.current[key]);
    });
    intervalIDs.current = [];
    setPlayer([]);
  };


  // ------------- USE EFFECTS -------------
  useEffect(()=>{
    if (openPreview){
      console.log("Inspecting zoneDetails while previewing");
      Object.keys(intervalIDs.current).forEach(key => {
        clearTimeout(intervalIDs.current[key]);
      });
      intervalIDs.current = [];
      setPlayer([]);
      handlePlaylistPreview(false);        
    }
  },[zoneDetails])

  const windowContent = () =>{
    if (openPreview == false ){
      return (
        <Stage
        style={{ backgroundColor: "white", width:`${stageSize.width+3}px`, border:"2px solid black" }}
        width={stageSize.width}
        height={stageSize.height}
      >
        <Layer>
          {zoneDetails.map(rect => (
            <React.Fragment key={rect.id}>
              <Rect
                {...rect}
                ref={node => (shapeRefs.current[rect.id] = node)}
                draggable
                onDragEnd={handleDragEnd}
                onClick={handleSelect}
                stroke={selectedId === rect.id ? 'blue' : 'black'}
                strokeWidth={0}
                onMouseEnter={e => { e.target.getStage().container().style.cursor = 'move'; }}
                onMouseLeave={e => { e.target.getStage().container().style.cursor = 'default'; }}
                dragBoundFunc={(pos) => {
                  setSelectedId(rect.id);

                  // Prevent dragging outside the stage
                  const newX = Math.max(0, Math.min(stageSize.width - rect.width * rect.scaleX, pos.x));
                  const newY = Math.max(0, Math.min(stageSize.height - rect.height * rect.scaleY, pos.y));
                  if (newX < 0){
                    newX = 0;
                  }
                  if (newY < 0){
                    newY = 0;
                  }
                  return {
                    x: newX,
                    y: newY
                  };
                }}
                onTransformEnd={(e) => {
                  const node = shapeRefs.current[rect.id];
                  const scaleX = node.scaleX();
                  const scaleY = node.scaleY();

                  node.scaleX(1);
                  node.scaleY(1);

                  // Calculate new width and height
                  const newWidth = Math.max(5, node.width() * scaleX);
                  const newHeight = Math.max(5, node.height() * scaleY);

                  // Calculate new position
                  const newX = Math.max(0, Math.min(stageSize.width - newWidth, node.x()));
                  const newY = Math.max(0, Math.min(stageSize.height - newHeight, node.y()));

                  onChange({
                    ...rect,
                    x: newX,
                    y: newY,
                    width: newWidth,
                    height: newHeight,
                  }, rect.id);
                }}
              />
              {selectedId === rect.id && (
                <Transformer
                  ref={node => (trRefs.current[rect.id] = node)}
                  resizeEnabled={true}
                  rotateEnabled={false}
                  boundBoxFunc={(oldBox, newBox) => {
                    // Prevent the rectangle from being resized too small
                    if (newBox.width < 5 || newBox.height < 5) {
                      return oldBox;
                    }
                    const isOut =
                    newBox.x < 0 ||
                    newBox.y < 0 ||
                    newBox.x + newBox.width > stageSize.width ||
                    newBox.y + newBox.height > stageSize.height;
                    if (isOut) {
                      if (newBox.x < 0) {
                        oldBox.x = 0;
                      }
                      if (newBox.y < 0) {
                        oldBox.y = 0;
                      }
                      return oldBox;
                    }
                    else{
                      return newBox;
                    }
                  }}
                />
              )}
            </React.Fragment>
          ))}
        </Layer>
      </Stage>
      )
    }  
    else{
      return (
         <PreviewPlayer mainWidth={stageSize.width} mainHeight={stageSize.height} open={openPreview} onClose={()=>setOpenPreview} player={player}/>

      )
    }
  }


  useEffect(() => {
    if (selectedId) {
      const shape = shapeRefs.current[selectedId];
      const tr = trRefs.current[selectedId];
      if (shape && tr) {
        tr.nodes([shape]);
        tr.getLayer().batchDraw();
      }
    }
  }, [selectedId]);

  return (
    <div style={{width:"100%", height:"90%", overflowY:"auto", borderRadius:"10px", display:"flex",flexDirection:"column", alignItems:"center", backgroundColor: "#f0f0f0", paddingBottom:"30px"}}>
      <div style={{ display: "flex", flexDirection: "row", justifyContent:"space-between" , width:"100%", borderBottom: "1px solid #333333", marginBottom:"10px",  alignItems:"center", gap: "10px", marginRight:"auto", padding:"10px" }}>
        {!openPreview && <div style={{ display: "flex", width:"100%", flexDirection: "row", alignItems:"center", gap: "15px", marginRight:"auto",}}>
          <Tooltip title={t("zoneCanvas.add")}>
            <span>
              <IoAddOutline size="24px" style={{cursor:"pointer", color:"black"}} onClick={addRectangle} />
            </span>
          </Tooltip> 
          <Tooltip title={t("zoneCanvas.undo")}>
            <span>
              <LuRefreshCcw size="18px" style={{cursor:"pointer", color:"black"}} onClick={()=>moveHistoryPointer("undo")} />
            </span>
          </Tooltip>
          <Tooltip title={t("zoneCanvas.redo")}>
            <span>
              <LuRefreshCw size="18px" style={{cursor:"pointer", color:"black",marginRight:"15px"}} onClick={()=>moveHistoryPointer("redo")} />
            </span>
          </Tooltip>
          {selectedId && <Tooltip title={t("zoneCanvas.bring_forward")}>
            <span>
              <MdFlipToFront size="20px" style={{cursor:"pointer", color:"black"}} onClick={bringToFront} disabled={!selectedId}/>
            </span>
          </Tooltip>}
          {selectedId && <Tooltip title={t("zoneCanvas.send_backward")}>
            <span>
            <MdFlipToBack size="20px" style={{cursor:"pointer", color:"black"}} onClick={sendToBack} disabled={!selectedId}/>
            </span>
          </Tooltip>}
          {selectedId && <Tooltip title={t("zoneCanvas.delete")}>
            <span>
              <FaRegTrashAlt style={{cursor:"pointer", color:"black"}} onClick={() => deleteFocusedZone()}/>
            </span>
          </Tooltip>}
        </div>}
        <div style={{display:"flex", flexDirection:"row", gap:"10px", marginLeft:"auto"}}>
          <Button 
              variant="contained" 
              sx={{ 
                display: 'flex', 
                alignItems: 'center',
                padding:"0px",
                margin:"0px",
                justifyContent: 'center',
                backgroundColor: "#111927", 
                '&:hover': {
                  backgroundColor: "#2c3e50", 
                },
                transition: 'background-color 0.3s',
              }}
              onClick={()=>setOpenSettingsDialog(true)}
            >
              <IoSettingsOutline size="20px" />
          </Button>
          <Button 
            variant="contained" 
            // color="primary" 
            sx={{ 
              display: 'flex', 
              alignItems: 'center',
              justifyContent: 'center',
              width:"160px",
              backgroundColor: "#111927", 
              '&:hover': {
                backgroundColor: "#2c3e50", 
              },
              transition: 'background-color 0.3s',
            }}
            startIcon={<CiMonitor />} 
            onClick={() => console.log(zoneDetails)}
          >
            Push To Screen
          </Button>
          <Button variant="contained"  
          sx={{
            backgroundColor: "#111927", 
            '&:hover': {
              backgroundColor: "#2c3e50", 
            },
            '&.Mui-disabled': {
              backgroundColor: "#444b5a", 
              color: "#9e9e9e", 
              cursor: 'not-allowed',  
            },
            transition: 'background-color 0.3s',
          }} 
          disabled={!isAnyChosenZoneMedia()}
          onClick={() => handlePlaylistPreview(openPreview ? true : false)}>{openPreview ? "Edit" : "Preview"}</Button>
        </div>
      </div>

      {windowContent()}

      <DialogBoxSettings screenInfo={screenInfo} setScreenInfo={setScreenInfo} settingsInfo={settingsInfo} setSettingsInfo = {setSettingsInfo} isOpen={openSettingsDialog} onClose={setOpenSettingsDialog}/>
    </div>
  );
};

export default ZoneCanvasControl;

