import { useParams } from "react-router-dom";
import Layout from "../../../components/layout/Layout";
import { makeStyles } from "@material-ui/core";
import { Box, Button, Dialog, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { FaList, FaRegClock } from "react-icons/fa";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  addPlaylist,
  deleteContentInPlaylist,
  deletePlaylist,
  findOne,
  getPlaylistsById,
  updateDuration,
  editPlaylistName,
  addContentInPlaylist,
  playlistFlagOff,
  unCheckedTimeLebel,
  playlistContentSorting,
} from "../../../redux/actions/Playlist";
import PlaylistList from "../component/PlaylistList";
import PlaylistData from "../component/PlaylistData";
import { useMediaQuery } from "react-responsive";
import DragContent from "../Dragable/DragContent";
import toast from "react-hot-toast";
import AddPlaylistModel from "../../../components/addplaylist/AddPlaylistModel";
import UserProfileComponent from "../../../components/userprofile/UserProfileComponent";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => {
  return {
    contentList: {
      "&:hover::-webkit-scrollbar": {
        display: "block",
        transition: "display .5s",
      },
      "&::-webkit-scrollbar": {
        width: "5px",
        borderRadius: "5px",
        display: "none",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#fffff",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "blue",
      },
      "&::-wekit-scrollbar-corner": {
        borderRadius: "5px",
      },
    },
    headIconStyle: {
      color: "#92959E",
      fontFamily: "open sans",
      fontStyle: "normal",
      fontWeight: "600",
    },
    topHeading: {
      display: "flex",
      gap: "10px",
      margin: "0 7px",
    },
    headingIcon: {
      width: "56px",
      height: "58px",
      background: "rgba(1, 106, 254, 0.1)",
      borderRadius: "10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    btnGroups: {
      transform: "translateY(-10px)",
      display: "flex",
      justifyContent: "space-between",
      margin: "10px 0",
      marginBottom: 10,
    },
    layoutSidebarUpperBox: {
      height: "80vh",
      width: "25vw",
      borderRadius: "10px 10px 0 0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        width: "90vw",
      },
    },
    layoutSidebarUpperBoxInside: {
      height: "75vh",
      width: "23vw",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        width: "80vw",
      },
    },
    layoutContainer: {
      height: "80vh",
      width: "80vw",
      margin: "20px auto",
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        width: "91vw",
      },
    },
    layoutSidebarBottomBox: {
      height: "70vh",
      width: "25vw",
      borderRadius: "10px 10px 0 0",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
      [theme.breakpoints.down("sm")]: {
        width: "80vw",
      },
    },
    layoutSidebar: {
      minHeight: "80vh",
      width: "25vw",
      margin: "0 8px",
      background: "#FFFFFF",
      borderRadius: "10px",
      boxShadow: "1px 1px 5px 5px rgba(0,0,0,0.1)",
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        width: "90vw",
      },
    },
    layoutBody: {
      minHeight: "50vh",
      width: "55vw",
      padding: "30px 20px 0 20px",
      boxShadow: "1px 1px 5px 5px rgba(0,0,0,0.1)",
      margin: "0 8px",
      background: "#FFFFFF",
      borderRadius: "10px",
      [theme.breakpoints.down("sm")]: {
        width: "80vw",
        marginTop: "40px",
        minHeight: "80vh",
      },
    },
    layoutSidebarMidBanner: {
      position: "absolute",
      background: "linear-gradient(30.84deg, #016AFE 61.34%, rgba(1, 163, 254, 0.53) 100%)",
      borderRadius: "0px",
      transform: "matrix(1, 0, 0, -1, 0, 0)",
      height: "10vh",
      width: "100%",
      top: "40vh",
    },
    layoutSidebarBottomBoxInside: {
      height: "60vh",
      width: "23vw",
      [theme.breakpoints.down("sm")]: {
        width: "80vw",
        transform: "translateX(30px)",
      },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    layoutSidebarBottomBox: {
      height: "40vh",
      width: "25vw",
      [theme.breakpoints.down("sm")]: {
        width: "80vw",
      },
      borderRadius: "10px 10px 0 0",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
    },

    layoutSidebarBottomBoxInside: {
      height: "60vh",
      width: "23vw",
      [theme.breakpoints.down("sm")]: {
        width: "90vw",
      },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
});
function UserPlaylist({ socketRef, user }) {
  const params = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { playlists, playlist, isOnePlaylist, isPlaylistLoaded, isContentAdded, isPlaylistContentSorted, screens, content, playlistLength, userInfo, isPlaylistAdded } =
    useSelector(
      (state) => ({
        playlists: state.Playlist.playlists.filter((p) => p?.subuser?._id == params.id),
        playlist: state.Playlist.playlist,
        isOnePlaylist: state.Playlist.isOnePlaylist,
        isPlaylistLoaded: state.Playlist.isPlaylistLoaded,
        isContentAdded: state.Playlist.isContentAdded,
        isPlaylistContentSorted: state.Playlist.isPlaylistContentSorted,
        screens: state.Screen.screens.filter((s) => s?.subuser?._id == params.id),
        content: state.Content.content.filter((c) => c?.subuser?._id == params.id),
        userInfo: state.User.users.filter((u) => u._id == params.id),
        playlistLength: state.Playlist.playlists.filter((p) => p?.subuser?._id == params.id).length,
        isPlaylistAdded: state.Playlist.isPlaylistAdded,
      }),
      shallowEqual
    );
  const [search, setSearch] = useState("");
  const [opneCreatePlaylist, setOpenCreatePlaylist] = useState(false);
  const [openFolder, setOpenFolder] = useState(false);
  const [playlistName, setPlaylistName] = useState("");
  const [foldername, setFolderName] = useState("");
  const [playlistControlOption, setPlaylistControlOption] = useState(false);
  const [openTimeModel, setOpenTimeModel] = useState(false);
  const [openEditPlaylistName, setOpenEditPlaylistName] = useState(false);
  const [time, setTime] = useState(0);
  const [contentId, setContentId] = useState("");
  const [anyId, setAnyId] = useState("");
  const [playlistId, setPlaylistId] = useState("");
  const [ids, setIds] = useState([]);
  const [openPlaylist, setOpenPlaylist] = useState(false);
  const playlistIdRef = useRef();
  const [isOver, setIsOver] = useState(false);
  const [overContent, setOverContent] = useState(false);
  const [contentObjectValue, setContentObjectValue] = useState({});
  const [lastContentId, setLastContentId] = useState("");
  const [isMainContent, setIsMainContent] = useState(false);
  const [one, setOne] = useState({});
  useEffect(() => {
    if (params) {
      dispatch(findOne(playlists[0]?._id));
      dispatch(playlistFlagOff());
    }
  }, [params.id]);

  useEffect(() => {
    dispatch(getPlaylistsById(params.id));
  }, [params.id]);
  useEffect(() => {
    if (isPlaylistAdded) {
      toast.success(t("views.userPlaylist.playlistCreatedSuccess"));
      dispatch(getPlaylistsById(params.id));
      dispatch(playlistFlagOff());
    }
    if (isPlaylistLoaded) {
      dispatch(findOne(playlists[0]?._id));
      dispatch(playlistFlagOff());
    }
    if (isContentAdded) {
      toast.success(t("views.userPlaylist.contentAddedSuccess"));
      emitOnAddedContentInPlaylist();
      dispatch(playlistFlagOff());
    }
    if (isPlaylistContentSorted) {
      toast.success(t("views.userPlaylist.contentSortedSuccess"));
      emitOnAddedContentInPlaylist();
      dispatch(playlistFlagOff());
    }
  }, [isPlaylistLoaded, isContentAdded, isPlaylistContentSorted, isPlaylistAdded, params.id]);
  useEffect(() => {
    if (isOnePlaylist) {
      setPlaylistId(playlist?._id);
      setOne({ ...playlist });
      dispatch(playlistFlagOff());
    }
  }, [isOnePlaylist]);
  const emitOnAddedContentInPlaylist = () => {
    for (const p in playlist?.screen_mac) {
      socketRef?.current.emit("content_added", { detail: { mac: playlist.screen_mac[p].mac } });
    }
  };
  const deleteContentFromPlaylist = (obj) => {
    dispatch(deleteContentInPlaylist(obj));
    const s = screens.find((c) => c?.playlist?._id === obj.playlistId);
    if (s !== undefined || s !== null) {
      emitOnDeleteContentFromPlaylist(s?.mac);
    }
  };
  const emitOnDeleteContentFromPlaylist = (mac) => {
    for (const p in playlist.screen_mac) {
      socketRef?.current.emit("content_deleted", { detail: { mac: playlist.screen_mac[p].mac } });
    }
  };
  const savePlaylist = () => {
    const isSameNamePlaylist = playlists.filter((p) => p.playlistName === playlistName);
    if (playlistName === "" || playlistName === undefined) {
      return;
    }
    if (isSameNamePlaylist.length > 0) {
      toast.error(t("views.userPlaylist.sameNameError"));
    } else {
      dispatch(
        addPlaylist({
          playlistName,
          user: params.id,
          subuser: params.id,
          role: "user",
        })
      );

      setOpenPlaylist(false);
      setPlaylistName("");
    }
  };
  const timeDuration = (id, times) => {
    setTime(times / 1000);
    setContentId(id);
    setOpenTimeModel(true);
  };
  const confirmDurationChange = () => {
    dispatch(
      updateDuration({
        time,
        playlistId: playlistIdRef.current.value,
        contentId,
      })
    );
    const s = screens.find((c) => c?.playlist?._id === playlistIdRef.current.value);
    if (s !== undefined || s !== null) {
      emitOnTimeDurationChangedInPlaylist(s?.mac);
    }
  };
  const emitOnTimeDurationChangedInPlaylist = (mac) => {
    for (const p in playlist.screen_mac) {
      socketRef?.current.emit("update_duration", { detail: { mac: playlist.screen_mac[p].mac } });
    }
  };
  const arrayOfSelectedPlaylist = (e) => {
    setIds((prev) => {
      if (e.target.checked) {
        return [...prev, e.target.value];
      } else {
        return [...prev.filter((p) => p !== e.target.value)];
      }
    });
  };
  const confirmDeleteplaylists = () => {
    dispatch(deletePlaylist(ids));
    emitOnDeletePlaylist(ids);
    setIds([]);
  };
  const emitOnDeletePlaylist = (ids) => {
    for (const p in playlist.screen_mac) {
      socketRef?.current.emit("playlist_deleted", {
        detail: { mac: playlist.screen_mac[p].mac },
      });
    }
  };
  const onEditPlaylistName = (id) => {
    setAnyId(id);
    setPlaylistName(playlist.playlistName);
    setOpenEditPlaylistName(true);
  };
  const editPlaylistNameSave = () => {
    dispatch(editPlaylistName(anyId, playlistName));
    setOpenEditPlaylistName(false);
  };
  const onSideClicksave = () => {
    if (time !== 0) {
      confirmDurationChange();
    }
  };
  const getTimeChangeInfo = (_content_id, times) => {
    setContentId(_content_id);
    setTime(times / 1000);
    dispatch(unCheckedTimeLebel(playlist._id, _content_id));
  };
  const keyChange = (e) => {
    if (e.key === "Enter" || e.key === "enter") {
      confirmDurationChange();
    }
    if (e.type === "mousedown") {
      confirmDurationChange();
    }
  };
  const onFocus = (e) => {};
  const onPlaylistAdd = () => {
    setOpenPlaylist(true);
  };
  const findOnePlaylistAndActive = (id) => {
    dispatch(findOne(id));
  };
  const mainDraggOver = (e) => {
    e.preventDefault();
    setIsOver(true);
    setIsMainContent(true);
  };
  const maindragstart = (obj, contentDragAdd) => {
    setIsMainContent(true);
    setContentObjectValue({ ...obj, contentDragAdd });
  };
  const onMainDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (contentObjectValue.contentDragAdd) {
      dispatch(
        addContentInPlaylist({
          id: playlistIdRef.current.value,
          value: contentObjectValue.id,
        })
      );
    }
    setContentObjectValue({});
    setIsOver(false);
    setIsMainContent(false);
  };
  const onMainLeave = (e) => {
    e.preventDefault();
    setIsOver(false);
  };
  const onContentStartDrag = (e, id) => {
    e.stopPropagation();
    setIsMainContent(false);
    e.dataTransfer.setData("first_id", id);
  };
  const onContentOverDrag = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    setLastContentId(id);
    setIsMainContent(false);
  };
  const onContentDropped = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsMainContent(false);
    const firstID = e.dataTransfer.getData("first_id");
    dispatch(playlistContentSorting(playlistIdRef.current.value, firstID, lastContentId));
  };
  const isMobile = useMediaQuery({ query: "(max-width: 786px)" });

  return (
    <>
      <Layout title={t("views.userPlaylist.title")} user={user} playlistAddBtn={true} onPlaylistAdd={onPlaylistAdd}>
        <input hidden type={"text"} value={playlist?._id} ref={playlistIdRef} />
        <UserProfileComponent user={userInfo} page={t("views.userPlaylist.pageTitle")} total={playlistLength} />
        <Box className={classes.layoutContainer}>
          <Box className={classes.layoutSidebar}>
            <Box className={classes.layoutSidebarUpperBox}>
              <Box className={classes.layoutSidebarUpperBoxInside}>
                <Box
                  sx={{
                    maxHeight: "100%",
                    height: "100%",
                    overflowY: "auto",
                    width: "95%",
                  }}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}>
                    <Typography
                      variant={"caption"}
                      sx={{
                        margin: "10px 0",
                        fontFamily: "open sans",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: "15px",
                        lineHeight: "20px",
                        color: "#92959E",
                        textTransform: "uppercase",
                      }}></Typography>
                    {ids.length > 0 && (
                      <>
                        <Box sx={{ display: "flex", gap: "5px" }}>
                          <img
                            src="/images/trash.png"
                            style={{
                              cursor: "pointer",
                              height: "20px",
                              width: "20px",
                            }}
                            onClick={confirmDeleteplaylists}
                          />
                        </Box>
                      </>
                    )}
                  </Box>
                  {playlists?.map((p) => (
                    <>
                      <PlaylistList user={user} key={p._id} data={p} onClick={(id) => findOnePlaylistAndActive(id)} onChange={(e) => arrayOfSelectedPlaylist(e)} />
                    </>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              height: "80vh",
              maxHeight: "80vh",
              width: isMobile ? "90vw" : "30vw",
              boxShadow: "1px 1px 5px 5px rgba(0,0,0,0.1)",
              backgroundColor: "white",
              borderRadius: "10px",
              margin: "0px 10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: isMobile ? "40px" : 0,
              marginBottom: isMobile ? "40px" : 0,
            }}
            onClick={onSideClicksave}
            onDragOver={(e) => mainDraggOver(e)}
            onDrop={(e) => onMainDrop(e)}
            onDragLeave={(e) => onMainLeave(e)}>
            <PlaylistData
              user={user}
              playlist={playlist}
              onDeleteContent={(id) =>
                deleteContentFromPlaylist({
                  playlistId: playlist._id,
                  contentId: id,
                })
              }
              isOver={isOver}
              onUpdateContentDuration={(id, time) => timeDuration(id, time)}
              onEditPlaylistName={onEditPlaylistName}
              onUnchecked={(_content_id, times) => getTimeChangeInfo(_content_id, times)}
              time={time}
              onSetTime={(e) => setTime(e.target.value)}
              keyChange={keyChange}
              onFocus={onFocus}
              onContentStartDrag={(e, id) => onContentStartDrag(e, id)}
              onContentOverDrag={(e, id) => onContentOverDrag(e, id)}
              onContentDropped={(e) => onContentDropped(e)}
              lastContentId={lastContentId}
            />
          </Box>

          <Box className={classes.layoutSidebar}>
            <Box className={classes.layoutSidebarUpperBox}>
              <Box className={classes.layoutSidebarUpperBoxInside}>
                <Box
                  sx={{
                    maxHeight: "100%",
                    height: "100%",
                    overflowY: "auto",
                    overFlowX: "hidden",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}>
                  {content?.map(
                    (con, index) =>
                      con.isActive && <DragContent user={user} key={index} contentObj={con} isMainContent={isMainContent} onGetObjectValue={maindragstart} />
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Layout>

      <AddPlaylistModel
        openCreatePlaylist={openPlaylist}
        value={playlistName}
        textValue={(e) => setPlaylistName(e)}
        onClose={(e) => setOpenPlaylist(e)}
        savePlaylist={savePlaylist}
      />

      <Dialog open={openEditPlaylistName} onClose={() => setOpenEditPlaylistName(false)} maxWidth={"lg"} sx={{ zIndex: "10000000000" }}>
        <DialogTitle sx={{ textAlign: "center" }}>{t("views.userPlaylist.editPlaylistNameTitle")}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: "30vw",
              height: "40vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}>
            <Box style={{ width: "100px", height: "100px", margin: "0 auto" }}>
              <FaList
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                  color: "#1977FA",
                }}
              />
            </Box>
            <Box sx={{ margin: "20px 0" }}>
              <TextField
                variant={"standard"}
                fullWidth
                type={"text"}
                label={t("views.userPlaylist.typeFolderNameLabel")}
                value={playlistName}
                onChange={(e) => setPlaylistName(e.target.value)}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "40px 0",
                gap: "10px 10px",
              }}>
              <Button
                variant={"outlined"}
                sx={{
                  height: "38px",
                  width: "193px",
                  textTransform: "capitalize",
                  borderRadius: "5px",
                }}
                onClick={() => setOpenEditPlaylistName(false)}>
                {t("views.userPlaylist.closeButton")}
              </Button>
              <Button
                variant={"contained"}
                sx={{
                  height: "38px",
                  width: "193px",
                  textTransform: "capitalize",
                  borderRadius: "5px",
                }}
                onClick={editPlaylistNameSave}>
                {t("views.userPlaylist.saveButton")}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={openTimeModel} onClose={() => setOpenTimeModel(false)} maxWidth={"lg"}>
        <DialogTitle sx={{ textAlign: "center" }}>{t("views.userPlaylist.updateTimeDurationTitle")}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: "30vw",
              height: "30vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}>
            <Box
              style={{
                width: { xl: "150px", lg: "100px" },
                height: { xl: "150px", lg: "100px" },
                margin: "0 auto",
              }}>
              <FaRegClock style={{ height: "100%", width: "100%", color: "#1977FA" }} />
            </Box>
            <Box sx={{ margin: "20px 0" }}>
              <TextField variant={"standard"} fullWidth type={"number"} value={time} onChange={(e) => setTime(e.target.value)} />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "40px 0",
                gap: "10px 10px",
              }}>
              <Button
                variant={"outlined"}
                sx={{
                  height: "38px",
                  width: "193px",
                  textTransform: "capitalize",
                  borderRadius: "5px",
                }}
                onClick={() => setOpenTimeModel(false)}>
                {t("views.userPlaylist.closeButton")}
              </Button>
              <Button
                variant={"contained"}
                sx={{
                  height: "38px",
                  width: "193px",
                  textTransform: "capitalize",
                  borderRadius: "5px",
                }}
                onClick={confirmDurationChange}>
                {t("views.userPlaylist.updateButton")}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={openFolder} onClose={() => setOpenFolder(false)} maxWidth={"lg"}>
        <DialogTitle sx={{ textAlign: "center" }}>{t("views.userPlaylist.createFolderTitle")}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: "50vw",
              height: "50vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}>
            <Box style={{ width: "183px", height: "154.41px", margin: "0 auto" }}>
              <img src="/images/addFolderIcon.png" />
            </Box>
            <Box sx={{ margin: "20px 0" }}>
              <TextField
                variant={"standard"}
                fullWidth
                type={"text"}
                label={t("views.userPlaylist.typeFolderNameLabel")}
                value={foldername}
                onChange={(e) => setFolderName(e.target.value)}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "40px 0",
                gap: "10px 10px",
              }}>
              <Button
                variant={"outlined"}
                sx={{
                  height: "38px",
                  width: "193px",
                  textTransform: "capitalize",
                  borderRadius: "5px",
                }}
                onClick={() => setOpenFolder(false)}>
                {t("views.userPlaylist.closeButton")}
              </Button>
              <Button
                variant={"contained"}
                sx={{
                  height: "38px",
                  width: "193px",
                  textTransform: "capitalize",
                  borderRadius: "5px",
                }}>
                {t("views.userPlaylist.saveButton")}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default UserPlaylist;
