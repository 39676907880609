import { Box, Button, Checkbox, FormControl, IconButton, Input, InputAdornment, InputLabel, TextField, Typography } from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login, loginFlagOff, loginWithFacebook, loginWithGoogle } from "../../redux/actions/User";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import Circularbar from "../../components/circularbar/Circularbar";
import { useTranslation } from "react-i18next";
import { baseApi } from "../../api/endpoints";
import { signInWithPopup } from "firebase/auth";
import { fbAuth, provider } from "../../firbaseConfig";

function Login() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { auth, loging, loggedin, logFail, logBlocked, logout, isVerified } = useSelector((state) => state.User);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
  });

  const [type, setType] = useState(true);

  useEffect(() => {
    if (loggedin) {
      toast.success(t("views.login.loginSuccess"));
      dispatch(loginFlagOff());
    }
    if (logFail) {
      toast.error(t("views.login.invalidCredentials"));
      dispatch(loginFlagOff());
    }
    if (logBlocked) {
      toast.error(t("views.login.userBlocked"));
      dispatch(loginFlagOff());
    }
    if (!isVerified) {
      toast.error(t("views.login.verificationRequired"));
      dispatch(loginFlagOff());
    }
  }, [logFail, logBlocked, loggedin, isVerified]);

  useEffect(() => {
    const isOk = Cookies.get("vcpCredential");
    if (isOk !== undefined) {
      const d = JSON.parse(Cookies.get("vcpCredential"));
      setValue("email", d?.email);
      setValue("password", d?.password);
      setRememberMe(true);
    } else {
      setValue("email", "");
      setValue("password", "");
      setRememberMe(false);
    }
  }, []);

  const onSubmit = (data) => {
    console.log(data);
    dispatch(login(data.email, data.password));
    reset({
      email: "",
      password: "",
    });
  };

  const onGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(fbAuth, provider);
      if (result) {
        dispatch(loginWithGoogle(result));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkedRememberMe = (e) => {
    const { checked } = e.target;
    if (checked) {
      setRememberMe(true);
      Cookies.set("vcpCredential", JSON.stringify({ email: email, password: password }), { expires: 7 });
    } else {
      Cookies.remove("vcpCredential");
      setRememberMe(false);
    }
  };

  if (auth) {
    return navigate("/");
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "100vw",
          position: "relative",
        }}>
        {loging && (
          <>
            <Box
              sx={{
                height: "100vh",
                width: "100vw",
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                zIndex: 10000,
                background: "rgba(0,0,0,0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Circularbar />
            </Box>
          </>
        )}
        <Box
          sx={{
            height: "111.45px",
            width: "158.47px",
            position: "absolute",
            top: "30px",
          }}>
          <img src="/images/VCPLogo.png" style={{ height: "100%", width: "100%", objectFit: "cover" }} alt="" />
        </Box>
        <Box
          sx={{
            minHeight: "100vh",
            width: "100%",
            background: "url(/images/loginside.png) ,rgba(255,255,255,.1)",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
          }}>
          <Box
            sx={{
              height: "80vh",
              width: {
                xs: "100%",
                sm: "100%",
                md: "30vw",
                lg: "30vw",
                xl: "30vw",
              },
              margin: "20px 0",
            }}>
            <Typography variant={"body1"} sx={{ margin: "30px auto", textAlign: "center" }}>
              <Typography variant={"caption"} sx={{ color: "#016AFE", fontWeight: "700", fontSize: "40px" }}>
                VCPlayer
              </Typography>
              <br />
              <Typography variant={"caption"} sx={{ color: "#313031", fontWeight: "700", fontSize: "40px" }}>
                SIGNAGE
              </Typography>
            </Typography>
            <Box
              sx={{
                height: "60vh",
                width: "90%",
                margin: "55px auto",
              }}>
              <Box
                sx={{
                  width: "100%",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px",
                }}>
                <Box
                  sx={{
                    width: "100%",
                    height: "40px",
                    // border:'1px solid green',
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "5px",
                  }}>
                  <Button
                    variant={"outlined"}
                    sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "40px", width: "40px" }}
                    onClick={onGoogleLogin}>
                    <img src="/images/googleIcon.png" style={{ height: "100%", width: "100%" }} />
                  </Button>
                </Box>
              </Box>

              <form onSubmit={handleSubmit(onSubmit)}>
                <Box
                  sx={{
                    height: "50px",
                    width: "90%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    margin: "0 auto",
                  }}>
                  <TextField
                    {...register("email", {
                      required: true,
                    })}
                    onChange={(e) => setEmail(e.target.value)}
                    variant={"standard"}
                    label={t("views.login.email")}
                    type={"email"}
                    sx={{ height: "70px", input: { color: "#313031" } }}
                    fullWidth
                  />
                  <Typography sx={{ color: "error.main" }}>{errors.email && t("views.login.emailRequired")}</Typography>
                </Box>
                <Box
                  sx={{
                    height: "50px",
                    width: "90%",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    margin: "10px auto",
                  }}>
                  <FormControl variant={"standard"} fullWidth>
                    <InputLabel htmlFor="password">{t("views.login.password")}</InputLabel>
                    <Input
                      id="password"
                      type={type ? "password" : "text"}
                      sx={{ background: "transparent", color: "#313031" }}
                      {...register("password", {
                        required: true,
                        minLength: 6,
                      })}
                      onChange={(e) => setPassword(e.target.value)}
                      endAdornment={
                        <InputAdornment position={"end"} style={{ marginTop: "-10px", color: "#551fff" }}>
                          <IconButton aria-label="toggle password visibility" onClick={() => setType(!type)}>
                            {type ? <VisibilityOffIcon style={{ color: "#551fff" }} /> : <VisibilityIcon style={{ color: "#551fff" }} />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <Typography sx={{ color: "error.main" }}>{errors.password?.type === "required" && t("views.login.passwordRequired")}</Typography>
                </Box>
                <Box
                  sx={{
                    height: "50px",
                    width: "90%",
                    margin: "10px auto",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <Checkbox onChange={checkedRememberMe} checked={rememberMe ? true : false} sx={{ marginTop: "-3px", marginLeft: "-10px" }} />
                    <Typography
                      variant={"caption"}
                      sx={{
                        color: "#313031",
                        fontWeight: "400",
                        fontSize: "18px",
                        lineHeight: "25px",
                      }}>
                      {t("views.login.rememberMe")}
                    </Typography>
                  </Box>
                  <Link to={"/forgetpassword"} style={{ textDecoration: "none", color: "#313031" }}>
                    {t("views.login.forgetPassword")}
                  </Link>
                </Box>
                <Box
                  sx={{
                    height: "50px",
                    width: "90%",
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px auto",
                  }}>
                  <Button
                    sx={{
                      textTransform: "capitalize",
                      height: {
                        xl: "54px",
                        lg: "50px",
                        md: "45px",
                        sm: "40px",
                        xs: "30px",
                      },
                      fontSize: "20px",
                      fontWeight: "700",
                      lineHeight: "38px",
                      backgroundColor: "#016AFE",
                      borderRadius: "6px",
                    }}
                    variant={"contained"}
                    type={"submit"}
                    fullWidth>
                    {t("views.login.login")}
                  </Button>
                </Box>
              </form>
              <Box
                sx={{
                  height: "50px",
                  width: "90%",
                  margin: "40px auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <Typography
                  variant={"caption"}
                  sx={{
                    color: "#313031",
                    fontWeight: 400,
                    fontSize: "18px",
                    linHeight: "25px",
                  }}>
                  {t("views.login.noAccount")}
                </Typography>
                &nbsp;
                <Link
                  to={"/signup"}
                  style={{
                    textDecoration: "none",
                    fontSize: {
                      xl: "18px",
                      lg: "18px",
                      md: "18px",
                      sm: "10px",
                      xs: "10px",
                    },
                    color: "#016AFE",
                  }}>
                  {t("views.login.createAccount")}
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            minHeight: "100vh",
            width: "100%",
            display: {
              sm: "none",
              xs: "none",
              md: "flex",
              lg: "flex",
              xl: "flex",
            },
            background: "url(/images/logincarouselside.png) ,linear-gradient(30.84deg, #016AFE 61.34%, rgba(1, 163, 254, 0.53) 100%);",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Box
            sx={{
              height: "90vh",
              width: "90%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Typography
                variant={"h4"}
                sx={{
                  color: "#F0F3FB",
                  fontWeight: "700",
                  fontSize: { xl: "40px", lg: "30px", md: "20" },
                  lineHeight: "102.6%",
                  textAlign: "center",
                  letterSpacing: "-0.02em",
                  textTransform: "uppercase",
                }}>
                {t("views.login.weMake")}
              </Typography>
              <Typography
                variant={"h2"}
                sx={{
                  color: "#F0F3FB",
                  fontWeight: "700",
                  fontSize: { xl: "60px", lg: "40px", md: "20" },
                  lineHeight: "102.6%",
                  textAlign: "center",
                  letterSpacing: "-0.02em",
                  textTransform: "uppercase",
                }}>
                {t("views.login.digitalSign")}
              </Typography>
              <Typography
                variant={"caption"}
                sx={{
                  color: "#F0F3FB",
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: { xl: "22px", lg: "15px", md: "10" },
                  lineHeight: "32px",
                  textAlign: "center",
                  letterSpacing: "-0.02em",
                  textTransform: "capatilize",
                  width: "500px",
                  margin: { xl: "25px 0", lg: "15px 0", md: "10px 0" },
                }}>
                {t("views.login.impressCustomers")}
              </Typography>
            </Box>

            <Box
              sx={{
                height: { xl: "300px", lg: "300px", md: "300px" },
                width: { xl: "350px", lg: "300px", md: "300px" },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <img src={"/images/iMacMonitor.png"} style={{ height: "100%", width: "100%" }} />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Typography
                variant={"caption"}
                sx={{
                  color: "#F0F3FB",
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: { xl: "15px", lg: "13px", md: "10px" },
                  lineHeight: "22px",
                  textAlign: "center",
                  letterSpacing: "-0.02em",
                  textTransform: "capatilize",
                  width: "500px",
                  margin: { xl: "25px 0", lg: "15px 0", md: "10px 0" },
                }}>
                {t("views.login.liveScrolling")}
              </Typography>
              <Box display={"flex"} alignItems={"center"} justifyContent={"center"} marginTop={"42px"}>
                <Box className="checkbox"></Box>
                <Box className="checkbox checkbox-muted checkbox-mid"></Box>
                <Box className="checkbox checkbox-muted"></Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Login;
