import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { deleteWidgetFromGrid } from "../../../redux/actions/WidgetsGridsAction";

import ConfirmDialog from "../../dialog/ConfirmDialog";

const WidgetDeleteModal = ({ modal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [state, dispatchModal] = modal;
  const { gridName, widget, action } = state;

  const handleAccept = useCallback(() => {
    dispatch(deleteWidgetFromGrid(gridName, widget));

    dispatchModal({ type: "END_ACTIONS" });
  }, [gridName, widget]);

  const handleClose = useCallback(() => {
    dispatchModal({ type: "END_ACTIONS" });
  }, []);

  return (
    <ConfirmDialog
      title={t("components.widgetDeleteModal.confirmDelete", { widgetName: widget.name })}
      open={widget && action == "DELETE"}
      onAccept={handleAccept}
      onClose={handleClose}
    />
  );
};

export default WidgetDeleteModal;
