import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";

import { useDispatch, useSelector } from "react-redux";

export const CustomerScreens = ({ user }) => {
  const { t } = useTranslation();
  const screensStatistics = useMemo(() => user?.screensStatistics, [user]);

  return (
    <>
      <Card>
        <CardHeader title={t("views.customerScreens.screensDetails")} />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ mt: 1 }}>
            <Typography color="text.secondary" variant="body">
              {t("views.customerScreens.totalScreens", { count: screensStatistics?.allScreensCount ?? 0 })}
            </Typography>
            <br />
            <Typography color="text.secondary" variant="body">
              {t("views.customerScreens.activeScreens", { count: screensStatistics?.activeScreensCount ?? 0 })}
            </Typography>
            <br />
            <Typography color="text.secondary" variant="body">
              {t("views.customerScreens.inactiveScreens", { count: screensStatistics?.nonActiveScreensCount ?? 0 })}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};
