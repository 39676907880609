import React from "react";
import { Box, Button } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function UserProfileComponent({ user, page, total }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 786px)" });

  return (
    <>
      {console.log(user)}
      <Box
        sx={{
          height: "30vh",
          width: isMobile ? "90vw" : "79vw",
          background: "white",
          margin: "0 auto",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Box
          sx={{
            height: "20%",
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}>
          <Button onClick={() => navigate("/users")} variant="contained" sx={{ margin: "0 10px" }}>
            {t("components.userProfileComponent.back")}
          </Button>
        </Box>
        <Box
          sx={{
            height: "80%",
            width: "100%",
            display: "flex",
          }}>
          <Box
            sx={{
              height: "100%",
              width: "30%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Box
              sx={{
                height: "20vh",
                width: "10vw",
                borderRadius: "50%",
              }}>
              <img
                src="https://cdn.pixabay.com/photo/2022/09/08/15/16/cute-7441224_640.jpg"
                style={{
                  height: "100%",
                  width: "100%",
                  borderRadius: "50%",
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              height: "100%",
              width: "70%",
              borderLeft: "1px dotted black",
              display: "flex",
              flexDirection: "column",
            }}>
            <Box
              sx={{
                margin: "0 10px",
              }}>
              <h3>{user[0].fullname}</h3>
              <p>
                {t("components.userProfileComponent.role")}: {user[0].role}
              </p>
              <p>
                {t("components.userProfileComponent.total")} {page}: <span>{total}</span>
              </p>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default UserProfileComponent;
