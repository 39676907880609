import * as Yup from 'yup';
import { CardContent, CardHeader } from '@mui/material';

//Widget content - required
export const WidgetContent = ({ widgetData, editMode }) => {

    const { html } = widgetData.settings;

    return (
        <CardContent dangerouslySetInnerHTML={{ __html: html }}/>
    )

};

//Widget header - not required
export const WidgetHeader = ({ widgetData, actions, editMode }) => {

    const { title } = widgetData.settings;

    //Header for admin
    if (editMode) {
        return <CardHeader title={(
            <>
                {title && `${title} - `} ({widgetData.name})
            </>
        )} action={actions} />;
    }

    //Header for user
    return title && (
        <CardHeader title={title} action={actions} />
    );

};

//Widget settings
export const type = "HTML";
export const displayName = "HTML widget";
export const configOptions = {
    title: {
        type: 'text',
        label: 'Title',
        validator: Yup.string()
    },
    html: {
        type: 'textarea',
        label: 'HTML*',
        placeholder: 'Enter HTML code here...',
        validator: Yup.string().required('HTML code is required')
    },
    padding: {
        
    }
    /*
    select: {
        type: 'select',
        label: 'Label',
        placeholder 'Placeholder',
        validator....
        options: [
            { value: 1, name: "one" },
            { value: 2, name: "two", default },
        ]
    }
    */
};
/*
TODO: Add three dots for custom actions
export const userActions = [
    
 ];
*/