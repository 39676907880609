import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

export const OrderEdit = (props) => {
  const { onCancel, onSave, order } = props;
  const [nameValue, setNameValue] = useState(order.playlistName);
  const { t } = useTranslation();

  useEffect(() => {
    setNameValue(order.playlistName);
  }, [order]);

  return (
    <Stack spacing={6}>
      <Stack spacing={3}>
        <FormControl component="form" onSubmit={(e) => e.preventDefault()}>
          <Stack spacing={3}>
            <TextField fullWidth label={t("views.orderEdit.nameLabel")} name="name" value={nameValue} onChange={(e) => setNameValue(e.target.value)} />
          </Stack>
          <Stack alignItems="center" direction="row" flexWrap="wrap" justifyContent="flex-end" spacing={2} marginTop={2}>
            <Button color="primary" onClick={() => onSave(order._id, nameValue)} size="small" variant="contained" type="submit">
              {t("views.orderEdit.saveButton")}
            </Button>
            <Button color="inherit" onClick={onCancel} size="small">
              {t("views.orderEdit.cancelButton")}
            </Button>
          </Stack>
        </FormControl>
      </Stack>
    </Stack>
  );
};

OrderEdit.propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  order: PropTypes.object,
};
