import { api } from '../../api/endpoints';
import {
    EMAIL_TEMP_GET,
    EMAIL_TEMP_SAVE,
    EDIT_TEMP_EMAIL,
    EMAIL_OFF_TEMP
} from '../constant';
export const emailTempSave = (obj) => {
    return async (dispatch) => {
        const { status, data } = await api.post('/email/saveemiltemp', obj);
        if (status === 201) {
            dispatch({
                type: EMAIL_TEMP_SAVE,
                payload: data.emailTemp
            })
        }

    }
}
export const getEmailTemp = () => {
    return async (dispatch) => {
        const { status, data } = await api.get('/email/getemailtemps');
        if (status === 200) {
            dispatch({
                type: EMAIL_TEMP_GET,
                payload: data.emailTemps
            })
        }
    }
}
export const editEmailTemp = (id, obj) => {
    return async (dispatch) => {
        const { status, data } = await api.patch(`/email/editemailtemp/${id}`, obj);
        if (status === 201) {
            dispatch({
                type: EDIT_TEMP_EMAIL,
                payload: data.editEmail
            })
        }
    }
}
export const emailFlagOff = () => {
    return async (dispatch) => {
        dispatch({
            type: EMAIL_OFF_TEMP
        })
    }
}