import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { TextField, Button, Box } from "@mui/material";
import { ChromePicker } from "react-color";

const ColorPickerApp = ({ onColorChange }) => {
  const { t } = useTranslation();
  const [selectedColor, setSelectedColor] = useState("#000");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const textInputRef = useRef(null);
  const appContainerRef = useRef(null);

  const handleColorChange = (color) => {
    setSelectedColor(color.hex);
    onColorChange(color.hex);
  };

  const handleButtonClick = () => {
    setButtonClicked(true);
    setShowColorPicker(false);
  };

  const handleTextFieldClick = () => {
    setShowColorPicker(true);
  };

  const handleOutsideClick = (event) => {
    if (textInputRef.current && !textInputRef.current.contains(event.target) && appContainerRef.current && !appContainerRef.current.contains(event.target)) {
      setShowColorPicker(false);
    }
    setButtonClicked(false);
  };

  // Attach event listener to handle clicks outside the color picker
  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <Box display="flex" alignItems="center" justifyContent="center" ref={appContainerRef}>
      <div style={{ position: "relative" }}>
        <TextField
          label={t("views.colorPickerApp.selectedColorHex")}
          value={selectedColor}
          variant="outlined"
          margin="normal"
          sx={{ width: "350px" }}
          onClick={handleTextFieldClick}
          InputProps={{
            readOnly: true,
          }}
          ref={textInputRef}
        />
        {showColorPicker && (
          <div
            style={{
              position: "absolute",
              top: "-200px",
              left: 0,
              zIndex: 1,
            }}>
            <ChromePicker
              color={selectedColor}
              onChange={handleColorChange}
              styles={{
                default: {
                  picker: { width: "150px", boxShadow: "none" },
                },
              }}
            />
          </div>
        )}
      </div>

      <Button
        variant="contained"
        onClick={handleButtonClick}
        style={{
          backgroundColor: selectedColor,
          height: "55px",
          marginTop: 6,
        }}>
        {t("views.colorPickerApp.change")}
      </Button>
    </Box>
  );
};

export default ColorPickerApp;
