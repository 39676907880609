import { Box, Button, Checkbox, FormControl, IconButton, Input, InputAdornment, InputLabel, TextField, Typography } from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signup, signupFlagOff, loginWithGoogle, loginFlagOff } from "../../redux/actions/User";
import { useForm, Controller } from "react-hook-form";
import toast from "react-hot-toast";
import reCAPTCHA from "react-google-recaptcha";
import Circularbar from "../../components/circularbar/Circularbar";
import { useTranslation } from "react-i18next";
import { signInWithPopup } from "firebase/auth";
import { fbAuth, provider } from "../../firbaseConfig";

function Signup() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth, loging, loggedin, logFail, logBlocked, logout, isVerified, isRegistered, signupSppiner } = useSelector((state) => state.User);
  const [type, setType] = useState(true);
  const [role, setRole] = useState("user");
  const [confirmPasswordType, setConfirmPasswordType] = useState(true);
  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    reset,
    control,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
  });

  useEffect(() => {
    if (isRegistered) {
      toast.success(t("views.signup.accountCreated"));
      navigate("/login");
      dispatch(signupFlagOff());
    }
  }, [isRegistered]);

  useEffect(() => {
    if (loggedin) {
      toast.success(t("views.login.loginSuccess"));
      dispatch(loginFlagOff());
      dispatch(signupFlagOff());
    }
    if (logFail) {
      toast.error(t("views.login.invalidCredentials"));
      dispatch(loginFlagOff());
      dispatch(signupFlagOff());
    }
    if (logBlocked) {
      toast.error(t("views.login.userBlocked"));
      dispatch(loginFlagOff());
      dispatch(signupFlagOff());
    }
    if (!isVerified) {
      toast.error(t("views.login.verificationRequired"));
      dispatch(loginFlagOff());
      dispatch(signupFlagOff());
    }
  }, [logFail, logBlocked, loggedin, isVerified]);

  const onGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(fbAuth, provider);
      if (result) {
        dispatch(loginWithGoogle(result));
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (auth) {
    return navigate("/");
  }

  const onSubmit = async (data) => {
    try {
      await dispatch(
        signup({
          fullname: data.fullname,
          email: data.email,
          password: data.password,
          confirmPassword: data.confirmPassword,
          role: role,
        })
      );
      reset({
        fullname: "",
        email: "",
        password: "",
        confirmPassword: "",
      });
    } catch (error) {
      if (error?.response?.data?.message) {
        dispatch(signupFlagOff());
        toast.error(error.response.data.message);
      }
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "100vw",
          position: "relative",
        }}>
        {signupSppiner && (
          <Box
            sx={{
              height: "100vh",
              width: "100vw",
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              zIndex: 10000,
              background: "rgba(0,0,0,0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Circularbar />
          </Box>
        )}
        <Box
          sx={{
            height: "111.45px",
            width: "158.47px",
            position: "absolute",
            top: "30px",
          }}>
          <img src="/images/VCPLogo.png" style={{ height: "100%", width: "100%", objectFit: "cover" }} alt="" />
        </Box>
        <Box
          sx={{
            minHeight: "100vh",
            width: "100%",
            background: "url(/images/loginside.png) ,rgba(255,255,255,.1)",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
          }}>
          <Box
            sx={{
              height: "80vh",
              width: {
                xs: "100%",
                sm: "100%",
                md: "30vw",
                lg: "30vw",
                xl: "30vw",
              },
            }}>
            <Typography variant={"body1"} sx={{ margin: "30px auto", textAlign: "center" }}>
              <Typography variant={"caption"} sx={{ color: "#016AFE", fontWeight: "700", fontSize: "40px" }}>
                VCPlayer
              </Typography>
              <br />
              <Typography variant={"caption"} sx={{ color: "#313031", fontWeight: "700", fontSize: "40px" }}>
                SIGNAGE
              </Typography>
            </Typography>
            <Box
              sx={{
                width: "100%",
                height: "40px",
                // border:'1px solid green',
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "5px",
              }}>
              <Button
                variant={"outlined"}
                sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "40px", width: "40px" }}
                onClick={onGoogleLogin}>
                <img src="/images/googleIcon.png" style={{ height: "100%", width: "100%" }} />
              </Button>
            </Box>
            <Box
              sx={{
                height: "60vh",
                width: "90%",
                margin: "10px auto",
              }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box
                  sx={{
                    height: "50px",
                    width: "90%",
                    margin: "0 auto",
                    display: "flex",
                    flexDirection: "column",
                  }}>
                  <TextField
                    {...register("fullname", {
                      required: true,
                    })}
                    variant={"standard"}
                    label={t("views.signup.fullName")}
                    type={"text"}
                    sx={{ height: "70px", input: { color: "#313031" } }}
                    fullWidth
                  />
                  <Typography sx={{ color: "error.main" }}>{errors.fullname && t("views.signup.nameRequired")}</Typography>
                </Box>
                <Box
                  sx={{
                    height: "50px",
                    width: "90%",
                    margin: "0 auto",
                    display: "flex",
                    flexDirection: "column",
                  }}>
                  <TextField
                    {...register("email", {
                      required: true,
                      pattern: {
                        value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                        message: t("views.signup.invalidEmail"),
                      },
                    })}
                    variant={"standard"}
                    label={t("views.signup.email")}
                    type={"email"}
                    sx={{ height: "70px", input: { color: "#313031" } }}
                    fullWidth
                  />
                  <Typography sx={{ color: "error.main" }}>{errors.email && errors.email?.message}</Typography>
                </Box>
                <Box
                  sx={{
                    height: "50px",
                    width: "90%",
                    margin: "10px auto",
                    display: "flex",
                    flexDirection: "column",
                  }}>
                  <FormControl variant={"standard"} fullWidth>
                    <InputLabel htmlFor="password">{t("views.signup.password")}</InputLabel>
                    <Input
                      id="password"
                      type={type ? "password" : "text"}
                      sx={{ color: "#313031" }}
                      {...register("password", {
                        required: true,
                        minLength: 6,
                      })}
                      endAdornment={
                        <InputAdornment position={"end"} style={{ marginTop: "-10px" }}>
                          <IconButton aria-label="toggle password visibility" onClick={() => setType(!type)}>
                            {type ? <VisibilityOffIcon style={{ color: "#551fff" }} /> : <VisibilityIcon style={{ color: "#551fff" }} />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <Typography sx={{ color: "error.main" }}>{errors.password?.type === "required" && t("views.signup.passwordRequired")}</Typography>
                  <Typography sx={{ color: "error.main" }}>{errors.password?.type === "minLength" && t("views.signup.passwordMinLength")}</Typography>
                </Box>
                <Box
                  sx={{
                    height: "50px",
                    width: "90%",
                    margin: "10px auto",
                    display: "flex",
                    flexDirection: "column",
                  }}>
                  <FormControl variant={"standard"} fullWidth>
                    <InputLabel htmlFor="confirmPassword">{t("views.signup.confirmPassword")}</InputLabel>
                    <Input
                      id="confirmPassword"
                      type={confirmPasswordType ? "password" : "text"}
                      sx={{ color: "#313031" }}
                      {...register("confirmPassword", {
                        required: true,
                        validate: (value) => value === getValues("password") || t("views.signup.passwordMismatch"),
                      })}
                      endAdornment={
                        <InputAdornment position={"end"} style={{ marginTop: "-10px", color: "#551fff" }}>
                          <IconButton aria-label="toggle password visibility" onClick={() => setConfirmPasswordType(!confirmPasswordType)}>
                            {confirmPasswordType ? <VisibilityOffIcon style={{ color: "#551fff" }} /> : <VisibilityIcon style={{ color: "#551fff" }} />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <Typography>{errors.confirmPassword && errors.confirmPassword?.message}</Typography>
                </Box>

                <Box
                  sx={{
                    height: "50px",
                    width: "90%",
                    margin: "5px auto",
                    display: "flex",
                    flexDirection: "column",
                  }}>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      ml: "-9px",
                      width: "90%",
                    }}>
                    <Controller
                      control={control}
                      name={"policy"}
                      defaultValue={false}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <>
                          <Checkbox {...field} />
                          <Typography color="text.secondary" variant="body2">
                            I have read the <a href="https://vcplayer.com/terms-and-conditions">Terms and Conditions</a>
                          </Typography>
                        </>
                      )}
                    />
                  </Box>
                  <Typography sx={{ color: "error.main" }}>{errors?.policy && "You need to accept Terms and Conditions"}</Typography>
                </Box>

                <Box
                  sx={{
                    height: "50px",
                    width: "90%",
                    margin: "20px auto",
                  }}>
                  <reCAPTCHA sitekey={"AIzaSyArZc3hs5if33IG5l0u1bkzZRkKLsQJ8EY"} clientid={"935920833622-abfevjo5720567nu3eefc5crh5in8k8f.apps.googleusercontent.com"} />
                </Box>

                <Box
                  sx={{
                    height: "50px",
                    width: "90%",
                    margin: "20px auto",
                  }}>
                  <Button
                    type={"submit"}
                    sx={{
                      textTransform: "capitalize",
                      height: {
                        xl: "54px",
                        lg: "50px",
                        md: "45px",
                        sm: "40px",
                        xs: "30px",
                      },
                      fontSize: "20px",
                      fontWeight: "700",
                      lineHeight: "38px",
                      backgroundColor: "#016AFE",
                      borderRadius: "6px",
                    }}
                    variant={"contained"}
                    fullWidth>
                    {t("views.signup.signUp")}
                  </Button>
                </Box>
              </form>
              <Box
                sx={{
                  height: "50px",
                  width: "100%",
                  margin: "40px auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <Typography
                  variant={"caption"}
                  sx={{
                    color: "#313031",
                    fontWeight: 400,
                    fontSize: "18px",
                    linHeight: "25px",
                  }}>
                  {t("views.signup.backToLogin")}
                </Typography>
                &nbsp;
                <Link to={"/login"} style={{ textDecoration: "none", color: "#016AFE" }}>
                  {t("views.signup.login")}
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            minHeight: "100vh",
            width: "100%",
            display: {
              sm: "none",
              xs: "none",
              md: "flex",
              lg: "flex",
              xl: "flex",
            },
            background: "url(/images/logincarouselside.png) ,linear-gradient(30.84deg, #016AFE 61.34%, rgba(1, 163, 254, 0.53) 100%);",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Box
            sx={{
              height: "90vh",
              width: "90%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Typography
                variant={"h4"}
                sx={{
                  color: "#F0F3FB",
                  fontWeight: "700",
                  fontSize: { xl: "40px", lg: "30px", md: "20" },
                  lineHeight: "102.6%",
                  textAlign: "center",
                  letterSpacing: "-0.02em",
                  textTransform: "uppercase",
                }}>
                {t("views.signup.weMake")}
              </Typography>
              <Typography
                variant={"h2"}
                sx={{
                  color: "#F0F3FB",
                  fontWeight: "700",
                  fontSize: { xl: "60px", lg: "40px", md: "20" },
                  lineHeight: "102.6%",
                  textAlign: "center",
                  letterSpacing: "-0.02em",
                  textTransform: "uppercase",
                }}>
                {t("views.signup.digitalSign")}
              </Typography>
              <Typography
                variant={"caption"}
                sx={{
                  color: "#F0F3FB",
                  fontWeight: "400",
                  fontSize: { xl: "22px", lg: "15px", md: "10" },
                  lineHeight: "32px",
                  textAlign: "center",
                  letterSpacing: "-0.02em",
                  textTransform: "capatilize",
                  width: "500px",
                  margin: { xl: "25px 0", lg: "15px 0", md: "10px 0" },
                }}>
                {t("views.signup.impressCustomers")}
              </Typography>
            </Box>

            <Box
              sx={{
                height: { xl: "300px", lg: "300px", md: "300px" },
                width: { xl: "350px", lg: "300px", md: "300px" },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <img src={"/images/iMacMonitor.png"} style={{ height: "100%", width: "100%" }} />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Typography
                variant={"caption"}
                sx={{
                  color: "#F0F3FB",
                  fontWeight: "400",
                  fontSize: { xl: "15px", lg: "13px", md: "10px" },
                  lineHeight: "22px",
                  textAlign: "center",
                  letterSpacing: "-0.02em",
                  textTransform: "capatilize",
                  width: "500px",
                  margin: { xl: "25px 0", lg: "15px 0", md: "10px 0" },
                }}>
                {t("views.signup.liveScrolling")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Signup;
