import PropTypes from "prop-types";
import ImageIcon from "@mui/icons-material/Image";
import DataObjectIcon from "@mui/icons-material/DataObject";
import Edit02Icon from "@untitled-ui/icons-react/build/esm/Edit02";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useState, useCallback, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import XIcon from "@untitled-ui/icons-react/build/esm/X";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import CheckIcon from "@mui/icons-material/Check";
import { resolveSrc } from "../../../../api/endpoints";
import SlowMotionVideoIcon from "@mui/icons-material/SlowMotionVideo";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { playlistContentSorting, updateDuration } from "src/redux/actions/Playlist";
import { useTranslation } from "react-i18next";
import { Refresh } from "@mui/icons-material";

const statusMap = {
  canceled: "warning",
  complete: "success",
  pending: "info",
  rejected: "error",
};

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const OrderDetails = (props) => {
  const { onApprove, onReject, onSelectFiles, order, isEditing, handleEditOpen, handleDelete, saveDurationChange, deleteContentFromPlaylist, onSync, enableEdit } = props;

  const { t } = useTranslation();

  const [duration, setDuration] = useState(0);
  const [isEditingFile, setIsEditingFile] = useState(null);
  const [editingDuration, setEditingDuration] = useState(0);
  const dispatch = useDispatch();

  const handleDurationChangeEnd = useCallback(
    async (id) => {
      console.log("handleDurationChangeEnd - before update", {
        id,
        duration: editingDuration,
      });
      if (editingDuration !== 0 && !isNaN(editingDuration)) {
        await dispatch(
          updateDuration({
            time: editingDuration,
            playlistId: order._id,
            contentId: id,
            resetDuration: false,
          })
        );
      }
      setIsEditingFile(null);
      setEditingDuration(0);
    },
    [dispatch, editingDuration, order._id]
  );

  const handleResetDuration = useCallback(
    async (id) => {
      const item = order.content.find((item) => item._id === id);
      if (item && item.content) {
        const originalDuration = parseInt(item.content.duration, 10) / 1000;
        console.log("handleResetDuration - original duration", originalDuration);
        await dispatch(
          updateDuration({
            time: originalDuration,
            playlistId: order._id,
            contentId: id,
            resetDuration: true,
          })
        );
      }
      setIsEditingFile(null);
      setEditingDuration(0);
    },
    [dispatch, order]
  );

  const handleIsEditing = useCallback((item) => {
    const durationInSeconds = parseInt(item.duration, 10) / 1000;
    setEditingDuration(durationInSeconds);
    setIsEditingFile(item._id);
  }, []);

  const renderDuration = useCallback((duration) => {
    const durationInSeconds = parseInt(duration, 10) / 1000;
    return isNaN(durationInSeconds) ? "0s" : `${durationInSeconds.toFixed(0)}s`;
  }, []);

  const dragHandler = (result) => {
    // Check if the drop was successful
    if (!result.destination) {
      return;
    }

    // Update the order of items based on the drag and drop result

    const reorderedList = Array.from(order.content);
    const [movedItem] = reorderedList.splice(result.source.index, 1);
    reorderedList.splice(result.destination.index, 0, movedItem);

    order.content = reorderedList;

    // Update your component state or dispatch an action to update the order
    dispatch(playlistContentSorting(order._id, result.source.index, result.destination.index));
  };

  return (
    <>
      <Stack spacing={6}>
        <Divider />
        <Stack spacing={3}>
          <Stack alignItems="center" direction="row" spacing={1}>
            <Typography variant="h6">{t("views.orderDetails.filesTitle")}</Typography>
            {enableEdit && (
              <>
                <Button
                  onClick={onSelectFiles}
                  sx={{ marginLeft: "auto !important" }}
                  startIcon={
                    <SvgIcon>
                      <AddIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                  size="small">
                  {t("views.orderDetails.addButton")}
                </Button>
                <Button onClick={handleEditOpen} size="small" variant="contained">
                  {t("views.orderDetails.editButton")}
                </Button>
                <Button color="error" onClick={handleDelete} size="small" variant="outlined">
                  {t("views.orderDetails.deleteButton")}
                </Button>
              </>
            )}
          </Stack>

          <DragDropContext onDragEnd={dragHandler}>
            <Droppable droppableId="fileList">
              {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <List>
                    {order?.content
                      ?.filter(function (item) {
                        if (item.content) {
                          return true;
                        }
                        return false;
                      })
                      .map((item, index) => (
                        <Draggable key={item._id} draggableId={item._id} index={index} isDragDisabled={!enableEdit}>
                          {(provided, snapshot) => (
                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              <ListItem
                                sx={{
                                  border: 1,
                                  borderColor: "divider",
                                  borderRadius: 1,
                                  "& + &": {
                                    mt: 1,
                                  },
                                  margin: "5px 0",
                                }}>
                                <Box
                                  sx={{
                                    height: "60px",
                                    width: "100px",
                                    marginRight: "20px",
                                  }}>
                                  {item?.content?.type === "image" && (
                                    <img
                                      src={resolveSrc(item?.content?.url)}
                                      style={{
                                        height: "95%",
                                        width: "80%",
                                        objectFit: "contain",
                                      }}
                                    />
                                  )}
                                  {item?.content?.type === "widget" && (
                                    <img
                                      src={`${item?.content?.widget?.widgetIcon}`}
                                      style={{
                                        height: "95%",
                                        width: "80%",
                                        objectFit: "contain",
                                      }}
                                    />
                                  )}
                                  {item?.content?.type === "video" && (
                                    <video
                                      src={resolveSrc(item?.content?.url)}
                                      controls
                                      style={{
                                        height: "95%",
                                        width: "80%",
                                        objectFit: "contain",
                                      }}
                                    />
                                  )}
                                  {item?.content?.embed === "embed" && (
                                    <embed
                                      src={`${item?.content?.url}`}
                                      style={{
                                        height: "95%",
                                        width: "80%",
                                        objectFit: "contain",
                                      }}
                                    />
                                  )}
                                </Box>
                                <ListItemIcon>
                                  {item?.content?.type === "image" && <ImageIcon />}
                                  {item?.content?.type === "widget" && <AppRegistrationIcon />}
                                  {item?.content?.type === "video" && <SlowMotionVideoIcon />}
                                  {item?.content?.embed === "embed" && <DataObjectIcon />}
                                </ListItemIcon>
                                <ListItemText
                                  primary={item?.content?.name}
                                  primaryTypographyProps={{
                                    variant: "subtitle2",
                                  }}
                                  sx={{
                                    maxWidth: "calc( 100% - 140px )",
                                    overflowWrap: "break-word;",
                                  }}
                                />
                                {isEditingFile === item._id ? (
                                  <>
                                    <TextField
                                      disabled={!enableEdit}
                                      focused
                                      label={t("views.orderDetails.durationLabel")}
                                      fullWidth
                                      type="number"
                                      inputProps={{ min: 0, step: 1 }}
                                      value={isNaN(editingDuration) ? "" : editingDuration}
                                      onChange={(e) => {
                                        const newValue = Number(e.target.value);
                                        setEditingDuration(isNaN(newValue) ? 0 : newValue);
                                      }}
                                      onBlur={() => handleDurationChangeEnd(item._id)}
                                      sx={{ maxWidth: "100px" }}
                                    />
                                    <IconButton edge="end" onClick={() => handleDurationChangeEnd(item._id)}>
                                      <CheckIcon color="primary" />
                                    </IconButton>
                                    <Tooltip title={t("views.orderDetails.resetDurationTooltip")}>
                                      <IconButton edge="end" onClick={() => handleResetDuration(item._id)}>
                                        <Refresh sx={{ color: "purple" }} />
                                      </IconButton>
                                    </Tooltip>
                                    <IconButton edge="end" onClick={() => setIsEditingFile(null)}>
                                      <XIcon color="error" />
                                    </IconButton>
                                  </>
                                ) : (
                                  <>
                                    {enableEdit && (
                                      <Tooltip title={t("views.orderDetails.changeDurationTooltip")}>
                                        <IconButton sx={{ marginRight: "8px" }} edge="end" onClick={() => handleIsEditing(item)}>
                                          <Edit02Icon color="primary" />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                    <Tooltip title={t("views.orderDetails.durationTooltip")}>
                                      <ListItemText
                                        primary={renderDuration(item.duration)}
                                        primaryTypographyProps={{
                                          variant: "caption",
                                        }}
                                        sx={{
                                          marginLeft: "auto",
                                          width: "30px",
                                          flexGrow: 0,
                                        }}
                                      />
                                    </Tooltip>
                                    {enableEdit && (
                                      <Tooltip title={t("views.orderDetails.removeTooltip")}>
                                        <IconButton
                                          edge="end"
                                          onClick={() =>
                                            deleteContentFromPlaylist({
                                              playlistId: order._id,
                                              contentId: item._id,
                                            })
                                          }>
                                          <SvgIcon>
                                            <XIcon color="error" />
                                          </SvgIcon>
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                  </>
                                )}
                              </ListItem>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </List>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Stack>
        {enableEdit && (
          <Button
            onClick={onSync}
            startIcon={
              <SvgIcon>
                <Refresh />
              </SvgIcon>
            }
            variant="contained">
            {t("components.tableBodies.sync")}
          </Button>
        )}
      </Stack>
    </>
  );
};

OrderDetails.propTypes = {
  onApprove: PropTypes.func,
  onReject: PropTypes.func,
  order: PropTypes.object,
};
