import { useState, useCallback, useMemo, memo } from "react";
import { useSelector } from "react-redux";
import { CardHeader, Card, Button, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import ConfirmDialog from "../dialog/ConfirmDialog";

const WidgetsGridHeader = memo(({ title, editMode, onEdit, onCancel, onSave }) => {
  const { t } = useTranslation();
  const isAdmin = useSelector((state) => state.User.user?.role == "admin");

  const [action, setAction] = useState("");
  const [confirmMessage, setConfirmMessage] = useState();

  const confirmAction = useCallback(
    (action) => {
      switch (action) {
        case "SAVE":
          setConfirmMessage(t("components.widgetsGridHeader.confirmSave"));
          break;
        case "CANCEL":
          setConfirmMessage(t("components.widgetsGridHeader.confirmDiscard"));
          break;
      }
      setAction(action);
    },
    [t]
  );

  const handleEdit = useCallback(() => {
    onEdit?.();
  }, [onEdit]);

  const handleActionReject = useCallback(() => {
    setAction("");
    setConfirmMessage("");
  }, []);

  const handleActionAccept = useCallback(() => {
    switch (action) {
      case "SAVE":
        onSave?.();
        break;
      case "CANCEL":
        onCancel?.();
        break;
    }

    handleActionReject();
  }, [onCancel, onSave, action]);

  if (!isAdmin) return null;

  return (
    <>
      <Card>
        <CardHeader
          sx={{ padding: "24px 16px" }}
          title={title}
          action={
            <Box display="flex" gap={1}>
              {!editMode && <Button onClick={handleEdit}>{t("components.widgetsGridHeader.edit")}</Button>}
              {editMode && <Button onClick={() => confirmAction("CANCEL")}>{t("components.widgetsGridHeader.cancel")}</Button>}
              {editMode && (
                <Button onClick={() => confirmAction("SAVE")} variant="contained">
                  {t("components.widgetsGridHeader.save")}
                </Button>
              )}
            </Box>
          }
        />
      </Card>
      {action && confirmMessage && <ConfirmDialog open title={confirmMessage} onClose={handleActionReject} onAccept={handleActionAccept} />}
    </>
  );
});

export default WidgetsGridHeader;
