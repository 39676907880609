import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import Layout from "../../../components/layout/Layout";
import { Box, Container, Typography } from "@mui/material";
import Subbar from "./Subbar";
import FolderItem from "./FolderItem";
import FileItem from "./FileItem";
import { useTranslation } from "react-i18next";

const FolderComponent = () => {
  const { t } = useTranslation();
  const { folderId } = useParams();
  const { currentFolderData, children, files } = useSelector(
    (state) => ({
      currentFolderData: state.FileFolder.userFolders.find((f) => f._id === folderId),
      children: state.FileFolder.userFolders.filter((c) => c.parent === folderId),
      files: state.Content.content.filter((c) => c.folderId === folderId),
    }),
    shallowEqual
  );
  useEffect(() => {}, []);

  return (
    <>
      <Container maxWidth={"lg"} sx={{ background: "#ffffff" }}>
        <Subbar />
        <Box sx={{ height: "50vh", width: "100%", display: "flex", gap: "10px" }}>
          {children.length === 0 ? (
            <Typography>{t("views.folderComponent.empty")}</Typography>
          ) : (
            children.map((folder, index) => <FolderItem key={index} folder={folder} />)
          )}
        </Box>
        <Box sx={{ height: "50vh", width: "100%", display: "flex", gap: "10px" }}>
          {files.length === 0 ? <Typography>{t("views.folderComponent.empty")}</Typography> : files.map((file, index) => <FileItem key={index} files={file} />)}
        </Box>
      </Container>
    </>
  );
};

export default FolderComponent;
