import { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useTranslation } from "react-i18next";
import { Scrollbar } from "src/components/scrollbar";

function RedesignUserBillingHistory({ user }) {
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader title={t("views.userBillingHistory.title")} />
      <Scrollbar>
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <TableCell>{t("views.userBillingHistory.currentPlan")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{user.plan.name}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Scrollbar>
    </Card>
  );
}

RedesignUserBillingHistory.propTypes = {
  user: PropTypes.object.isRequired,
};

export default RedesignUserBillingHistory;
