import { Button, TextField, Box, Dialog, DialogTitle, DialogContent } from "@mui/material";
import React from "react";
import { FaList } from "react-icons/fa";
import { useTranslation } from "react-i18next";

function AddPlaylistModel({ openCreatePlaylist, value, onClose, textValue, savePlaylist }) {
  const { t } = useTranslation();

  return (
    <>
      <Dialog open={openCreatePlaylist} onClose={() => onClose(false)} maxWidth={"xl"} sx={{ zIndex: "1000000" }}>
        <DialogTitle sx={{ textAlign: "center" }}>{t("components.addPlaylistModel.createPlaylist")}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: { xl: "50vw", lg: "50vw", sm: "70vw", xs: "70vw" },
              height: "50vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}>
            <Box
              sx={{
                width: { xl: "183px", lg: "183px", sm: "120px", xs: "120px" },
                height: { xl: "154.41px", lg: "154.41px", sm: "100px", xs: "100px" },
                margin: "0 auto",
              }}>
              <FaList
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                  color: "#1977FA",
                }}
              />
            </Box>
            <Box sx={{ margin: "20px 0" }}>
              <TextField
                variant={"standard"}
                fullWidth
                type={"text"}
                label={t("components.addPlaylistModel.writePlaylistName")}
                value={value}
                onChange={(e) => textValue(e.target.value)}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "40px 0",
                gap: "10px 10px",
              }}>
              <Button
                variant={"outlined"}
                sx={{
                  height: "38px",
                  width: "193px",
                  textTransform: "capitalize",
                  borderRadius: "5px",
                }}
                onClick={() => onClose(false)}>
                {t("components.addPlaylistModel.close")}
              </Button>
              <Button
                variant={"contained"}
                onClick={() => savePlaylist()}
                sx={{
                  height: "38px",
                  width: "193px",
                  textTransform: "capitalize",
                  borderRadius: "5px",
                }}>
                {t("components.addPlaylistModel.save")}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AddPlaylistModel;
