import React from "react";
import { Box } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setFolderId } from "../../../redux/actions/FileFolder";
function FolderItem({ folder }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onDoubleClicks = (id) => {
    dispatch(setFolderId(id));
    return navigate(`/foldercomponent/${id}`);
  };
  return (
    <>
      <Box
        sx={{
          height: "100px",
          width: "130px",
          borderRadius: "10px",
          border: "1px solid black",
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        onDoubleClick={() => onDoubleClicks(folder._id)}>
        <Box>
          <FolderIcon sx={{ fontSize: "50px" }} />
        </Box>
        <Box>{folder.name}</Box>
      </Box>
    </>
  );
}

export default FolderItem;
