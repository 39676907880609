import React from "react";

const CardEmbedPreview = ({ item }) => {
  const { title, inputValue } = item;

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}>
        <div
          style={{
            height: "70vh",
            width: "60vw",
            backgroundColor: "gray",
            overflow: "auto",
          }}
          dangerouslySetInnerHTML={{ __html: inputValue }}
        />
      </div>
    </>
  );
};

export default CardEmbedPreview;
