import { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import XIcon from "@untitled-ui/icons-react/build/esm/X";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import ConfirmDialog from "../../../../components/dialog/ConfirmDialog";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { OrderDetails } from "./order-details";
import { OrderEdit } from "./order-edit";

export const OrderDrawer = (props) => {
  const {
    container,
    onClose,
    open,
    order,
    orders,
    onEdit,
    onSelectFiles,
    deletePlaylists,
    editPlaylistNameSave,
    saveDurationChange,
    deleteContentFromPlaylist,
    onSync,
    onSetDemoPlaylist,
    isAdmin,
  } = props;
  const enableEdit = !order?.defaultPlaylist || isAdmin;

  const [isEditing, setIsEditing] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const { t } = useTranslation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const handleEditOpen = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleEditCancel = useCallback(() => {
    setIsEditing(false);
  }, []);

  const handleSave = useCallback(
    (id, name) => {
      const isSameNamePlaylist = orders.find((p) => p.playlistName === name);
      if (name === "" || name === undefined) {
        toast.error(t("views.orderDrawer.nameFieldError"));
        return;
      } else if (isSameNamePlaylist) {
        toast.error(t("views.orderDrawer.sameNameError"));
      } else {
        editPlaylistNameSave(id, name);
        setIsEditing(false);
      }
    },
    [t]
  );

  const cofirmDialogAccept = () => {
    deletePlaylists(order._id);
    setConfirmDialogOpen(false);
    onClose();
  };

  useEffect(() => {
    setIsEditing(false);
  }, [order]);

  let content = null;

  if (order) {
    content = (
      <div>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{
            px: 3,
            py: 2,
          }}>
          <Typography color="inherit" variant="h6">
            {order.playlistName}
          </Typography>
          <Stack alignItems="center" direction="row" flexWrap="wrap" justifyContent="flex-end" spacing={2}>
            {/* {!isEditing && enableEdit && (
              <Button onClick={handleEditOpen} size="small" variant="contained">
                {t("views.orderDrawer.editButton")}
              </Button>
            )}

            {enableEdit && (
              <Button color="error" onClick={() => setConfirmDialogOpen(true)} size="small" variant="outlined">
                {t("views.orderDrawer.deleteButton")}
              </Button>
            )} */}
            <IconButton color="inherit" onClick={onClose}>
              <SvgIcon>
                <XIcon />
              </SvgIcon>
            </IconButton>
          </Stack>
        </Stack>
        <Box
          sx={{
            px: 3,
          }}>
          {!isEditing ? (
            <>
              <OrderDetails
                onApprove={onClose}
                onEdit={handleEditOpen}
                onReject={onClose}
                order={order}
                onSelectFiles={onSelectFiles}
                deletePlaylists={deletePlaylists}
                closeDrawer={onClose}
                saveDurationChange={saveDurationChange}
                handleEditOpen={handleEditOpen}
                handleDelete={() => setConfirmDialogOpen(true)}
                deleteContentFromPlaylist={deleteContentFromPlaylist}
                onSync={onSync}
                enableEdit={enableEdit}
              />

              {order?.defaultPlaylist && !isAdmin && (
                <Button disabled variant="contained" sx={{ p: 1, mt: 1 }} fullWidth>
                  {t("views.addPlaylistModal.adminPlaylist")}
                </Button>
              )}

              {isAdmin && (
                <Button variant="contained" sx={{ p: 1, mt: 1 }} color="info" fullWidth onClick={() => onSetDemoPlaylist(order._id, order?.defaultPlaylist || false)}>
                  {!order?.defaultPlaylist ? <>{t("views.orderDrawer.setAsDemo")}</> : <>{t("views.orderDrawer.unsetAsDemo")}</>}
                </Button>
              )}

              <ConfirmDialog
                open={confirmDialogOpen}
                onClose={() => setConfirmDialogOpen(false)}
                onAccept={cofirmDialogAccept}
                title={t("views.orderDrawer.confirmDialogTitle")}
              />
            </>
          ) : (
            <OrderEdit onCancel={handleEditCancel} onSave={handleSave} order={order} />
          )}
        </Box>
      </div>
    );
  }

  if (lgUp) {
    return (
      <Drawer
        anchor="right"
        open={open}
        PaperProps={{
          sx: {
            position: "relative",
            width: 700,
            zIndex: 200,
          },
        }}
        SlideProps={{ container }}
        variant="persistent">
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      hideBackdrop
      ModalProps={{
        container,
        sx: {
          pointerEvents: "none",
          position: "absolute",
        },
      }}
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          maxWidth: "100%",
          width: 400,
          pointerEvents: "auto",
          position: "absolute",
        },
      }}
      SlideProps={{ container }}
      variant="temporary">
      {content}
    </Drawer>
  );
};

OrderDrawer.propTypes = {
  container: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  order: PropTypes.object,
};
