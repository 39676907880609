import React, { useEffect, useState } from "react";
import { Box, Typography, Button, TextField, FormControl, InputLabel, Input, InputAdornment, IconButton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { loginFlagOff, passwordChange } from "../../redux/actions/User";
import toast from "react-hot-toast";
import Circularbar from "../../components/circularbar/Circularbar";
import { useTranslation } from "react-i18next";

function ChangePassword() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isPasswordChangeRequesting, isPasswordChanged, isPasswordChangeTokenExpired } = useSelector((state) => state.User);
  const params = useParams();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");

  useEffect(() => {
    if (isPasswordChanged) {
      toast.success(t("views.changePassword.passwordChangeSuccess"));
      dispatch(loginFlagOff());
    }
    if (isPasswordChangeTokenExpired) {
      toast.error(t("views.changePassword.tokenExpired"));
      dispatch(loginFlagOff());
    }
  }, [isPasswordChanged, isPasswordChangeTokenExpired]);

  const passwordSaveChanges = () => {
    dispatch(
      passwordChange({
        token: params.token,
        password,
        confirmPasswords: cPassword,
      })
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "100vw",
          position: "relative",
        }}>
        {isPasswordChangeRequesting && (
          <>
            <Box
              sx={{
                height: "100vh",
                width: "100vw",
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                zIndex: 10000,
                background: "rgba(0,0,0,0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Circularbar />
            </Box>
          </>
        )}
        <Box
          sx={{
            height: "111.45px",
            width: "158.47px",
            position: "absolute",
            top: "30px",
          }}>
          <img src="/images/VCPLogo.png" style={{ height: "100%", width: "100%", objectFit: "cover" }} alt="" />
        </Box>
        <Box
          sx={{
            minHeight: "100vh",
            width: "100%",
            background: "url(/images/loginside.png) ,rgba(255,255,255,.1)",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
          }}>
          <Box
            sx={{
              height: "80vh",
              width: {
                xs: "100%",
                sm: "100%",
                md: "30vw",
                lg: "30vw",
                xl: "30vw",
              },
            }}>
            <Typography variant={"body1"} sx={{ margin: "30px auto", textAlign: "center" }}>
              <Typography variant={"caption"} sx={{ color: "#016AFE", fontWeight: "700", fontSize: "40px" }}>
                VCPlayer
              </Typography>
              <br />
              <Typography variant={"caption"} sx={{ color: "#313031", fontWeight: "700", fontSize: "40px" }}>
                SIGNAGE
              </Typography>
            </Typography>

            <Box
              sx={{
                height: "60vh",
                width: "90%",
                margin: "55px auto",
              }}>
              {isPasswordChanged ? (
                <Button variant="standard" onClick={() => navigate("/login")}>
                  {t("views.changePassword.goToLogin")}
                </Button>
              ) : (
                <>
                  <Typography
                    variant={"caption"}
                    sx={{
                      margin: "0 20px",
                      color: "#313031",
                      fontWeight: "400",
                      lineHeight: "54px",
                      fontSize: { xl: "40px", lg: "40px", md: "40px", sm: "20px", xs: "20px" },
                    }}>
                    {t("views.changePassword.newPassword")}
                  </Typography>
                  <Box
                    sx={{
                      height: "50px",
                      width: "90%",
                      margin: "20px auto",
                    }}>
                    <FormControl sx={{ width: "100%", input: { color: "#313031" } }} variant={"standard"}>
                      <InputLabel htmlFor="new-password">{t("views.changePassword.newPassword")}</InputLabel>
                      <Input
                        id="new-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type={showNewPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position={"end"}>
                            <IconButton arial-label="toggle password visiblity" onClick={() => setShowNewPassword(!showNewPassword)}>
                              {showNewPassword ? <VisibilityOff sx={{ color: "#551ffa" }} /> : <Visibility sx={{ color: "#551ffa" }} />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Box>
                  <Box
                    sx={{
                      height: "50px",
                      width: "90%",
                      margin: "20px auto",
                    }}>
                    <FormControl sx={{ width: "100%", input: { color: "#313031" } }} variant={"standard"}>
                      <InputLabel htmlFor="confirm-password">{t("views.changePassword.confirmPassword")}</InputLabel>
                      <Input
                        id="confirm-password"
                        value={cPassword}
                        onChange={(e) => setCPassword(e.target.value)}
                        type={confirmPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility" onClick={() => setConfirmPassword(!confirmPassword)}>
                              {confirmPassword ? <VisibilityOff sx={{ color: "#551ffa" }} /> : <Visibility sx={{ color: "#551ffa" }} />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Box>
                  <Box
                    sx={{
                      height: "50px",
                      width: "90%",
                      margin: "10px auto",
                    }}>
                    <Button
                      onClick={passwordSaveChanges}
                      sx={{
                        textTransform: "capitalize",
                        height: { xl: "54px", lg: "50px", md: "45px", sm: "40px", xs: "30px" },
                        fontSize: "20px",
                        fontWeight: "700",
                        lineHeight: "38px",
                        backgroundColor: "#016AFE",
                        borderRadius: "6px",
                      }}
                      variant={"contained"}
                      fullWidth>
                      {t("views.changePassword.changePassword")}
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            minHeight: "100vh",
            width: "100%",
            display: {
              sm: "none",
              xs: "none",
              md: "flex",
              lg: "flex",
              xl: "flex",
            },
            background: "url(/images/logincarouselside.png) ,linear-gradient(30.84deg, #016AFE 61.34%, rgba(1, 163, 254, 0.53) 100%);",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Box
            sx={{
              height: "90vh",
              width: "90%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Typography
                variant={"h4"}
                sx={{
                  color: "#F0F3FB",
                  fontWeight: "700",
                  fontSize: { xl: "40px", lg: "30px", md: "20" },
                  lineHeight: "102.6%",
                  textAlign: "center",
                  letterSpacing: "-0.02em",
                  textTransform: "uppercase",
                }}>
                {t("views.changePassword.screenSlogan1")}
              </Typography>
              <Typography
                variant={"h2"}
                sx={{
                  color: "#F0F3FB",
                  fontWeight: "700",
                  fontSize: { xl: "60px", lg: "40px", md: "20" },
                  lineHeight: "102.6%",
                  textAlign: "center",
                  letterSpacing: "-0.02em",
                  textTransform: "uppercase",
                }}>
                {t("views.changePassword.screenSlogan2")}
              </Typography>
              <Typography
                variant={"caption"}
                sx={{
                  color: "#F0F3FB",
                  fontWeight: "400",
                  fontSize: { xl: "22px", lg: "15px", md: "10" },
                  lineHeight: "32px",
                  textAlign: "center",
                  letterSpacing: "-0.02em",
                  textTransform: "capitalize",
                  width: "500px",
                  margin: { xl: "25px 0", lg: "15px 0", md: "10px 0" },
                }}>
                {t("views.changePassword.sloganDescription1")}
              </Typography>
            </Box>

            <Box
              sx={{
                height: { xl: "300px", lg: "300px", md: "300px" },
                width: { xl: "350px", lg: "300px", md: "300px" },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <img src={"/images/iMacMonitor.png"} style={{ height: "100%", width: "100%" }} />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Typography
                variant={"caption"}
                sx={{
                  color: "#F0F3FB",
                  fontWeight: "400",
                  fontSize: { xl: "15px", lg: "13px", md: "10px" },
                  lineHeight: "22px",
                  textAlign: "center",
                  letterSpacing: "-0.02em",
                  textTransform: "capitalize",
                  width: "500px",
                  margin: { xl: "25px 0", lg: "15px 0", md: "10px 0" },
                }}>
                {t("views.changePassword.sloganDescription2")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ChangePassword;
