import { memo } from 'react';
import { IconButton } from '@mui/material';

import OpenWithIcon from '@mui/icons-material/OpenWith';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const WidgetItemActions = memo(({ onChangeVisibility, onDelete, onEdit, moveListeners, isVisible, editMode }) => {

    return editMode && (
        <>
            <IconButton onClick={onChangeVisibility}>
                {isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
            <IconButton onClick={onEdit}>
                <EditIcon />
            </IconButton>
            <IconButton>
                <OpenWithIcon {...moveListeners} />
            </IconButton>
            <IconButton onClick={onDelete}>
                <DeleteIcon />
            </IconButton>
        </>
    );

});

export default WidgetItemActions;