import { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useTranslation } from "react-i18next";
import { MoreMenu } from "src/components/more-menu";
import { Scrollbar } from "src/components/scrollbar";

import { useDispatch, useSelector } from "react-redux";

import { getLogs } from "../../redux/actions/Log";

import formatDate from "src/utils/format-date";

function RedesignUserLogs({ user }) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { logs, limit, page, allLogs } = useSelector((state) => state.Log);

  useEffect(() => {
    dispatch(getLogs({ user: user._id, page: 1, limit: 10 }));
  }, []);

  const handleChangePage = useCallback(
    (event, newPage) => {
      dispatch(getLogs({ user: user._id, page: newPage + 1, limit: limit }));
    },
    [dispatch, user._id, limit]
  );

  const handleChangeRowsPerPage = useCallback(
    (event) => {
      dispatch(getLogs({ user: user._id, page: page, limit: parseInt(event.target.value, 10) }));
    },
    [dispatch, user._id, page]
  );

  return (
    <Card>
      <CardHeader action={<MoreMenu />} title={t("views.userLogs.recentLogs")} />
      <Scrollbar>
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <TableCell>{t("views.userLogs.message")}</TableCell>
              <TableCell>{t("views.userLogs.date")}</TableCell>
              <TableCell>{t("views.userLogs.ip")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logs.length > 0 &&
              logs.map((log) => {
                return (
                  <TableRow key={log.id}>
                    <TableCell>{t(log.message, { ...log.data, interpolation: { escapeValue: false } })}</TableCell>
                    <TableCell>{formatDate(new Date(log.createdAt))}</TableCell>
                    <TableCell>{log.ip ? log.ip : t("views.userLogs.notAvailable")}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Scrollbar>
      <TablePagination
        component="div"
        count={allLogs}
        page={page - 1}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage={t("views.userLogs.rowsQty")}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
}

RedesignUserLogs.propTypes = {
  user: PropTypes.object.isRequired,
};

export default RedesignUserLogs;
