import { EMAIL_TEMP_GET, EMAIL_TEMP_SAVE, EDIT_TEMP_EMAIL, EMAIL_OFF_TEMP } from "../constant";

const initial = {
    emailtemps: [],
    isEdited: false,
    isAdded: false
}
export const EmailTempReducer = (state = initial, action) => {
    switch (action.type) {
        case EMAIL_OFF_TEMP:
            return {
                ...state,
                isEdited: false,
                isAdded: false
            }
        case EMAIL_TEMP_SAVE:
            return {
                ...state,
                isAdded: true,
                emailtemps: [...state.emailtemps, action.payload]
            }
        case EMAIL_TEMP_GET:
            return {
                ...state,
                emailtemps: [...action.payload]
            }
        case EDIT_TEMP_EMAIL:
            return {
                ...state,
                isEdited: true,
                emailtemps: [action.payload]
            }
        default:
            return state;
    }
}