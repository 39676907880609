import { useRef, useCallback, useEffect, useState, useMemo } from "react";
import { useFormContext } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";

import Camera01Icon from "@untitled-ui/icons-react/build/esm/Camera01";
import User01Icon from "@untitled-ui/icons-react/build/esm/User01";

import { alpha } from "@mui/system/colorManipulator";

import Box from "@mui/material/Box";
import SvgIcon from "@mui/material/SvgIcon";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import UserAvatar from "src/components/avatar/UserAvatar";

const AvatarInputFile = ({ user, name }) => {
  const { t } = useTranslation();
  const {
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const fileInputRef = useRef(null);

  const [avatarProps, setAvatarProps] = useState({ user });

  useEffect(() => {
    setAvatarProps({ user });
    fileInputRef.current.value = null;
  }, [user]);

  const showDeleteButton = useMemo(() => {
    return !!avatarProps?.user?.avatar || !!avatarProps?.src;
  }, [avatarProps]);

  const handleOpenFilePicker = useCallback(() => {
    fileInputRef.current.click();
  }, [name, user]);

  const handleAvatarDelete = useCallback(() => {
    setAvatarProps({
      user: {
        ...user,
        avatar: null,
      },
    });
    setValue(name, null);
    clearErrors(name);
    fileInputRef.current.value = null;
  }, [name, user, setValue, clearErrors]);

  useEffect(() => {
    if (!fileInputRef.current) return;

    const { current: fileInput } = fileInputRef;

    const handleFileChange = (e) => {
      const [avatarFile] = e.target.files;

      if (!avatarFile) return;

      setValue(name, avatarFile);
      clearErrors(name);

      const imageReader = new FileReader();
      imageReader.onload = (event) => {
        const avatarPreviewSrc = event.target.result;
        setAvatarProps({
          user: user,
          src: avatarPreviewSrc,
        });
      };

      imageReader.readAsDataURL(avatarFile);
    };

    fileInput.addEventListener("change", handleFileChange);

    return () => {
      fileInput.removeEventListener("change", handleFileChange);
    };
  }, [name, user, setValue, clearErrors]);

  return (
    <Stack spacing={2}>
      <Stack alignItems="center" direction="row" spacing={2}>
        <Box
          sx={{
            borderColor: errors?.avatar?.message ? "error.main" : "neutral.300",
            borderRadius: "50%",
            borderStyle: "dashed",
            borderWidth: 1,
            p: "4px",
          }}
          onClick={handleOpenFilePicker}>
          <Box
            sx={{
              borderRadius: "50%",
              height: "100%",
              width: "100%",
              position: "relative",
            }}>
            <Box
              sx={{
                alignItems: "center",
                backgroundColor: (theme) => alpha(theme.palette.neutral[700], 0.5),
                borderRadius: "50%",
                color: "common.white",
                cursor: "pointer",
                display: "flex",
                height: "100%",
                justifyContent: "center",
                left: 0,
                opacity: 0,
                position: "absolute",
                top: 0,
                width: "100%",
                zIndex: 1,
                "&:hover": {
                  opacity: 1,
                },
              }}>
              <Stack alignItems="center" direction="row" spacing={1}>
                <SvgIcon color="inherit">
                  <Camera01Icon />
                </SvgIcon>
                <Typography color="inherit" variant="subtitle2" sx={{ fontWeight: 700 }}>
                  {t("views.avatarInput.select")}
                </Typography>
              </Stack>
            </Box>
            <UserAvatar
              {...avatarProps}
              sx={{
                height: 100,
                width: 100,
              }}>
              <SvgIcon>
                <User01Icon />
              </SvgIcon>
            </UserAvatar>

            <input ref={fileInputRef} accept="image/png, image/jpeg" type="file" style={{ display: "none" }} />
          </Box>
        </Box>

        <Box>
          <Typography variant={"h6"}>{t("views.avatarInput.avatar")}</Typography>
          <Typography variant={"body1"}>{t("views.avatarInput.resolution")}</Typography>
          {showDeleteButton && (
            <Button color="inherit" size="small" onClick={handleAvatarDelete}>
              {t("views.avatarInput.delete")}
            </Button>
          )}
        </Box>
      </Stack>
      <Typography variant={"caption"} color="error">
        {errors?.avatar?.message}
      </Typography>
    </Stack>
  );
};

export default AvatarInputFile;
