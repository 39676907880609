import { Box, Button, Container, Checkbox, Table, TableBody, TableCell, TableHead, TableRow, Typography, IconButton, Stack, SvgIcon, Card } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Layout from "../../components/layout/Layout";
import { apkDelete, apkFlagOff, apkUpload, fetchApk } from "../../redux/actions/Apk";
import { FaRegTrashAlt } from "react-icons/fa";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import toast from "react-hot-toast";
import Circularbar from "../../components/circularbar/Circularbar";
import { Scrollbar } from "src/components/scrollbar";
import ConfirmDialog from "../../components/dialog/ConfirmDialog";
import { useSettings } from "src/hooks/use-settings";

function RedesignApkManageNew({ user }) {
  const { t } = useTranslation();
  const settings = useSettings();

  const dispatch = useDispatch();
  const { apk, apkLoading, apkLoaded, isAvailable, isAdded, identity, isAdding } = useSelector((state) => state.Apk);
  const [browseApk, setBrowseApk] = useState("");
  const [openSaveDialog, setOpenDialogSave] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  useEffect(() => {
    dispatch(fetchApk());
  }, [dispatch]);

  useEffect(() => {
    if (isAvailable) {
      toast.success(t("views.redesignApkManageNew.toasts.apkAvailable"));
      dispatch(apkFlagOff());
    }
    if (isAdded) {
      toast.success(t("views.redesignApkManageNew.toasts.apkSaved"));
      dispatch(apkFlagOff());
    }
    if (identity) {
      toast.success(t("views.redesignApkManageNew.toasts.apkInvalid"));
      dispatch(apkFlagOff());
    }
  }, [isAvailable, isAdded, identity, dispatch, t]);

  const handleUpload = (e) => {
    setBrowseApk(e);
    setOpenDialogSave(true);
  };

  const handleSave = () => {
    setOpenDialogSave(false);
    const form = new FormData();
    form.append("apkFile", browseApk);
    setBrowseApk("");
    dispatch(apkUpload(form));
  };

  const handleCancel = () => {
    setBrowseApk("");
    setOpenDialogSave(false);
  };

  const handleDeleteDialog = (id) => {
    setOpenDeleteDialog(true);
    setDeleteId(id);
  };

  const handleDelete = () => {
    setOpenDeleteDialog(false);
    dispatch(apkDelete(deleteId));
  };

  return (
    <Layout title={t("views.redesignApkManageNew.title")} user={user} playlistAddBtn={false}>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}>
        <Container maxWidth={settings.stretch ? false : "xl"}>
          <Stack spacing={4}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">{t("views.redesignApkManageNew.apk")}</Typography>
              </Stack>
              <Stack alignItems="center" direction="row" spacing={3}>
                <input type="file" onChange={(e) => handleUpload(e.target.files[0])} hidden id={"apk-brow"} />
                <Button
                  onClick={() => document.getElementById("apk-brow").click()}
                  startIcon={
                    <SvgIcon>
                      <FileUploadIcon />
                    </SvgIcon>
                  }
                  variant="contained">
                  {t("views.redesignApkManageNew.uploadNewVersion")}
                </Button>
              </Stack>
              {isAdding && (
                <>
                  <Box
                    sx={{
                      height: "100vh",
                      width: "100vw",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      zIndex: 10000,
                      background: "rgba(0,0,0,0.5)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <Circularbar />
                  </Box>
                </>
              )}
            </Stack>
            <Card>
              <Scrollbar>
                <Table sx={{ minWidth: 700 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox />
                      </TableCell>
                      <TableCell>{t("views.redesignApkManageNew.tableHeaders.uploadDate")}</TableCell>
                      <TableCell>{t("views.redesignApkManageNew.tableHeaders.versionName")}</TableCell>
                      <TableCell>{t("views.redesignApkManageNew.tableHeaders.versionCode")}</TableCell>
                      <TableCell>{t("views.redesignApkManageNew.tableHeaders.package")}</TableCell>
                      <TableCell align="right">{t("views.redesignApkManageNew.tableHeaders.actions")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {apk.map((file) => {
                      return (
                        <TableRow key={file._id}>
                          <TableCell padding="checkbox">
                            <Checkbox />
                          </TableCell>
                          <TableCell>{new Date(file?.createdAt).toLocaleString()}</TableCell>
                          <TableCell>{file?.versionName}</TableCell>
                          <TableCell>{file?.versionCode}</TableCell>
                          <TableCell>{file?.package}</TableCell>
                          <TableCell align="right">
                            <IconButton aria-label="delete" onClick={() => handleDeleteDialog(file?._id)}>
                              <FaRegTrashAlt
                                style={{
                                  fontSize: "17px",
                                  color: "red",
                                }}
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Scrollbar>
            </Card>
          </Stack>
        </Container>
      </Box>
      <ConfirmDialog title={t("views.redesignApkManageNew.dialogs.save.message")} open={openSaveDialog} onClose={handleCancel} onAccept={handleSave} />
      <ConfirmDialog
        title={t("views.redesignApkManageNew.dialogs.delete.message")}
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onAccept={handleDelete}
      />
    </Layout>
  );
}

export default RedesignApkManageNew;
