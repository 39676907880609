import React from "react";
import "./switch.css";
function VCPSwitch({ name, checked, onChange }) {
  return (
    <input
      name={name}
      type={"checkbox"}
      checked={checked}
      onChange={(e) => onChange(e.target.checked)}
      id={'checkbox--custom'}
    />
  );
}

export default VCPSwitch;
