import { Button, Dialog, DialogTitle, DialogActions } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

function ConfirmDialog({ open, onClose, onAccept, title }) {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"lg"} sx={{ zIndex: "10000000000" }}>
      <DialogTitle sx={{ textAlign: "center" }}>{title}</DialogTitle>

      <DialogActions>
        <Button
          variant={"outlined"}
          sx={{
            height: "38px",
            width: "193px",
            textTransform: "capitalize",
            borderRadius: "5px",
          }}
          onClick={onClose}>
          {t("components.confirmDialog.close")}
        </Button>
        <Button
          variant={"contained"}
          sx={{
            height: "38px",
            width: "193px",
            textTransform: "capitalize",
            borderRadius: "5px",
          }}
          onClick={onAccept}>
          {t("components.confirmDialog.yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
