import { Typography } from "@material-ui/core";
import { Box } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { resolveSrc } from "../../../api/endpoints";
import { useDrag } from "react-dnd";
function DragContent({ contentObj, onGetObjectValue, isOver, isMainContent, user }) {
  const [contentDragAdd, setContentDragAdd] = React.useState(false);
  const inputRef = useRef(null);
  useEffect(() => {
    // console.log('from useEffect in DragableContent=>', isMainContent)
    //    setContentDragAdd(isMainContent ? false : true)
  }, [isMainContent]);
  // const [{isDragging},drag]=useDrag(()=>({
  //     type:'image',
  //     item:{...contentObj},
  //     collect:(monitor)=>({
  //         isDragging:!!monitor.isDragging()
  //     })
  // }));
  //    !isOver && setContentDragAdd(false)
  const dragStart = (e, obj, dragableContent) => {
    // e.stopPropagation();
    // console.log('from dragstart=',inputRef.current.value)
    onGetObjectValue(obj, inputRef.current.value);
  };
  return (
    <>
      <input hidden value={isMainContent} ref={inputRef} />
      <Box
        // ref={drag}
        draggable={user.isMember ? (user.previlliages.playlist.canCreate ? true : false) : true}
        onDragStart={(e) => dragStart(e, contentObj, isMainContent)}
        sx={{
          minHeight: "70px",
          width: "98%",
          borderBottom: "1px dotted #92959E",
          display: "flex",
          cursor: "pointer",
        }}
        className={isOver && "dragging-border"}>
        {contentObj.type === "image" && (
          <Box sx={{ height: "100%", width: "30%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img src={resolveSrc(contentObj?.url)} style={{ height: "95%", width: "80%", objectFit: "cover" }} />
          </Box>
        )}
        {contentObj.type === "widget" && (
          <Box sx={{ height: "100%", width: "30%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img src={`${contentObj.widget?.widgetIcon}`} style={{ height: "95%", width: "80%", objectFit: "cover" }} />
          </Box>
        )}
        {contentObj.type === "video" && (
          <Box sx={{ height: "100%", width: "30%", borderRight: "none", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <video src={resolveSrc(contentObj?.url)} controls style={{ height: "95%", width: "80%", objectFit: "cover" }} />
          </Box>
        )}
        {contentObj.embed === "embed" && (
          <Box sx={{ height: "100%", width: "30%", borderRight: "none", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <embed src={`${contentObj.url}`} style={{ height: "95%", width: "80%", objectFit: "cover" }} />
          </Box>
        )}
        <Box sx={{ height: "100%", width: "70%", marginLeft: "10px", display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
          <Typography>{contentObj.name.substring(0, 20)}</Typography>
        </Box>
      </Box>
    </>
  );
}

export default DragContent;
