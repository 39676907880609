import React, { useMemo, useState, useEffect } from "react";
import moment from "moment/moment";

export const WeatherInfoTemplate = `
  <style>
    .weather-widget{
      background-color: {{bgColor}};
      background-image: url({{bgImg}});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      height: 100%;
      width: 100%;
      border-radius: 10px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: {{fontSize}}px;
      container-name: weather-widget;
      container-type: inline-size;
    }
    .weather-widget-header{
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .weather-widget-header h1{
        font-size: 18px; 
        margin: 20px 0 0 0;
    }
    .weather-widget-header .current-temperature{
        font-size: 32px;
    }
    .weather-widget-header h1 img{
        width: 20px;
        height: 20px;
    }
    .weather-widget-header h3{
        font-size:16px;
    }
    .weather-widget-forecast{
        margin-top: 48px;
        background-color: rgba(135,206,250,.5);
        width: 100%;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
    }
    .weather-widget-forecast .current-time{
        align-self: center;
        margin: 16px 0 32px;
        font-size: 18px;
        font-weight: 500;
    }
    .weather-widget-forecast .forecast-grid{
        display: grid;
        grid-template-rows: repeat(6, minmax(0, 1fr));
        width: 100%;
        align-items: center;
        margin: auto;
        padding: 0 16px;
    }
    .weather-widget-forecast .forecast-grid-item{
        height: 80px;
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        align-items: center;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        padding: 0 16px;
        border-radius: 16px;
    }
    .weather-widget-forecast .forecast-grid-item img{
        width: 48px;
        margin: 0 auto;
    }
    .weather-widget-forecast .forecast-grid-item .forecast-time-mobile{
        text-align: left;
        display: block;
        word-spacing: 9999999px;
    }
    .weather-widget-forecast .forecast-grid-item .forecast-time-desktop{
        display: none;
    }
    .weather-widget-forecast .forecast-grid-item .forecast-temperature{
        text-align: right;
    }
    @container weather-widget (min-width: 768px){
      .weather-widget-header h1{
        font-size:28px;
      }
      .weather-widget-header h1 img{
        width: 32px;
        height: 32px;
      }
      .weather-widget-forecast{
        background-color: rgba(135,206,250,.5);
        aspect-ratio: 3 / 1;
        width: 80%;
        margin: 60px auto 40px auto;
      }
      .weather-widget-forecast .current-time{
        margin: 16px 16px 0 0;
        align-self: flex-end;
        font-size: 24px;
      }
      .weather-widget-forecast .forecast-grid{
        grid-template-rows: none;
        grid-template-columns: repeat(6, minmax(0, 1fr));
      }
      .weather-widget-forecast .forecast-grid-item{
        height: auto;
        grid-template-columns: none;
        font-size: 24px;
        padding: 0;
      }
      .weather-widget-forecast .forecast-grid-item img{
        width: 64px;
      }
      .weather-widget-forecast .forecast-grid-item .forecast-time-mobile{
        display: none;
      }
      .weather-widget-forecast .forecast-grid-item .forecast-time-desktop{
        display: block;
      }
      .weather-widget-forecast .forecast-grid-item .forecast-temperature{
        text-align: center;
      }
    }
  </style>
  <div class="weather-widget">
    <div class="weather-widget-header">
      <h1>
        <img src="/assets/icons/weather/location-sign.svg" width="32" />
        {{formattedAddress}}
      </h1>
      <img src="/assets/icons/weather/{{weatherIcon}}.svg" width="100" height="100" />
      <h1 class="current-temperature" style="margin: 0">{{temperature}}°{{scale}}</h1>
      <div
        style="
          display: flex;
          align-items: center;
          gap: 10px;
        "
      >
        <div style=" 
          display: flex;
          align-items: center; 
          gap: 8px;
         "
        >
          <img src="/assets/icons/weather/humidity.svg" width="40" height="40" />
          <h3>{{humidity}}%</h3>
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            gap: 8px;
            margin-left: 50px;
          "
        >
          <img src="/assets/icons/weather/wind.svg" width="40" height="40" />
          <h3>{{pressure}} hPa</h3>
        </div>
        </div>
      </div>
      <div class="weather-widget-forecast">
        <div class="current-time">
          {{currentTime}}
        </div>
        <div class="forecast-grid">
         {{forecast}}
        </div>
      </div>
    </div>
`;

export const ForecastItemTemplate = `
  <div class="forecast-grid-item" style="background: {{background}};">
    <p class="forecast-time-mobile" style="margin: 8px;">
        {{datetimeMobile}}
    </p>
    <p class="forecast-time-desktop" style="margin: 8px;">
        {{datetimeDesktop}}
    </p>
    <img src="/assets/icons/weather/{{weatherIcon}}.svg" />
    <p class="forecast-temperature" style="margin: 8px;">
      <span>{{maxTemperature}}°</span> - <span>{{minTemperature}}°</span>
    </p>
  </div>
`;

export const weatherThemeSettings = (themeValue) => {

  const images = {
    bg1: `/images/BG_1.jpeg`,
    bg2: `/images/BG_2.jpeg`,
  };

  switch (themeValue) {
    case "#808080":
      return { bgImg: images.bg1, bgColor: "#224e83" };
    case "#90EE90":
      return { bgImg: images.bg2, bgColor: "#6995c8" };
    default:
      return null;
  }
};

const API_KEY = "b589b102afcfbd7027b38ea5b816f17a";

export const getWeatherUrl = (lat, lng, lang, units) => `//api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lng}&appid=${API_KEY}&lang=${lang}&units=${units}`;

export const getForecastUrl = (lat, lng, lang, units) => `//api.openweathermap.org/data/2.5/forecast?lat=${lat}&lon=${lng}&appid=${API_KEY}&lang=${lang}&units=${units}`;

const getWeather = async (lat, lng, lang, units) => {
  const currentWeatherURL = getWeatherUrl(lat, lng, lang, units);
  const currentWeatherResponse = await fetch(currentWeatherURL);
  const currentWeatherData = await currentWeatherResponse.json();

  const forecastURL = getForecastUrl(lat, lng, lang, units);
  const forecastResponse = await fetch(forecastURL);
  const forecastData = await forecastResponse.json();

  forecastData.list.unshift({
    dt_txt: moment().format('YYYY-MM-DD 15:00:00'),
    ...currentWeatherData,
  });

  console.log(forecastData);

  return {
    current: currentWeatherData,
    forecast: forecastData
  };

}

const CardWeather = ({ item, style }) => {

  const [weatherData, setWeatherData] = useState(null);
  useEffect(() => {
    getWeather(
      item.weather?.location?.lat ?? '40.730610',
      item.weather?.location?.lng ?? '-73.935242',
      item.weather?.language ?? 'en',
      item.weather?.scale == 'F' ? 'imperial' : 'metric'
    ).then(data => setWeatherData(data));
  }, [item?.weather?.location, item?.weather?.language, item?.weather?.scale]);


  const previewHTML = useMemo(() => {
    if (weatherData == null)
      return "";

    const { weather: weatherSettings } = item;
    const { current: currentWeather, forecast: forecastWeather } = weatherData;

    const theme = weatherThemeSettings(weatherSettings.theme);

    //Find newest forecast but not more that 15
    let forecastHTML = forecastWeather.list.reduce((forecast, item) => {
      const itemMoment = moment(item.dt_txt);
      const dayIndex = forecast.findIndex(i => {
        const iMoment = moment(i.dt_txt);
        return iMoment.day() == itemMoment.day();
      });

      if (dayIndex < 0)
        forecast.push(item);

      const forecastDayMoment = moment(forecast[dayIndex]);

      if (itemMoment.hour() > forecastDayMoment.hour() && itemMoment.hour() <= 15)
        forecast[dayIndex] = item;

      return forecast;
    }, []).map((weather) => {
      return ForecastItemTemplate
        .replace("{{datetimeMobile}}", moment(weather.dt_txt).startOf('day').isSame(moment().startOf('day')) ? "Today" : moment(weather.dt_txt).format('DD dddd'))
        .replace("{{datetimeDesktop}}", moment(weather.dt_txt).startOf('day').isSame(moment().startOf('day')) ? "Today" : moment(weather.dt_txt).format('dd DD'))
        .replace("{{weatherIcon}}", currentWeather.weather[0].icon.replace(/[dn]/, ''))
        .replace("{{maxTemperature}}", Math.ceil(weather.main.temp_max))
        .replace("{{minTemperature}}", Math.floor(weather.main.temp_min))
        .replace("{{background}}", moment(weather.dt_txt).startOf('day').isSame(moment().startOf('day')) ? "rgba(0,162,232,0.5)" : "none");
    }).join('')

    let fontSizes = {
      S: 12,
      L: 14,
      XL: 16,
      XXL: 18
    };

    let html = WeatherInfoTemplate
      .replace("{{bgColor}}", theme.bgColor)
      .replace("{{bgImg}}", theme.bgImg)
      .replace("{{fontSize}}", fontSizes[weatherSettings?.fontSize ?? 'L'])
      .replace("{{forecast}}", forecastHTML)
      .replace("{{formattedAddress}}", weatherSettings?.location?.formattedAddress ?? currentWeather.name)
      .replace("{{weatherIcon}}", currentWeather.weather[0].icon.replace(/[dn]/, ''))
      .replace("{{temperature}}", Math.round(currentWeather.main.temp))
      .replace("{{humidity}}", currentWeather.main.humidity)
      .replace("{{pressure}}", currentWeather.main.pressure)
      .replace("{{currentTime}}", moment().format('hh:mm A dddd, MMM DD'))
      .replaceAll("{{scale}}", weatherSettings.scale)

    return html;
  }, [weatherData, item]);

  return (
    <div style={style} dangerouslySetInnerHTML={{ __html: previewHTML }} />
  );
};

export default CardWeather;