import { Container, Grid, Box, Table, TableHead, TableBody, TableCell, TableRow, TableContainer } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Layout from "../../components/layout/Layout";
import { SettingsBackupRestore } from "@mui/icons-material";
import { getContentById, restoreFile } from "../../redux/actions/Content";
import RestoreFileTable from "./components/RestoreFileTable";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import { useSettings } from "src/hooks/use-settings";

function RedesignRestoreFile({ user }) {
  const { t } = useTranslation();
  const settings = useSettings();

  const dispatch = useDispatch();
  const { content } = useSelector(
    (state) => ({
      content: state.Content.content,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getContentById(user?._id));
  }, []);

  return (
    <>
      <Layout title={t("views.redesignRestoreFile.title")} user={user}>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8,
          }}>
          <Container maxWidth={settings.stretch ? false : "xl"}>
            <Stack spacing={4}>
              <Stack direction="row" justifyContent="space-between" spacing={4}>
                <Typography variant="h4">{t("views.redesignRestoreFile.restoreFiles")}</Typography>
              </Stack>
              <Card>
                <RestoreFileTable items={content} />
              </Card>
            </Stack>
          </Container>
        </Box>
      </Layout>
    </>
  );
}

export default RedesignRestoreFile;
