import { useCallback, useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, FormControl, InputLabel, Select, MenuItem, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from "@mui/material";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { removeGroup, assignGroup } from "src/redux/actions/User";
import GroupIcon from "@mui/icons-material/Group";

const UsersChangeGroupDialog = ({ open, onClose, groups, selectedUsers, action }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedGroups, setSelectedGroups] = useState([]);
  useEffect(() => setSelectedGroups([]), [open]);

  const handleGroupsChange = useCallback((event, itemsId) => {
    setSelectedGroups(itemsId);
  }, []);

  const handleSaveAction = useCallback(async () => {
    const modalAction = action === "ADD" ? assignGroup : removeGroup;

    if (selectedGroups.length <= 0) return onClose?.();

    try {
      await Promise.all(selectedGroups.map(async (groupId) =>
        dispatch(modalAction({ groupId, userIds: selectedUsers }))
      ));
      action === "ADD" && toast.success(t("views.usersChangeGroupDialog.assignSuccess"));
      action === "DELETE" && toast.success(t("views.usersChangeGroupDialog.deleteSuccess"));
    } catch {
      action === "ADD" && toast.error(t("views.usersChangeGroupDialog.assignError"));
      action === "DELETE" && toast.error(t("views.usersChangeGroupDialog.deleteError"));
    } finally {
      onClose?.();
    }
  }, [selectedUsers, selectedGroups, action, onClose, dispatch, t]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
        <GroupIcon color="primary" sx={{ fontSize: 24, marginRight: "8px" }} />
        {t("views.usersChangeGroupDialog.title", { action: action === "ADD" ? t("views.usersChangeGroupDialog.add") : t("views.usersChangeGroupDialog.delete") })}
      </DialogTitle>
      <DialogContent sx={{ width: "320px" }}>
        <InputLabel>{t("views.usersChangeGroupDialog.groups")}</InputLabel>
        <SimpleTreeView checkboxSelection multiSelect selectedItems={selectedGroups} onSelectedItemsChange={handleGroupsChange}>
          {groups
            .filter((g) => !g.parent)
            .map((group) => (
              <GroupTree key={group._id} {...group} groups={groups} />
            ))}
        </SimpleTreeView>
        <DialogActions sx={{ marginTop: "60px" }}>
          <Button onClick={onClose}>{t("views.usersChangeGroupDialog.cancel")}</Button>
          <Button onClick={handleSaveAction} variant={"contained"}>
            {t("views.usersChangeGroupDialog.save")}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default UsersChangeGroupDialog;

const GroupTree = ({ groups, name, _id }) => {
  return (
    <TreeItem itemId={_id} label={name}>
      {groups
        .filter((g) => g?.parent && g.parent === _id)
        .map((group) => (
          <GroupTree key={group._id} {...group} groups={groups} />
        ))}
    </TreeItem>
  );
};
