import { useEffect, useState, useMemo } from "react";
import Card from "@mui/material/Card";
import { APIProvider, Map, Marker, AdvancedMarker, useMapsLibrary, useMap } from "@vis.gl/react-google-maps";
import { useTranslation } from "react-i18next";

export const CustomerMap = (props) => {
  const { user } = props;
  const [markerLocation, setMarkerLocation] = useState(null);
  const { t } = useTranslation();

  const userLocation = useMemo(() => {
    if (!user?.location) return null;

    const [lat, lng] = user.location.split(",");
    return {
      lat: parseFloat(lat),
      lng: parseFloat(lng),
    };
  }, [user]);

  return (
    <Card>
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
        <Map mapId={"bf51a910020fa25a"} disableDefaultUI={true} defaultZoom={6} style={{ height: "300px" }}>
          {markerLocation && <Marker position={markerLocation} title="User address location" />}
          {userLocation && (
            <AdvancedMarker position={userLocation} title="User location">
              <div
                style={{
                  width: 16,
                  height: 16,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  background: "#1dbe80",
                  border: "2px solid #0e6443",
                  borderRadius: "50%",
                  transform: "translate(-50%, -50%)",
                }}></div>
            </AdvancedMarker>
          )}
        </Map>
        <Geocoding user={user} setMarkerLocation={setMarkerLocation} />
        <Center points={[markerLocation, userLocation]} />
      </APIProvider>
    </Card>
  );
};

const Geocoding = ({ setMarkerLocation, user }) => {
  const geocodingLibrary = useMapsLibrary("geocoding");
  const { t } = useTranslation();

  useEffect(() => {
    if (!geocodingLibrary || !user) return;

    const geocoder = new geocodingLibrary.Geocoder();
    const address = `${user.country}, ${user.state}, ${user.address1}, ${user.address2}`;

    geocoder.geocode(
      {
        address: address,
      },
      (results, status) => {
        if (status === "OK" && results[0]) {
          setMarkerLocation(results[0].geometry.location);
        } else {
          console.log(t("views.customerMap.geocodeError", { address, status }));
        }
      }
    );
  }, [geocodingLibrary, user, t]);
};

const Center = ({ points }) => {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    const mapPoints = points.filter(Boolean);

    if (mapPoints.length == 1) {
      map.setZoom(6);
      map.setCenter(mapPoints[0]);
      return;
    }

    var bounds = new window.google.maps.LatLngBounds();
    for (const point of mapPoints) bounds.extend(point);

    map.fitBounds(bounds);
    map.panToBounds(bounds);
  }, [map, points]);
};
