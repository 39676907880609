import { TroubleshootSharp } from "@mui/icons-material";
import { updateScreen } from "../actions/Screen";
import {
  DELETE_SCREEN,
  DELETE_SCREEN_REQUEST,
  GET_ALL_SCREEN_SUCCESS,
  GET_ONE_SCREEN,
  SCREEN_REGISTER,
  SCREEN_REGISTER_FLAG_OFF,
  SCREEN_STATUS_ACTIVE,
  SCREEN_STATUS_DEACTIVE,
  EDIT_SCREEN,
  SCREEN_MOVE_TO_FOLDER,
  SCREEN_PAIRED,
  SCREEN_UNPAIRED
} from "../constant";

const initial = {
  screens: [],
  screen: {},
  isFindScreen: false,
  updatedScreen: false,
  activeScreens: 0,
  deactiveScreens: 0,
  isScreenRegistered: false,
  mac: "",
  delMac: "",
  isDeleted: false,
  isDeleting: false,
  isPaired: false,
  isUnpaired: false
};
export const ScreenReducer = (state = initial, action) => {
  switch (action.type) {
    case SCREEN_REGISTER:
      let regiScreens = {};
      const now_2 = new Date().getTime();
      let till_2 = 90000;
      //  let distance=now + till;
      //  action.payload.forEach(row=> {
      //  if(row.isActive){
      if (now_2 > +action.payload?.lastActive + till_2) {
        regiScreens = { ...action.payload, onlineStatus: false }
        // offline_2+=1;
        state.deactiveScreens += 1;
      }
      else {
        regiScreens = { ...action.payload, onlineStatus: true }
        //  online_2+=1;
        state.activeScreens += 1;
      }
      return {
        ...state,
        screens: [...state.screens, regiScreens],
        activeScreens: state.activeScreens,
        deactiveScreens: state.deactiveScreens,
        isScreenRegistered: true,
        mac: action.payload.mac,
        delMac: action.payload.mac
      };
    case EDIT_SCREEN:
      const __findIndex = state.screens.findIndex(s => s._id === action.payload._id);
      let updatedEditScreens = {};
      const now_1 = new Date().getTime();
      let till_1 = 90000;
      //  let distance=now + till;
      //  action.payload.forEach(row=> {
      //  if(row.isActive){
      if (now_1 > +action.payload?.lastActive + till_1) {
        updatedEditScreens = { ...action.payload, onlinesStatus: false }
        // offline_1+=1;
        state.deactiveScreens += 1;
      }
      else {
        updatedEditScreens = { ...action.payload, onlinesStatus: true }
        //  online_1+=1;
        state.activeScreens += 1;
      }
      // }
      //  })
      state.screens[__findIndex] = { ...updatedEditScreens }
      return {
        ...state,
        screens: state.screens,
        updatedScreen: true,
        delMac: action.payload.mac,
        activeScreens: state.activeScreens,
        deactiveScreens: state.deactiveScreens
      }
    case SCREEN_REGISTER_FLAG_OFF:
      return {
        ...state,
        isScreenRegistered: false,
        isDeleted: false,
        isDeleting: false,
        isFindScreen: false,
        updatedScreen: false,
        isPaired: false,
        isUnpaired: false
      };
    case GET_ALL_SCREEN_SUCCESS:
      // console.log(action.payload.map(s=>s.isActive===true))
      // console.log('get your screen->',state.screens.find(s=>s.mac==='3eec16aaae46d1ac'))
      let updatedScreens = [];
      const now = new Date().getTime();
      let till = 90000;
      let online = 0;
      let offline = 0;
      //  let distance=now + till;
      action.payload.forEach(row => {
        if (now > +row?.lastActive + till) {
          updatedScreens.push({ ...row, onlinesStatus: false })
          offline += 1;
        } else {
          updatedScreens.push({ ...row, onlinesStatus: true })
          online += 1;
        }
      })

      //  console.log(updatedScreens.filter(c=>c.playlist!==undefined));
      return {
        ...state,
        screens: updatedScreens,
        activeScreens: online,
        deactiveScreens: offline
        // screens: [...action.payload],
        // activeScreens:[...action.payload.filter(s=>s.playlist!==undefined)].length,
        // deactiveScreens:[...action.payload.filter(s=>s.playlist===undefined)].length,
      };
    case SCREEN_STATUS_ACTIVE:
      const activeStatusIndex = state.screens.findIndex(
        (s) => s.mac === action.payload
      );
      // console.log('from active screen=>',action.payload)
      state.screens[activeStatusIndex] = { ...state.screens[activeStatusIndex], onlineStatus: true };
      return {
        ...state,
        screens: state.screens,
      };
    case SCREEN_STATUS_DEACTIVE:
      const DeactiveStatusIndex = state.screens.findIndex(
        (s) => s.mac === action.payload
      );
      // console.log('from inactive screen=>',action.payload)
      state.screens[DeactiveStatusIndex] = { ...state.screens[DeactiveStatusIndex], onlineStatus: false };
      return {
        ...state,
        screens: state.screens,
      };
    case DELETE_SCREEN_REQUEST:
      return {
        ...state,
        isDeleting: true,
      };
    case DELETE_SCREEN:
      const m = state.screens.find((s) => s._id === action.payload._id);
      // const _findIndexOfUnpairedScreen=state.screens.findIndex(s=>s._id===action.payload._id);
      // state.screens[_findIndexOfUnpairedScreen]={...action.payload};
      return {
        ...state,
        delMac: m.mac,
        isDeleted: true,
        screens: state.screens
      };
    case SCREEN_PAIRED:
      const _findMacForPairedScreen = state.screens.find((s) => s._id === action.payload._id);
      const _findIndexOfPairedScreen = state.screens.findIndex(s => s._id === action.payload._id);
      state.screens[_findIndexOfPairedScreen] = { ...action.payload };
      return {
        ...state,
        delMac: _findMacForPairedScreen.mac,
        isPaired: true,
        screens: state.screens
      }
    case SCREEN_UNPAIRED:
      const _findMacForUnPairedScreen = state.screens.find((s) => s._id === action.payload._id);

      return {
        ...state,
        delMac: _findMacForUnPairedScreen.mac,
        screens: state.screens.filter(s => s._id !== action.payload._id),
        isUnpaired: true
      }
    case GET_ONE_SCREEN:
      return {
        ...state,
        isFindScreen: true,
        screen: { ...state.screens.find(_screen => _screen._id === action.payload) }
      }
    case SCREEN_MOVE_TO_FOLDER:
      const __screen_move_index = state.screens.findIndex(s => s._id === action.payload._id);
      state.screens[__screen_move_index] = { ...action.payload }
      return {
        ...state,
        screens: state.screens
      }

    default:
      return state;
  }
};
