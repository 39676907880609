import React from "react";
import { useTranslation } from "react-i18next";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

function FullScreens() {
  const { t } = useTranslation();
  const handle = useFullScreenHandle();
  const divRef = React.useRef(null);

  const toggleFullScreen = () => {
    if (divRef.current) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        divRef.current.requestFullscreen().catch((err) => {
          console.log(err);
        });
      }
    }
  };

  return (
    <>
      <FullScreen handle={handle}>
        <div
          ref={divRef}
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "white",
          }}>
          {t("views.fullScreen.content")}
          {/* Content of the full-screen div */}
        </div>
      </FullScreen>
      <button onClick={handle.enter}>{t("views.fullScreen.goFullScreen")}</button>
    </>
  );
}

export default FullScreens;
