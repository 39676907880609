import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import PropTypes from "prop-types";
import File04Icon from "@untitled-ui/icons-react/build/esm/File04";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

import { Logo } from "src/components/logo";
import { RouterLink } from "src/components/router-link";
import { Scrollbar } from "src/components/scrollbar";
import { usePathname } from "src/hooks/use-pathname";
import { paths } from "src/paths";
import { TenantSwitch } from "../tenant-switch";
import { MobileNavSection } from "./mobile-nav-section";

const MOBILE_NAV_WIDTH = 280;

const useCssVars = (color) => {
  // ... (keep the existing useCssVars function as is)
};

export const MobileNav = (props) => {
  const { t } = useTranslation();
  const { color = "evident", open, onClose, sections = [] } = props;
  const pathname = usePathname();
  const cssVars = useCssVars(color);

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          ...cssVars,
          backgroundColor: "var(--nav-bg)",
          color: "var(--nav-color)",
          width: MOBILE_NAV_WIDTH,
        },
      }}
      variant="temporary">
      <Scrollbar
        sx={{
          height: "100%",
          "& .simplebar-content": {
            height: "100%",
          },
          "& .simplebar-scrollbar:before": {
            background: "var(--nav-scrollbar-color)",
          },
        }}>
        <Stack sx={{ height: "100%" }}>
          <Stack alignItems="center" direction="row" spacing={2} sx={{ p: 3 }}>
            <Box
              component={RouterLink}
              href={paths.index}
              sx={{
                borderColor: "var(--nav-logo-border)",
                borderRadius: 1,
                borderStyle: "solid",
                borderWidth: 1,
                display: "flex",
                height: 40,
                p: "4px",
                width: 40,
              }}>
              <Logo />
            </Box>
            <TenantSwitch sx={{ flexGrow: 1 }} />
          </Stack>
          <Stack
            component="nav"
            spacing={2}
            sx={{
              flexGrow: 1,
              px: 2,
            }}>
            {sections.map((section, index) => (
              <MobileNavSection items={section.items} key={index} pathname={pathname} subheader={section.subheader} />
            ))}
          </Stack>
          <Box sx={{ p: 3 }}>
            <Typography color="neutral.400" variant="subtitle1">
              {t("layout.mobileNav.needHelp")}
            </Typography>
            <Typography color="neutral.400" sx={{ mb: 2 }} variant="body2">
              {t("layout.mobileNav.checkDocs")}
            </Typography>
            <Button
              component="a"
              fullWidth
              href={paths.docs}
              startIcon={
                <SvgIcon>
                  <File04Icon />
                </SvgIcon>
              }
              target="_blank"
              variant="contained">
              {t("layout.mobileNav.documentation")}
            </Button>
          </Box>
        </Stack>
      </Scrollbar>
    </Drawer>
  );
};

MobileNav.propTypes = {
  color: PropTypes.oneOf(["blend-in", "discrete", "evident"]),
  onClose: PropTypes.func,
  open: PropTypes.bool,
  sections: PropTypes.array,
};
