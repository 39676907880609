import { Container, Box } from "@mui/material";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Route, Routes, useNavigate } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import { getFolders } from "../../redux/actions/FileFolder";
import FolderComponent from "./component/FolderComponent";
import FolderItem from "./component/FolderItem";
import FileItem from "./component/FileItem";
import Subbar from "./component/Subbar";

function Folder() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentFolder, userFolders, files } = useSelector(
    (state) => ({
      currentFolder: state.FileFolder.currentFolder,
      userFolders: state.FileFolder.userFolders.filter((f) => f.parent === "root"),
      files: state.Content.content.filter((c) => c.folderId === "root"),
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getFolders());
  }, [dispatch]);

  return (
    <Layout title={t("views.folder.fileManagement")}>
      <Container maxWidth={"lg"} sx={{ background: "#ffffff" }}>
        <Subbar />
        <Box sx={{ height: "50vh", width: "100%", display: "flex", gap: "10px" }}>
          {userFolders.map((folder, index) => (
            <FolderItem key={index} folder={folder} />
          ))}
        </Box>
        <Box sx={{ height: "50vh", width: "100%", display: "flex", gap: "10px" }}>
          {files.map((file, index) => (
            <FileItem key={index} files={file} />
          ))}
        </Box>
      </Container>
    </Layout>
  );
}

export default Folder;
