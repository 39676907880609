import { api } from "../../api/endpoints";
import { GET_LOGS_SUCCESS } from "../constant";

export const getLogs = (obj) => {
  console.log(obj);
  return async (dispatch) => {
    const response = await api.post(`/log/`, obj);
    if (response.status === 200) {
      dispatch({
        type: GET_LOGS_SUCCESS,
        payload: response.data,
      });
    }
  };
};
