import { useMemo, useEffect } from "react";
import { useFormContext } from "react-hook-form-mui";

const UserInitValues = ({ initValues }) => {
  const { setValue } = useFormContext();

  useEffect(() => {
    Object.entries(initValues).forEach(([name, value]) => setValue(name, value));
  }, [initValues, setValue]);
};

export default UserInitValues;
