import { Box } from '@material-ui/core';
import React from 'react'
import './circularbar.css';
function Circularbar({ height, width }) {
  return (
    <Box className='minLoader'>

    </Box>
  )
}

export default Circularbar