import {
  //CHANGE_GROUP,
  CREATE_GROUP,
  GET_GROUPS,
  //SET_GROUP_ID,
  GROUP_FLAG_OFF,
  GROUP_DELETE,
  EDIT_GROUP
} from "../constant";

const initial = {
  isLoading: false,
  //currentGroup: "root",
  userGroups: [],
  //userFiles: [],
  //adminGroups: [],
  //adminFiles: [],
  isGroupCreated: false,
  isGroupDeleted: false,
  isGroupUpdated: false
};

export const GroupReducer = (state = initial, action) => {
  switch (action.type) {
    case GROUP_FLAG_OFF:
      return {
        ...state,
        isGroupCreated: false,
        isGroupDeleted: false,
        isGroupUpdated: false
      };
    case CREATE_GROUP:
      return {
        ...state,
        userGroups: [...state.userGroups, action.payload],
        isGroupCreated: true,
      };
    case GET_GROUPS:
      return {
        ...state,
        userGroups: [...action.payload],
      };
    /* 
  case SET_GROUP_ID:
    // nie potrzebny id tutaj
    return {
      ...state,
      currentGroup: action.payload,
    };
  case CHANGE_GROUP:
    return {
      ...state,
      currentGroup: action.payload,
    };
    */
    case GROUP_DELETE:
      return {
        ...state,
        userGroups: state.userGroups.filter(f => f._id !== action.payload),
      }
    case EDIT_GROUP:
      const updatedGroup = action.payload;

      return {
        ...state,
        userGroups: state.userGroups.map(g => g._id === updatedGroup._id ? updatedGroup : g),
      }
    default:
      return state;
  }
};
