import PropTypes from "prop-types";
import LeftIndent01Icon from "@untitled-ui/icons-react/build/esm/LeftIndent01";
import RightIndent01Icon from "@untitled-ui/icons-react/build/esm/RightIndent01";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

export const OptionsDirection = (props) => {
  const { t } = useTranslation();
  const options = [
    {
      label: t("components.optionsDirection.leftToRight"),
      value: "ltr",
      icon: (
        <SvgIcon fontSize="small">
          <LeftIndent01Icon />
        </SvgIcon>
      ),
    },
    {
      label: t("components.optionsDirection.rightToLeft"),
      value: "rtl",
      icon: (
        <SvgIcon fontSize="small">
          <RightIndent01Icon />
        </SvgIcon>
      ),
    },
  ];

  const { onChange, value } = props;

  return (
    <Stack spacing={1}>
      <Typography color="text.secondary" variant="overline">
        {t("components.optionsDirection.orientation")}
      </Typography>
      <Stack alignItems="center" direction="row" flexWrap="wrap" gap={2}>
        {options.map((option) => (
          <Chip
            icon={option.icon}
            key={option.label}
            label={option.label}
            onClick={() => onChange?.(option.value)}
            sx={{
              borderColor: "transparent",
              borderRadius: 1.5,
              borderStyle: "solid",
              borderWidth: 2,
              ...(option.value === value && {
                borderColor: "primary.main",
              }),
            }}
          />
        ))}
      </Stack>
    </Stack>
  );
};

OptionsDirection.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOf(["ltr", "rtl"]),
};
