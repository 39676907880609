import { memo } from 'react';
import { Box } from '@mui/material';

const WidgetContentWrapper = memo(({ widgetData, children }) => {

    let wrapperStyle = {
        margin: `${widgetData.style.padding * 8 - 32}px ${widgetData.style.padding * 6 - 24}px` //Remove padding using negative margin
    };

    //Attach content with proportion
    if (widgetData.style.proportions !== 'auto'){
        const [w, h] = widgetData.style.proportions.split(':');
        
        wrapperStyle = {
            ...wrapperStyle,
            aspectRatio: `${w}/${h}`,
            '& .MuiCardContent-root': {
                height: '100%'
            }
        };
    
    }

    return (
        <Box sx={wrapperStyle}>
            {children}
        </Box>
    );

});

export default WidgetContentWrapper;