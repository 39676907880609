import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Menu,
  MenuItem,
  InputLabel,
  Select,
  TextField,
  FormControl,
  Checkbox,
  Slider,
  Input,
  InputBase,
} from "@mui/material";
import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import Layout from "../../../components/layout/Layout";
import VCPSwitch from "../../../components/switch/VCPSwitch";
import TableBodies from "../../../components/table/TableBodies";
import { editFolder, folderDelete, folderFlagOff, getFolders } from "../../../redux/actions/FileFolder";
import FolderIcon from "@mui/icons-material/Folder";
import {
  unPairedScreen,
  pairedScreen,
  getAllScreenById,
  getOneScreen,
  registerScreen,
  registerScreenFlagOff,
  updateScreen,
  screenMoveToFolder,
} from "../../../redux/actions/Screen";
import ConfirmationModel from "../../../components/confirmmodel/ConfirmationModel";
import { FaTrash } from "react-icons/fa";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Circularbar from "../../../components/circularbar/Circularbar";
import Player from "../components/Player";
import { useParams } from "react-router-dom";
import UserProfileComponent from "../../../components/userprofile/UserProfileComponent";
import { useTranslation } from "react-i18next";

// import { isButtonElement } from "react-router-dom/dist/dom";
function UserScreens({ socketRef, user }) {
  const { t } = useTranslation();

  const params = useParams();
  const locationRef = useRef(null);
  const dispatch = useDispatch();
  // const { playlists } = useSelector((state) => state.Playlist);
  const {
    // screens,
    delMac,
    isDeleted,
    isDeleting,
    screen,
    isFindScreen,
    updatedScreen,
    isPaired,
    isUnpaired,
    isScreenRegistered,
  } = useSelector((state) => state.Screen);
  const { currentFolder, screenFolders, screens, allScreens, isFolderCreated, isFolderDeleted, playlists, userInfo, screenLength } = useSelector(
    (state) => ({
      currentFolder: state.FileFolder.currentFolder,
      // allFolders:state.FileFolder.userFolders.filter(f=>f.folderFor==='screen'),
      screenFolders: state.FileFolder.userFolders.filter((f) => f.folderFor === "screen"),
      screens: state.Screen.screens.filter((s) => s?.subuser?._id == params.id),
      // screens:state.Screen.screens.filter(s=>s.folderId==='root'),
      allScreens: state.Screen.screens,
      isFolderCreated: state.FileFolder.isFolderCreated,
      isFolderDeleted: state.FileFolder.isFolderDeleted,
      playlists: state.Playlist.playlists,
      userInfo: state.User.users.filter((u) => u._id == params.id),
      screenLength: state.Screen.screens.filter((s) => s?.subuser?._id == params.id).length,
    }),
    shallowEqual
  );
  const [oneScreen, setOneScreen] = useState({});
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [rowId, setRowId] = useState("");
  const macRef = useRef();
  const [openCreateScreen, setOpenCreateScreen] = useState(false);
  const [type, setType] = useState("");
  const [playlistId, setPlaylistId] = useState("");
  const [oriantation, setOriantation] = useState("");
  const [syncPlay, setSyncPlay] = useState(false);
  const [code, setCode] = useState("");
  const [showDownloadStatus, setShowDownloadStatus] = useState(false);
  const [preloadAssetPlaylist, setPreloadAssetPlaylist] = useState(false);
  const [showOfflineIndicator, setShowOfflineIndicator] = useState(false);
  const [playBackControl, setPlayBackControl] = useState(false);
  const [brightness, setBrightness] = useState(0);
  const [volume, setVolume] = useState(0);
  const [mute, setMute] = useState(false);
  const [location, setLocation] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [colorCode, setColorCode] = useState("");
  const [backgroundType, setBackgroundType] = useState("");
  const [openPairScreenModel, setOpenPairScreenModel] = useState(false);
  const [openImageModel, setOpenImageModel] = useState(false);
  const [screenId, setScreenId] = useState("");
  const [folderVal, setFolderVal] = useState("");
  const [folderName, setFolderName] = useState("");
  const [ids, setIds] = useState("");
  const [openMoveModel, setOpenMoveModel] = useState(false);
  const [openCreateModel, setOpenCreateModel] = useState(false);
  const [openPairedModel, setOpenPairedModel] = useState(false);
  const [openFolderEditModel, setOpenFolderEditModel] = useState(false);
  const [player, setPlayer] = useState({});
  const [openPlayer, setOpenPlayer] = useState(false);
  const [intervalID, setIntervalID] = useState(null);
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  // const [playlistId,setPlaylistId]=useState('');
  useEffect(() => {
    dispatch(getAllScreenById(params.id));
    dispatch(getFolders(params.id));
  }, [params.id]);
  // useEffect(()=>{
  //   let arr = [1, 2, 3, 4, 5];
  //   let index1=arr.indexOf(2);
  //   let index2=arr.indexOf(4);
  //   console.log(index1,index2)
  //   arr.splice(index1,1,4);
  //   arr.splice(index2,1,2);
  //   console.log('sorted=>',arr)
  // },[])
  useEffect(() => {
    if (isScreenRegistered) {
      toast.success(t("views.userScreens.screenRegistered"));
      dispatch(getAllScreenById(params.id));
      setOpenPairScreenModel(false);
      emitOnAddScreen();
      dispatch(registerScreenFlagOff());
    }
    if (isPaired) {
      toast.success(t("views.userScreens.screenPaired"));
      dispatch(registerScreenFlagOff());
    }
    if (isUnpaired) {
      toast.success(t("views.userScreens.screenUnpaired"));
      dispatch(registerScreenFlagOff());
    }
    if (isFindScreen) {
      console.log(screen);
      setCode(screen?.screenCode);
      setDeviceName(screen?.deviceName);
      setOriantation(screen?.oriantation);
      setSyncPlay(screen?.syncPlay);
      setBrightness(screen?.brightness);
      setVolume(screen?.volume);
      setType(screen?.type);
      setMute(screen?.mute);
      setLocation(screen?.location);
      setShowDownloadStatus(screen?.showDownloadStatus);
      setPlayBackControl(screen?.playBackControl);
      setPreloadAssetPlaylist(screen?.preloadAssetPlaylist);
      setShowOfflineIndicator(screen?.showOfflineIndicator);
      setPlaylistId(screen?.playlist?._id);
      setOpenCreateScreen(true);
      dispatch(registerScreenFlagOff());
    }
    if (updatedScreen) {
      toast.success(t("views.userScreens.screenUpdated"));
      setOpenCreateScreen(false);
      emitOnAddScreen();
      dispatch(registerScreenFlagOff());
    }
    if (isFolderCreated) {
      toast.success(t("views.userScreens.folderCreated"));
      dispatch(folderFlagOff());
    }
    if (isFolderDeleted) {
      toast.success(t("views.userScreens.folderDeleted"));
      dispatch(folderFlagOff());
    }
  }, [isPaired, isUnpaired, isFindScreen, updatedScreen, isFolderCreated, isFolderDeleted, isScreenRegistered]);
  const editScreen = () => {
    dispatch(
      updateScreen(screen._id, {
        deviceName,
        screenCode: code,
        oriantation,
        syncPlay,
        brightness,
        volume,
        type,
        playlistId,
        location,
        showDownloadStatus,
        showOfflineIndicator,
        playBackControl,
        preloadAssetPlaylist,
        mute,
        backgroundType: {
          type: backgroundType,
          value: colorCode,
        },
        user: params.id,
        subuser: params.id,
        role: "user",
        lat,
        lng,
      })
    );
  };
  const emitOnAddScreen = () => {
    const plylist = playlists.find((p) => p._id === playlistId);
    console.log(plylist);
    for (const p in plylist?.screen_mac) {
      socketRef?.current.emit("screen_added", {
        detail: { mac: plylist?.screen_mac[p]?.mac },
      });
      // let id = setTimeout(() => {
      //   socketRef?.current.emit("screen_added", {
      //     detail: { mac: plylist?.screen_mac[p].mac },
      //   });
      //   clearTimeout(id);
      // }, 2000);
    }
  };
  const unPaired = (id, mac) => {
    setRowId(id);
    setOpenDeleteModel(true);
  };
  const confirmUnpaired = () => {
    dispatch(unPairedScreen(rowId));
    emitOnDeleteScreen();
    setOpenDeleteModel(false);
  };
  const paired = (id, mac) => {
    setRowId(id);
    setOpenPairedModel(true);
  };
  const confirmPaired = () => {
    dispatch(pairedScreen(rowId));
    emitOnDeleteScreen();
    setOpenPairedModel(false);
  };
  const emitOnDeleteScreen = () => {
    const id = setTimeout(() => {
      socketRef?.current.emit("screen_deleted", {
        detail: { mac: macRef.current.value },
      });
      clearTimeout(id);
    }, 2000);
  };
  const screenUpdate = (id) => {
    dispatch(getOneScreen(id));
  };

  const pairScreen = () => {
    const isSameNameScreen = allScreens.filter((s) => s.deviceName === deviceName);
    console.log(isSameNameScreen);
    if (isSameNameScreen.length > 0) {
      toast.error(t("views.userScreens.sameNameScreenError"));
    } else {
      dispatch(
        registerScreen(code, {
          playlistId,
          oriantation,
          deviceName,
          type,
          syncPlay,
          showDownloadStatus,
          showOfflineIndicator,
          preloadAssetPlaylist,
          playBackControl,
          brightness,
          volume,
          mute,
          location,
          backgroundType: {
            type: backgroundType,
            value: colorCode,
          },
          user: params.id,
          subuser: params.id,
          role: "user",
          folderId: currentFolder,
          lat,
          lng,
        })
      );

      setPlaylistId("");
      setOriantation("");
      setDeviceName("");
      setType("");
      setSyncPlay(false);
      setShowDownloadStatus(false);
      setShowOfflineIndicator(false);
      setPreloadAssetPlaylist(false);
      setPlayBackControl(false);
      setBrightness(0);
      setVolume(0);
      setMute(false);
      setLocation("");
    }
  };

  const onScreenMove = (id) => {
    setScreenId(id);
    setOpenMoveModel(true);
  };
  const moveOnFolder = () => {
    dispatch(screenMoveToFolder({ id: screenId, folderId: folderVal }));
    setFolderVal("");
    setOpenMoveModel(false);
  };
  const onScreenFolderDelete = (id) => {
    const childrenscreenfolders = screenFolders.filter((f) => f.parent === id);
    const childrenscreens = allScreens.filter((s) => s.folderId === id);
    console.log(childrenscreenfolders, childrenscreens);
    if (childrenscreenfolders.length == 0 && childrenscreens.length == 0) {
      setIds(id);
      setOpenCreateModel(true);
    } else {
      toast.success("This group contains screens. Can not be delete.");
    }
  };
  const onConfirm = () => {
    setOpenCreateModel(false);
    dispatch(folderDelete(ids));
  };
  const onFolderEdit = (id, name) => {
    setIds(id);
    setFolderName(name);
    setOpenFolderEditModel(true);
  };
  const onConfirmFolderEdit = () => {
    dispatch(editFolder(ids, folderName));
    setOpenFolderEditModel(false);
  };
  const onSyncScreen = (_mac) => {
    const relaventScreen = allScreens.find((s) => s.mac === _mac);
    //  console.log(_mac)
    socketRef?.current?.emit("sync", { detail: { mac: _mac } }, function () {
      toast.success(`Sync succefully on ${relaventScreen?.deviceName}`);
    });
  };
  const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
  //   if(!isLoaded){
  //     return (
  //         <>
  //         <p>loading</p>
  //         </>
  //     )
  // }
  const onGetLatLonByLocatioChange = () => {
    const placeObject = locationRef.current.getPlace();
    const lat = placeObject.geometry.location.lat();
    const lng = placeObject.geometry.location.lng();
    setLocation(placeObject?.name);
    setLat(lat);
    setLng(lng);
    // console.log(`latitude=${lat},longitude=${lng}`)
  };
  const isObjectEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };
  const PlayerBox = (objectPlay) => {
    setPlayer({ ...objectPlay });
  };

  const onPlaylistPreview = (screenplaylist) => {
    // console.log(screenplaylist)

    let playerContentList = [];
    console.log(isObjectEmpty(screenplaylist));
    if (isObjectEmpty(screenplaylist)) {
      playerContentList = [];
    } else {
      setOpenPlayer(true);

      let counter = 0;
      const _playlistContent = screenplaylist?.playlist?.content?.filter((p) => p?.content !== null);
      console.log("outside=?", _playlistContent);
      if (_playlistContent !== undefined || _playlistContent !== null) {
        _playlistContent?.forEach((player) => {
          console.log("inside foreach=>", player);
          playerContentList.push({
            orientation: screenplaylist?.oriantation || "90",
            name: player.content.name,
            url: player.content.url,
            duration: player.duration,
            type: player.content.type,
          });
        });
      }

      console.log("empty,", playerContentList);
      const nextPlay = () => {
        if (counter < playerContentList.length && playerContentList.length > 0) {
          console.log("inside=>", playerContentList);
          const contentPlay = playerContentList[counter];
          PlayerBox(contentPlay);
          counter++;
          setIntervalID(setTimeout(nextPlay, contentPlay.duration));
        } else {
          if (playerContentList.length > 0) {
            counter = 0;
            nextPlay();
          }
        }
      };
      nextPlay();
    }
  };
  const closePlayer = () => {
    clearTimeout(intervalID);
    onPlaylistPreview({});
    setPlayer({});
    setOpenPlayer(false);
  };
  return (
    <Fragment>
      <input ref={macRef} value={delMac} hidden />
      <Layout title={t("views.userScreens.screens")} user={user} playlistAddBtn={false}>
        <UserProfileComponent user={userInfo} page={"Screens"} total={screenLength} />
        <Box
          sx={{
            height: "100%",
            width: "99%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}>
          {isDeleting && (
            <>
              <Box
                sx={{
                  height: "100vh",
                  width: "100vw",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  zIndex: 10000,
                  background: "rgba(0,0,0,0.5)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <Circularbar />
              </Box>
            </>
          )}
          <Box
            sx={{
              height: "75vh",
              width: isMobile ? "100vw" : "75vw",
              overflowX: "hidden",
              background: "white",
              borderRadius: "10px",
              padding: {
                xl: "20px",
                lg: "10px",
                md: "10px",
                sm: "5px",
                xs: "5px",
              },
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}>
            <TableBodies
              dataRows={screens}
              user={user}
              onUnpaired={unPaired}
              onPaired={paired}
              onEditScreen={screenUpdate}
              onPairScreenModel={() => setOpenPairScreenModel(true)}
              onScreenMove={onScreenMove}
              onScreenFolderDelete={onScreenFolderDelete}
              onFolderEdit={onFolderEdit}
              onSyncScreen={onSyncScreen}
              onPlaylistPreview={onPlaylistPreview}
            />
          </Box>
        </Box>
      </Layout>

      {openPlayer && <Player open={openPlayer} onClose={closePlayer} player={player} />}

      {/* <Dialog 
        open={openPlayer}
        onClose={closePlayer}
        maxWidth={'xl'}
        >
           <DialogTitle
            sx={{
              height: "5vh",
              background:
                "linear-gradient(30.84deg, #016AFE 61.34%, rgba(1, 163, 254, 0.53) 100%)",
              transform: "matrix(1, 0, 0, -1, 0, 0)",
            }}
          ></DialogTitle>
          <DialogContent>
         <Box sx={{height:'60vh',width:'50vw',margin:'10px 0'}}>
           {
             player.type==='image' &&
             <img src={`${baseImg}/${player.url}`} style={{height:'100%',width:'100%',objectFit:'cover'}} />
           }
          {
            player.type==='video' &&  <video
            autoPlay
            src={`${baseImg}/${player.url}`}
            style={{height:'100%',width:'100%',objectFit:'cover'}}
          ></video>
          }
         </Box>
         <Box sx={{height:'10vh',width:'50vw',display:'flex',justifyContent:'flex-start',alignItems:'center'}}>
           <Button variant={'contained'} onClick={closePlayer}>Close</Button>
         </Box>
          </DialogContent>
        </Dialog> */}

      <ConfirmationModel
        openCreateModel={openCreateModel}
        onClose={() => setOpenCreateModel(false)}
        onConfirm={onConfirm}
        Icon={FaTrash}
        title={t("views.userScreens.deleteConfirmation")}
      />

      <Dialog open={openMoveModel} onClose={() => setOpenMoveModel(false)} maxWidth={"lg"}>
        <DialogTitle sx={{ textAlign: "center" }}>{t("views.userScreens.selectFolder")}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: "40vw",
              height: "40vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}>
            <Box
              style={{
                width: "100px",
                height: "100px",
                margin: "0 auto",
              }}>
              <FolderIcon
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                  color: "#1977FA",
                }}
              />
            </Box>
            <Box sx={{ margin: "20px 0" }}>
              <Select value={folderVal} onChange={(e) => setFolderVal(e.target.value)} fullWidth>
                {[{ _id: "root", name: t("views.userScreens.home"), folderFor: "content", path: [], parent: "" }, ...screenFolders].map((folders) => (
                  <MenuItem key={folders._id} value={folders._id}>
                    {folders.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "40px 0",
                gap: "10px 10px",
              }}>
              <Button
                variant={"outlined"}
                sx={{
                  height: "38px",
                  width: "193px",
                  textTransform: "capitalize",
                  borderRadius: "10px",
                }}
                onClick={() => setOpenMoveModel(false)}>
                {t("views.userScreens.close")}
              </Button>
              <Button
                variant={"contained"}
                onClick={moveOnFolder}
                sx={{
                  height: "38px",
                  width: "193px",
                  textTransform: "capitalize",
                  borderRadius: "10px",
                }}>
                {t("views.userScreens.save")}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={openCreateScreen} onClose={() => setOpenCreateScreen(false)} maxWidth={"xl"} sx={{ zIndex: "10" }}>
        <DialogTitle sx={{ textAlign: "right" }}></DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: { xl: "50vw", lg: "50vw", sm: "70vw", xs: "70vw" },
              minHeight: "65vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}>
            <Typography variant={"h4"} align={"center"}>
              {t("views.userScreens.editScreen")}
            </Typography>
            <Box
              sx={{
                width: { xl: "183px", lg: "183px", sm: "120px", xs: "120px" },
                height: { xl: "154.41px", lg: "154.41px", sm: "100px", xs: "100px" },
                margin: "0 auto",
              }}>
              <img src="/images/screenPairingIcon.png" style={{ height: "100%", width: "100%" }} />
            </Box>
            <Box sx={{ margin: "20px 0" }}>
              <TextField
                variant={"standard"}
                fullWidth
                type={"text"}
                label={t("views.userScreens.deviceName")}
                value={deviceName}
                onChange={(e) => setDeviceName(e.target.value)}
              />
            </Box>
            <Box sx={{ margin: "20px 0" }}>
              <TextField
                variant={"standard"}
                fullWidth
                type={"text"}
                label={t("views.userScreens.pairingCode")}
                value={code}
                disabled
              // onChange={(e) => setCode(e.target.value)}
              />
            </Box>
            <Box sx={{ margin: "10px 0" }}>
              <TextField label={t("views.userScreens.tags")} variant={"standard"} fullWidth />
            </Box>
            <Box
              sx={{
                margin: "10px 0",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="demo-simple-select-standard-label">{t("views.userScreens.type")}</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  label={t("views.userScreens.type")}
                  value={type}
                  onChange={(e) => setType(e.target.value)}>
                  <MenuItem value="">
                    <em>{t("views.userScreens.none")}</em>
                  </MenuItem>
                  <MenuItem value={"asset"}>{t("views.userScreens.task")}</MenuItem>
                  <MenuItem value={"playlist"}>{t("views.userScreens.playlist")}</MenuItem>
                  <MenuItem value={"schedule"}>{t("views.userScreens.schedule")}</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ margin: "10px 0" }}>
              {type === "playlist" && (
                <>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id="playlist_select">{t("views.userScreens.selectPlaylist")}</InputLabel>
                    <Select
                      labelId="playlist_select"
                      id="demo-simple-select-standard"
                      label={t("views.userScreens.selectPlaylist")}
                      value={playlistId}
                      onChange={(e) => setPlaylistId(e.target.value)}>
                      {playlists.map((p) => (
                        <MenuItem value={p._id}>{p.playlistName}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}
            </Box>
            <Box sx={{ margin: "10px 0" }}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="demo-simple-select-standard-label">{t("views.userScreens.selectSchedule")}</InputLabel>
                <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" label={t("views.userScreens.selectSchedule")}>
                  <MenuItem value="">
                    <em>{t("views.userScreens.none")}</em>
                  </MenuItem>
                  {/* <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ margin: "10px 0" }}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="demo-simple-select-standard-label">{t("views.userScreens.orientation")}</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  label={t("views.userScreens.orientation")}
                  value={oriantation}
                  onChange={(e) => setOriantation(e.target.value)}>
                  <MenuItem value="">
                    <em>{t("views.userScreens.none")}</em>
                  </MenuItem>
                  <MenuItem value={"landscape"}>{t("views.userScreens.landscape")}</MenuItem>
                  <MenuItem value={"90"}>{t("views.userScreens.rotate90")}</MenuItem>
                  <MenuItem value={"180"}>{t("views.userScreens.rotate180")}</MenuItem>
                  <MenuItem value={"270"}>{t("views.userScreens.rotate270")}</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                width: "100%",
                minHeight: "10vh",
                margin: "10px 0",
                display: "flex",
                justifyContent: "flex-start",
                flexWrap: "wrap",
              }}>
              <Box
                sx={{
                  height: "100%",
                  width: "30%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}>
                <Typography
                  variant={"caption"}
                  sx={{
                    fontWeight: "400",
                    fontSize: "15px",
                    lineHeight: "27px",
                    color: "#92959E",
                  }}>
                  {t("views.userScreens.syncPlay")}
                </Typography>
                <Checkbox size={"small"} name={"syncPlay"} checked={syncPlay} onChange={(e) => setSyncPlay(e.target.checked)} />
              </Box>
              <Box
                sx={{
                  height: "100%",
                  width: "30%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}>
                <Typography
                  variant={"h6"}
                  sx={{
                    fontWeight: "400",
                    fontSize: "15px",
                    lineHeight: "27px",
                    color: "#92959E",
                  }}>
                  {t("views.userScreens.preloadAssets")}
                </Typography>
                <Checkbox size={"small"} name={"preloadAssetPlaylist"} checked={preloadAssetPlaylist} onChange={(e) => setPreloadAssetPlaylist(e.target.checked)} />
              </Box>
              <Box
                sx={{
                  height: "100%",
                  width: "30%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}>
                <Typography
                  variant={"caption"}
                  sx={{
                    fontWeight: "400",
                    fontSize: "15px",
                    lineHeight: "27px",
                    color: "#92959E",
                  }}>
                  {t("views.userScreens.showOfflineIndicator")}
                </Typography>
                <Checkbox size={"small"} name={"showOfflineIndicator"} checked={showOfflineIndicator} onChange={(e) => setShowOfflineIndicator(e.target.checked)} />
              </Box>
              <Box
                sx={{
                  height: "100%",
                  width: "30%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}>
                <Typography
                  variant={"h6"}
                  sx={{
                    fontWeight: "400",
                    fontSize: "15px",
                    lineHeight: "27px",
                    color: "#92959E",
                  }}>
                  {t("views.userScreens.showDownloadingControl")}
                </Typography>
                <Checkbox size={"small"} name={"showDownloadStatus"} checked={showDownloadStatus} onChange={(e) => setShowDownloadStatus(e.target.checked)} />
              </Box>
              <Box
                sx={{
                  height: "100%",
                  width: "30%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}>
                <Typography
                  variant={"h6"}
                  sx={{
                    fontWeight: "400",
                    fontSize: "15px",
                    lineHeight: "27px",
                    color: "#92959E",
                  }}>
                  {t("views.userScreens.playbackControl")}
                </Typography>
                <VCPSwitch name={"playBackControl"} checked={playBackControl} onChange={(e) => setPlayBackControl(e)} />
              </Box>
            </Box>
            <Box sx={{ margin: "10px 0" }}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="demo-simple-select-standard-label">{t("views.userScreens.backgroundType")}</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  label={t("views.userScreens.backgroundType")}
                  value={backgroundType}
                  onChange={(e) => setBackgroundType(e.target.value)}>
                  <MenuItem value="">
                    <em>{t("views.userScreens.none")}</em>
                  </MenuItem>
                  <MenuItem value={"default"}>{t("views.userScreens.default")}</MenuItem>
                  <MenuItem value={"color"}>{t("views.userScreens.color")}</MenuItem>
                  <MenuItem value={"image"}>{t("views.userScreens.image")}</MenuItem>
                  <MenuItem value={"transparent"}>{t("views.userScreens.transparent")}</MenuItem>
                </Select>
              </FormControl>
              {backgroundType === "color" && (
                <>
                  <Box
                    sx={{
                      margin: "1rem 0",
                    }}>
                    <InputLabel id="back-color">{t("views.userScreens.colorPicker")}</InputLabel>
                    <Input labelId="back-color" type={"color"} value={colorCode} onChange={(e) => setColorCode(e.target.value)} fullWidth />
                  </Box>
                </>
              )}
              {backgroundType === "image" && (
                <>
                  <Box
                    sx={{
                      margin: "1rem 0",
                      display: "flex",
                    }}>
                    <TextField value={"abc.png"} aria-readonly={false} disabled fullWidth />
                    <Button variant={"outlined"}>{t("views.userScreens.change")}</Button>
                  </Box>
                </>
              )}
              <Box sx={{ margin: "10px 0", zIndex: "1000000000000000" }}>
                {/* <Autocomplete onLoad={(autocomplete)=>locationRef.current=autocomplete} onPlaceChanged={onGetLatLonByLocatioChange}>
                  <TextField
                    type={"text"}
                    label={"location"}
                    variant={"standard"}
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    fullWidth
                  />
                  </Autocomplete> */}
              </Box>
              <Box sx={{ margin: "10px 0" }}>
                <Slider
                  sx={{
                    height: "2px",
                    "& .MuiSlider-thumb": {
                      height: 20,
                      width: 20,
                      backgroundColor: "#016AFE",
                      "&:before": {
                        height: 10,
                        width: 10,
                        border: "2px solid white",
                        borderRadius: 50,
                      },
                      "&:focus, &:hover, &.Mui-active": {
                        boxShadow: "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
                      },
                    },
                    "& .MuiSlider-valueLabel": {
                      fontSize: 12,
                      fontWeight: "normal",
                      top: -6,
                      color: "green",
                    },
                    "& .MuiSlider-mark": {
                      backgroundColor: "#bfbfbf",
                      height: 10,
                      width: 10,
                      borderRadius: 50,
                    },
                  }}
                  marks={[
                    { value: 0, label: "0%" },
                    { value: 30, label: t("views.userScreens.brightness") },
                    { value: 70, label: t("views.userScreens.brightness") },
                    { value: 100, label: "full" },
                  ]}
                  onChange={(e) => setBrightness(e.target.value)}
                  value={brightness}
                />
              </Box>
              <Box sx={{ margin: "10px 0" }}>
                <Slider
                  value={volume}
                  onChange={(e) => setVolume(e.target.value)}
                  sx={{
                    height: "2px",
                    "& .MuiSlider-thumb": {
                      height: 20,
                      width: 20,
                      backgroundColor: "#016AFE",
                      "&:before": {
                        height: 10,
                        width: 10,
                        border: "2px solid white",
                        borderRadius: 50,
                      },
                      "&:focus, &:hover, &.Mui-active": {
                        boxShadow: "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
                      },
                    },
                    "& .MuiSlider-valueLabel": {
                      fontSize: 12,
                      fontWeight: "normal",
                      top: -6,
                      color: "green",
                    },
                    "& .MuiSlider-mark": {
                      backgroundColor: "#bfbfbf",
                      height: 10,
                      width: 10,
                      borderRadius: 50,
                    },
                  }}
                  marks={[
                    { value: 0, label: "0%" },
                    { value: 30, label: t("views.userScreens.volume") },
                    { value: 70, label: t("views.userScreens.volume") },
                    { value: 100, label: "full" },
                  ]}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "10px 0",
                gap: "10px 10px",
              }}>
              {t("views.userScreens.mute")} <VCPSwitch name={"mute"} checked={mute} onChange={(e) => setMute(e)} />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "40px 0",
                gap: "10px 10px",
              }}>
              <Button
                variant={"outlined"}
                sx={{
                  height: "38px",
                  width: "193px",
                  textTransform: "capitalize",
                  borderRadius: "10px",
                }}
                onClick={() => setOpenCreateScreen(false)}>
                {t("views.userScreens.cancel")}
              </Button>
              <Button
                variant={"contained"}
                sx={{
                  height: "38px",
                  width: "193px",
                  textTransform: "capitalize",
                  borderRadius: "10px",
                }}
                onClick={editScreen}>
                {t("views.userScreens.update")}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      {/* pairing screen */}
      <Dialog open={openPairScreenModel} onClose={() => setOpenPairScreenModel(false)} maxWidth={"lg"} sx={{ zIndex: "10" }}>
        <DialogTitle sx={{ textAlign: "right" }}></DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: { xl: "50vw", lg: "50vw", sm: "70vw", xs: "70vw" },
              minHeight: "65vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}>
            <Typography variant={"h4"} align={"center"}>
              {t("views.userScreens.createScreen")}
            </Typography>
            <Box
              style={{
                width: { xl: "183px", lg: "183px", sm: "120px", xs: "120px" },
                height: { xl: "154.41px", lg: "154.41px", sm: "100px", xs: "100px" },
                margin: "20px auto",
              }}>
              <img src="/images/screenPairingIcon.png" style={{ height: "100%", width: "100%" }} />
            </Box>
            <Box sx={{ margin: "20px 0" }}>
              <TextField
                variant={"standard"}
                fullWidth
                type={"text"}
                label={t("views.userScreens.deviceName")}
                value={deviceName}
                onChange={(e) => setDeviceName(e.target.value)}
              />
            </Box>
            <Box sx={{ margin: "20px 0" }}>
              <TextField variant={"standard"} fullWidth type={"text"} label={t("views.userScreens.pairingCode")} value={code} onChange={(e) => setCode(e.target.value)} />
            </Box>
            <Box sx={{ margin: "10px 0" }}>
              <TextField label={t("views.userScreens.tags")} variant={"standard"} fullWidth />
            </Box>
            <Box
              sx={{
                margin: "10px 0",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="demo-simple-select-standard-label">{t("views.userScreens.type")}</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  label={t("views.userScreens.type")}
                  value={type}
                  onChange={(e) => setType(e.target.value)}>
                  <MenuItem value="">
                    <em>{t("views.userScreens.none")}</em>
                  </MenuItem>
                  <MenuItem value={"asset"}>{t("views.userScreens.task")}</MenuItem>
                  <MenuItem value={"playlist"}>{t("views.userScreens.playlist")}</MenuItem>
                  <MenuItem value={"schedule"}>{t("views.userScreens.schedule")}</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ margin: "10px 0" }}>
              {type === "playlist" && (
                <>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id="playlist_select">{t("views.userScreens.selectPlaylist")}</InputLabel>
                    <Select
                      labelId="playlist_select"
                      id="demo-simple-select-standard"
                      label={t("views.userScreens.selectPlaylist")}
                      value={playlistId}
                      onChange={(e) => setPlaylistId(e.target.value)}>
                      {playlists.map((p) => (
                        <MenuItem value={p._id}>{p.playlistName}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}
            </Box>
            <Box sx={{ margin: "10px 0" }}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="demo-simple-select-standard-label">{t("views.userScreens.selectSchedule")}</InputLabel>
                <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" label={t("views.userScreens.selectSchedule")}>
                  <MenuItem value="">
                    <em>{t("views.userScreens.none")}</em>
                  </MenuItem>
                  {/* <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ margin: "10px 0" }}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="demo-simple-select-standard-label">{t("views.userScreens.orientation")}</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  label={t("views.userScreens.orientation")}
                  value={oriantation}
                  onChange={(e) => setOriantation(e.target.value)}>
                  <MenuItem value="">
                    <em>{t("views.userScreens.none")}</em>
                  </MenuItem>
                  <MenuItem value={"landscape"}>{t("views.userScreens.landscape")}</MenuItem>
                  <MenuItem value={"90"}>{t("views.userScreens.rotate90")}</MenuItem>
                  <MenuItem value={"180"}>{t("views.userScreens.rotate180")}</MenuItem>
                  <MenuItem value={"270"}>{t("views.userScreens.rotate270")}</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                width: "100%",
                minHeight: "10vh",
                margin: "10px 0",
                display: "flex",
                justifyContent: "flex-start",
                flexWrap: "wrap",
              }}>
              <Box
                sx={{
                  height: "100%",
                  width: "30%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}>
                <Typography
                  variant={"caption"}
                  sx={{
                    fontWeight: "400",
                    fontSize: "15px",
                    lineHeight: "27px",
                    color: "#92959E",
                  }}>
                  {t("views.userScreens.syncPlay")}
                </Typography>
                <Checkbox size={"small"} onChange={(e) => setSyncPlay(e.target.checked)} />
              </Box>
              <Box
                sx={{
                  height: "100%",
                  width: "30%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}>
                <Typography
                  variant={"h6"}
                  sx={{
                    fontWeight: "400",
                    fontSize: "15px",
                    lineHeight: "27px",
                    color: "#92959E",
                  }}>
                  {t("views.userScreens.preloadAssets")}
                </Typography>
                <Checkbox size={"small"} onChange={(e) => setPreloadAssetPlaylist(e.target.checked)} />
              </Box>
              <Box
                sx={{
                  height: "100%",
                  width: "30%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}>
                <Typography
                  variant={"caption"}
                  sx={{
                    fontWeight: "400",
                    fontSize: "15px",
                    lineHeight: "27px",
                    color: "#92959E",
                  }}>
                  {t("views.userScreens.showOfflineIndicator")}
                </Typography>
                <Checkbox size={"small"} onChange={(e) => setShowOfflineIndicator(e.target.checked)} />
              </Box>
              <Box
                sx={{
                  height: "100%",
                  width: "30%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}>
                <Typography
                  variant={"h6"}
                  sx={{
                    fontWeight: "400",
                    fontSize: "15px",
                    lineHeight: "27px",
                    color: "#92959E",
                  }}>
                  {t("views.userScreens.showDownloadingControl")}
                </Typography>
                <Checkbox size={"small"} onChange={(e) => setShowDownloadStatus(e.target.checked)} />
              </Box>
              <Box
                sx={{
                  height: "100%",
                  width: "30%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}>
                <Typography
                  variant={"h6"}
                  sx={{
                    fontWeight: "400",
                    fontSize: "15px",
                    lineHeight: "27px",
                    color: "#92959E",
                  }}>
                  {t("views.userScreens.playbackControl")}
                </Typography>
                <VCPSwitch onChange={(e) => setPlayBackControl(e)} />
              </Box>
            </Box>
            <Box sx={{ margin: "10px 0" }}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="demo-simple-select-standard-label">{t("views.userScreens.backgroundType")}</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  label={t("views.userScreens.backgroundType")}
                  value={backgroundType}
                  onChange={(e) => setBackgroundType(e.target.value)}>
                  <MenuItem value="">
                    <em>{t("views.userScreens.none")}</em>
                  </MenuItem>
                  <MenuItem value={"default"}>{t("views.userScreens.default")}</MenuItem>
                  <MenuItem value={"color"}>{t("views.userScreens.color")}</MenuItem>
                  <MenuItem value={"image"}>{t("views.userScreens.image")}</MenuItem>
                  <MenuItem value={"transparent"}>{t("views.userScreens.transparent")}</MenuItem>
                </Select>
              </FormControl>
              {backgroundType === "color" && (
                <>
                  <Box
                    sx={{
                      margin: "1rem 0",
                    }}>
                    <InputLabel id="back-color">{t("views.userScreens.colorPicker")}</InputLabel>
                    <Input labelId="back-color" type={"color"} value={colorCode} onChange={(e) => setColorCode(e.target.value)} fullWidth />
                  </Box>
                </>
              )}
              {backgroundType === "image" && (
                <>
                  <Box
                    sx={{
                      margin: "1rem 0",
                      display: "flex",
                    }}>
                    <TextField value={"abc.png"} aria-readonly={false} disabled fullWidth />
                    <Button variant={"outlined"}>{t("views.userScreens.change")}</Button>
                  </Box>
                </>
              )}
              <Box sx={{ margin: "10px 0", zIndex: "1000000000000000" }}>
                {/* <Autocomplete onLoad={(autocomplete)=>locationRef.current=autocomplete} onPlaceChanged={onGetLatLonByLocatioChange}>
                    /> 
                  <TextField
                    type={"text"}
                    label={"location"}
                    variant={"standard"}
                    // value={location}
                    // onChange={(e) => setLocation(e.target.value)}
                    fullWidth
                  />
                  </Autocomplete> */}
              </Box>
              <Box sx={{ margin: "10px 0" }}>
                <Slider
                  sx={{
                    height: "2px",
                    "& .MuiSlider-thumb": {
                      height: 20,
                      width: 20,
                      backgroundColor: "#016AFE",
                      "&:before": {
                        height: 10,
                        width: 10,
                        border: "2px solid white",
                        borderRadius: 50,
                      },
                      "&:focus, &:hover, &.Mui-active": {
                        boxShadow: "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
                      },
                    },
                    "& .MuiSlider-valueLabel": {
                      fontSize: 12,
                      fontWeight: "normal",
                      top: -6,
                      color: "green",
                    },
                    "& .MuiSlider-mark": {
                      backgroundColor: "#bfbfbf",
                      height: 10,
                      width: 10,
                      borderRadius: 50,
                    },
                  }}
                  marks={[
                    { value: 0, label: "0%" },
                    { value: 30, label: t("views.userScreens.brightness") },
                    { value: 70, label: t("views.userScreens.brightness") },
                    { value: 100, label: "full" },
                  ]}
                  onChange={(e) => setBrightness(e.target.value)}
                />
              </Box>
              <Box sx={{ margin: "10px 0" }}>
                <Slider
                  onChange={(e) => setVolume(e.target.value)}
                  sx={{
                    height: "2px",
                    "& .MuiSlider-thumb": {
                      height: 20,
                      width: 20,
                      backgroundColor: "#016AFE",
                      "&:before": {
                        height: 10,
                        width: 10,
                        border: "2px solid white",
                        borderRadius: 50,
                      },
                      "&:focus, &:hover, &.Mui-active": {
                        boxShadow: "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
                      },
                    },
                    "& .MuiSlider-valueLabel": {
                      fontSize: 12,
                      fontWeight: "normal",
                      top: -6,
                      color: "green",
                    },
                    "& .MuiSlider-mark": {
                      backgroundColor: "#bfbfbf",
                      height: 10,
                      width: 10,
                      borderRadius: 50,
                    },
                  }}
                  marks={[
                    { value: 0, label: "0%" },
                    { value: 30, label: t("views.userScreens.volume") },
                    { value: 70, label: t("views.userScreens.volume") },
                    { value: 100, label: "full" },
                  ]}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "10px 0",
                gap: "10px 10px",
              }}>
              {t("views.userScreens.mute")} <VCPSwitch onChange={(e) => setMute(e)} />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "40px 0",
                gap: "10px 10px",
              }}>
              <Button
                variant={"outlined"}
                sx={{
                  height: "38px",
                  width: "193px",
                  textTransform: "capitalize",
                  borderRadius: "10px",
                }}
                onClick={() => setOpenPairScreenModel(false)}>
                {t("views.userScreens.cancel")}
              </Button>
              <Button
                variant={"contained"}
                sx={{
                  height: "38px",
                  width: "193px",
                  textTransform: "capitalize",
                  borderRadius: "10px",
                }}
                onClick={pairScreen}>
                {t("views.userScreens.pair")}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      {/* pairing screen ending */}

      {/* image model */}
      <Dialog open={openImageModel} onClose={() => setOpenImageModel(false)} maxWidth={"xl"}>
        <DialogTitle>{t("views.userScreens.selectImage")}</DialogTitle>
        <DialogContent></DialogContent>
      </Dialog>
      {/* image model ending */}

      <ConfirmationModel
        openCreateModel={openDeleteModel}
        onClose={() => setOpenDeleteModel(false)}
        onConfirm={confirmUnpaired}
        Icon={HelpOutlineIcon}
        title={t("views.userScreens.unpairConfirmation")}
      />
      <ConfirmationModel
        openCreateModel={openPairedModel}
        onClose={() => setOpenPairedModel(false)}
        onConfirm={confirmPaired}
        Icon={HelpOutlineIcon}
        title={t("views.userScreens.pairConfirmation")}
      />
      <Dialog open={openFolderEditModel} onClose={() => setOpenFolderEditModel(false)}>
        <DialogTitle style={{ textAlign: "center" }}>{t("views.userScreens.editFolder")}</DialogTitle>
        <DialogContent style={{ borderRadius: "50px" }}>
          <Box
            sx={{
              display: "flex",
              gap: "2px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Box style={{ width: "300px", height: "50px", margin: "20px auto" }}>
              <TextField type={"text"} value={folderName} onChange={(e) => setFolderName(e.target.value)} fullWidth />
            </Box>

            <Box sx={{ display: "flex", gap: "10px" }}>
              <Button
                onClick={() => setOpenFolderEditModel(false)}
                variant={"contained"}
                color={"error"}
                sx={{
                  height: "38px",
                  width: "150px",
                  textTransform: "capitalize",
                  borderRadius: "10px",
                }}>
                {t("views.userScreens.cancel")}
              </Button>
              <Button
                onClick={onConfirmFolderEdit}
                variant={"contained"}
                sx={{
                  height: "38px",
                  width: "150px",
                  textTransform: "capitalize",
                  borderRadius: "10px",
                }}>
                {t("views.userScreens.save")}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}

export default UserScreens;
